import { useForm } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import { Button, Table } from "@anytimesoftware0/anytime-ui";
import styles from "./OrderingStagesModal.module.scss";
import { useTranslation } from "react-i18next";
import { MRT_ColumnDef } from "material-react-table";
import { Dispatch, SetStateAction } from "react";

interface OrderingStagesModalProps {
  handleCloseOrderingModal: () => void;
  handleOrderStagesSubmit: () => void;
  columns: MRT_ColumnDef<{
    id: number;
    title: string;
    order: number;
  }>[];
  newOrder: {
    id: number;
    title: string;
    order: number;
  }[];
  setNewOrder: Dispatch<
    SetStateAction<
      {
        id: number;
        title: string;
        order: number;
      }[]
    >
  >;
}

const OrderingStagesModal: React.FC<OrderingStagesModalProps> = ({
  handleCloseOrderingModal,
  handleOrderStagesSubmit,
  columns,
  newOrder,
  setNewOrder,
}) => {
  const [t] = useTranslation(["common"]);
  const { handleSubmit } = useForm();

  const submit = () => {
    handleOrderStagesSubmit();
    handleCloseOrderingModal();
  };
  return (
    <form
      noValidate
      className={styles.modalContent}
      onSubmit={handleSubmit(submit)}
    >
      <Typography variant="h3" component="h3">
        {t("ChangeStageOrder")}
      </Typography>
      <Table
        columns={columns}
        data={newOrder}
        enableRowOrdering
        enableRowDragging
        setData={setNewOrder}
      />
      <Box className={styles.buttons}>
        <Button type="submit" color="primary" className={styles.add}>
          {t("save")}
        </Button>
        <Button
          variant="contained"
          color="danger"
          onClick={handleCloseOrderingModal}
        >
          {t("cancel")}
        </Button>
      </Box>
    </form>
  );
};

export default OrderingStagesModal;
