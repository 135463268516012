import { Stage } from "../../pages/Jobs/jobs.types";
import axios from "../../utils/axios";
import config from "../../utils/config";
import { CreateStageDto } from "./stages.types";

const baseUrl = `${config.backendUrl}/stages`;

const create = async (
  jobId: number,
  createStageDto: CreateStageDto
): Promise<Stage> => {
  const { data } = await axios.post<Stage>(
    `${baseUrl}?jobId=${jobId}`,
    createStageDto
  );
  return data;
};
const update = async (
  jobId: number,
  stageId: number,
  updateStageDto: CreateStageDto
): Promise<Stage> => {
  const { data } = await axios.patch<Stage>(
    `${baseUrl}/${stageId}`,
    updateStageDto
  );
  return data;
};
const remove = async (jobId: number, stageId: number): Promise<Stage> => {
  const { data } = await axios.delete<Stage>(`${baseUrl}/${stageId}`);
  return data;
};

const stagesService = { create, update, remove };

export default stagesService;
