import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../utils/store";
import { AxiosError } from "axios";
import permissionService from "../services/permissions";
import { Permission } from "../pages/Roles/roles.types";
import toast from "react-hot-toast";

const initialState = [] as Permission[];

const permissionsSlice = createSlice({
  name: "Permissions",
  initialState,
  reducers: {
    setPermissions(state, action: PayloadAction<Permission[]>) {
      return action.payload;
    },
  },
});

export const { setPermissions } = permissionsSlice.actions;

export const initializePermissions = () => async (dispatch: AppDispatch) => {
  try {
    const permissions = await permissionService.getPermissions();
    dispatch(setPermissions(permissions));
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      toast.error(`Failed to fetch Permissions`);
    }
  }
};

export default permissionsSlice.reducer;
