import { type MRT_ColumnDef } from "material-react-table";
import Role, { CreateRoleDto, Permission } from "./roles.types";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../utils/store";
import { useEffect, useMemo, useState } from "react";
import {
  createRole,
  deleteManyRoles,
  initializeRoles,
  updateRole,
} from "./roles.slice";
import { Box } from "@mui/material";
import { initializeCustomUsers } from "../../pages/Users/users.slice";
import { useTranslation } from "react-i18next";
import { initializePermissions } from "../../reducers/permissions";
import { Button } from "@anytimesoftware0/anytime-ui";

import RolesTemplate from "../../components/templates/RolesTemplate";

export default function Roles() {
  const [selectedRole, setSelectedRole] = useState({ name: "" });
  const [searchTerm, setSearchTerm] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [role, setRole] = useState({ name: "" });

  const [t] = useTranslation(["common"]);
  const [selectedPermissions, setSelectedPermissions] = useState<Permission[]>(
    []
  );
  const {
    user,
    roles: rolesData,
    permissions,
  } = useSelector((state: RootState) => ({
    user: state.user,
    roles: state.roles,
    permissions: state.permissions,
  }));

  const { roles, loading, error } = rolesData;

  const columns: MRT_ColumnDef<Role>[] = useMemo(
    () =>
      [
        {
          accessorKey: "name",
          header: "NAME",
          size: 120,
          enableColumnOrdering: false,
        },
        {
          accessorKey: "permissions",
          header: "PERMISSIONS",
          size: 200,
          enableSorting: false,
          enableColumnActions: false,
          enableColumnOrdering: false,
          Cell: ({ row }) => (
            <Box
              sx={{
                display: "flex",
                gap: 1,
                flexWrap: "wrap",
              }}
            >
              {row.original.permissions.map((permission) => (
                <div key={permission.id}>{permission.name}</div>
              ))}
            </Box>
          ),
        },
        {
          accessorKey: "actions",
          header: "Actions",
          enableSorting: false,
          enableColumnActions: false,
          enableColumnOrdering: false,
          Cell: ({ row }) => {
            return (
              <Box>
                <Button
                  color="primary"
                  onClick={() => {
                    setSelectedRole({ name: row.original.name });
                  }}
                  variant="contained"
                  sx={{ mr: 2 }}
                >
                  {t("editPermissions")}
                </Button>
              </Box>
            );
          },
        },
      ] as any,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [roles]
  );

  const [openCreateModal, setCreateOpenModal] = useState(false);

  const dispatch: AppDispatch = useDispatch();

  const handlePermissionToggle = (permission: Permission) => {
    if (
      selectedPermissions.some(
        (p) => p.id === permission.id && p.name === permission.name
      )
    ) {
      // Remove the permission if it's already checked
      setSelectedPermissions((prev) =>
        prev.filter(
          (p) => !(p.id === permission.id && p.name === permission.name)
        )
      );
    } else {
      // Add the permission if it's not checked
      setSelectedPermissions((prev) => [...prev, permission]);
    }
  };

  const handleOpenCreateModal = () => setCreateOpenModal(true);

  const handleCloseCreateModal = () => setCreateOpenModal(false);

  const handleAddRoleSubmit = () => {
    const { name } = role;

    if (name) {
      const role: CreateRoleDto = {
        name,
        permissions: selectedPermissions,
      };
      dispatch(createRole(role));
    }
    setSelectedPermissions([]);
    handleCloseCreateModal();
  };

  const handleEditRolePermissions = () => {
    const { name } = selectedRole;

    if (name) {
      const role: CreateRoleDto = {
        name,
        permissions: selectedPermissions,
      };
      dispatch(updateRole(role));
    }
    setSelectedPermissions([]);
    setSelectedRole({ name: "" });
    handleCloseCreateModal();
  };
  const handleDeleteRoles = () => {
    const roles = Object.keys(rowSelection).map((key) => filteredRoles[key]);
    dispatch(deleteManyRoles(roles.map((role) => role.name)));
    setRowSelection({});
  };

  useEffect(() => {
    if (user) {
      dispatch(initializeCustomUsers());
      dispatch(initializeRoles());
      dispatch(initializePermissions());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const rolesSelectedPermissions = roles.find(
      (role) => role.name === selectedRole.name
    )?.permissions;
    setSelectedPermissions(rolesSelectedPermissions ?? []);
  }, [roles, selectedRole.name]);

  const filteredRoles = useMemo(() => {
    return roles.filter((role) =>
      role.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [roles, searchTerm]);

  return (
    <RolesTemplate
      actionsBar={{
        setSearchTerm,
        handleDeleteRoles,
        handleOpenCreateModal,
      }}
      addRoleModal={{
        isAddRoleModalOpen: openCreateModal,
        handlePermissionToggle,
        permissions,
        selectedPermissions,
        handleCloseCreateModal,
        handleAddRoleSubmit,
        onCancel: handleCloseCreateModal,
        setRole,
        role,
      }}
      state={{
        error,
        loading,
      }}
      table={{
        columns,
        filteredRoles,
        rowSelection,
        setRowSelection,
      }}
      editRoleModal={{
        isEditRoleModalOpen: selectedRole.name !== "",
        handleCloseEditModal: () => setSelectedRole({ name: "" }),
        handleEditRoleSubmit: handleEditRolePermissions,
        onCancelEdit: () => setSelectedRole({ name: "" }),
        setSelectedRole: setSelectedRole,
        selectedRole: role,
      }}
    />
  );
}
