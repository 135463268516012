import { type MRT_ColumnDef } from "material-react-table";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../utils/store";
import { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { initializeCompanies } from "../../../pages/Clients/clients.slice";
import { deleteJobs } from "../../../pages/Jobs/jobs.slice";
import { useNavigate, useParams } from "react-router-dom";
import jobsService from "../../../pages/Jobs/jobs.api";
import { useTranslation } from "react-i18next";
import { updateManyCandidates } from "../../../pages/Candidates/candidates.slice";
import { Button, TruncateModal } from "@anytimesoftware0/anytime-ui";
import styles from "./Modal.module.scss";
import SubClientTemplate from "../../../components/templates/ClientTemplate";
import { JobWithCandidates } from "../../../pages/Jobs/jobs.types";
import { addJob, updateJob } from "./client.slice";
import { Option } from "../../../types/Option";
import toast from "react-hot-toast";

const enEmail = {
  subject: "A new recommended candidate",
  message:
    "<h1>A candidate has been added</h1><p><br></p><p>Hi there,</p><p><br></p><p>{{firstName}} {{lastName}} has been added to {{title}}</p>",
  language: { value: "EN", label: "EN" },
};

const czEmail = {
  subject: "Nový doporučený kandidát",
  message:
    "<h1>Byl přidán kandidát</h1><p><br></p><p>Ahoj,</p><p><br></p><p>{{firstName}} {{lastName}} byl přidán do {{title}}</p>",
  language: { value: "CZ", label: "CZ" },
};

export default function Client() {
  const [job, setJob] = useState({
    title: "",
    description: "",
  });
  const [editedJob, setEditedJob] = useState({
    title: "",
    description: "",
  });
  const [assignedClient, setAssignedClient] = useState({
    email: "",
  });
  const [email, setEmail] = useState<{
    message: string;
    subject: string;
    language: Option;
  }>({
    message: "",
    subject: "",
    language: { value: "EN", label: "EN" },
  });
  const [selectedJobIdToAssignCleint, setSelectedJobIdToAssignCleint] =
    useState<number | null>(null);
  const [assignedCandidates, setAssignedCandidates] = useState<Option[]>([]);
  const [selectedJobIdToAssignCandidates, setSelectedJobIdToAssignCandidates] =
    useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const id = useParams().id;
  const [selectedJobId, setSelectedJobId] = useState<number | null>(null);
  const [t] = useTranslation(["common"]);
  const { companies, candidates } = useSelector((state: RootState) => ({
    user: state.user,
    companies: state.companies,
    candidates: state.candidates,
  }));

  useEffect(() => {
    if (email.language.value === "EN") {
      setEmail(enEmail);
    } else {
      setEmail(czEmail);
    }
  }, [email.language.value]);

  const navigate = useNavigate();

  const company = companies.companies.find(
    (company) => company.id === Number(id)
  );

  const columns: MRT_ColumnDef<JobWithCandidates>[] = useMemo(() => {
    return [
      {
        accessorKey: "id",
        header: "ID",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnOrdering: false,
        width: "1rem",
      },
      {
        accessorKey: "title",
        header: "Title",
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "description",
        header: "Description",
        Cell: ({ cell }) => (
          <TruncateModal content={String(cell.getValue())} length={14} />
        ),
      },
      {
        accessorKey: "candidates",
        header: "Assigned Candidates",
        enableColumnActions: false,
        enableColumnOrdering: false,
      },
      {
        accessorKey: "actions",
        header: "Actions",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnOrdering: false,
        Cell: ({ row }) => {
          return (
            <Box className={styles.actions}>
              <Button
                color="primary"
                onClick={() => {
                  setSelectedJobId(row.original.id);
                }}
                variant="contained"
              >
                {t("edit")}
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  setSelectedJobIdToAssignCleint(row.original.id);
                }}
                variant="contained"
              >
                {t("assignToClient")}
              </Button>
              <Button
                color="primary"
                onClick={() =>
                  setSelectedJobIdToAssignCandidates(row.original.id)
                }
                variant="contained"
              >
                Assign Candidates
              </Button>
              <Button
                color="primary"
                onClick={() => navigate(`/jobs/${row.original.id}`)}
                variant="contained"
              >
                Open
              </Button>
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies]);

  const [openCreateModal, setCreateOpenModal] = useState(false);

  const dispatch: AppDispatch = useDispatch();

  /* Add Job Functions */
  const handleOpenCreateModal = () => setCreateOpenModal(true);
  const handleCloseCreateModal = () => setCreateOpenModal(false);
  const clearAddJob = () => {
    setJob({
      title: "",
      description: "",
    });
  };
  const handleAddJobSubmit = () => {
    const { title, description } = job;
    if (title && company) {
      const ceateJobtDto = { userId: company.userId, title, description };
      dispatch(addJob(ceateJobtDto));
      clearAddJob();
      handleCloseCreateModal();
    }
  };
  const onCancelNew = () => {
    if (job.title || job.description) {
      const isConfirmed = window.confirm(
        "Are you sure you want to discard the changes?"
      );

      if (isConfirmed) {
        clearAddJob();
        handleCloseCreateModal();
      }
    } else {
      handleCloseCreateModal();
    }
  };

  /* Assign Candidates Functions */
  const handleAssignCandidateSubmit = () => {
    if (selectedJobIdToAssignCandidates && company) {
      const stageId = companies.companies
        .find((company) => company.id === Number(company.id))
        ?.jobs.find((job) => job.id === Number(selectedJobIdToAssignCandidates))
        ?.stages.find((stage) => stage.order === 1)?.id;
      const emailForm = {
        subject: email.subject,
        message: email.message,
      };
      const candidateDto = {
        staging: {
          companyId: company.id,
          jobId: selectedJobIdToAssignCandidates,
          stageId,
          emailMessage: emailForm,
        },
      };
      dispatch(
        updateManyCandidates(
          assignedCandidates.map(({ value }) => Number(value)),
          candidateDto as any
        )
      );
    }
  };
  const clearAssignCandidateForm = () => {
    setAssignedCandidates([]);
  };
  const onCancelAssignCandidates = () => {
    if (assignedCandidates.length) {
      const isConfirmed = window.confirm(
        "Are you sure you want to discard the changes?"
      );

      if (isConfirmed) {
        clearAssignCandidateForm();
        setSelectedJobIdToAssignCandidates(null);
      }
    } else {
      setSelectedJobIdToAssignCandidates(null);
    }
  };

  /* Edit Job Functions */
  useEffect(() => {
    if (selectedJobId) {
      const selectedJob = company?.jobs.find((job) => job.id === selectedJobId);
      if (selectedJob) {
        setEditedJob({
          title: selectedJob.title,
          description: selectedJob.description,
        });
      }
    }
  }, [company?.jobs, selectedJobId]);
  const handleEditJobSubmit = () => {
    if (selectedJobId) {
      dispatch(updateJob(editedJob, selectedJobId));
      clearEditJob();
    }
  };
  const onCancelEdit = () => {
    if (!selectedJobId) {
      return;
    }
    const oldJob = company?.jobs.find((job) => job.id === selectedJobId);

    if (
      editedJob.title !== oldJob?.title ||
      editedJob.description !== oldJob?.description
    ) {
      const isConfirmed = window.confirm(
        "Are you sure you want to discard the changes?"
      );

      if (isConfirmed) {
        clearEditJob();
      }
    } else {
      setSelectedJobId(null);
    }
  };
  const clearEditJob = () => {
    setEditedJob({
      title: "",
      description: "",
    });
    setSelectedJobId(null);
  };

  /* Assign Job to Client Functions */
  const handleAssignJobSubmit = () => {
    const emails = assignedClient.email;
    if (emails) {
      const updatedJobDto = {
        emails,
        jobId: selectedJobIdToAssignCleint,
      } as any;
      try {
        jobsService.assignJobToClient(updatedJobDto).then(() => {
          toast.success("The job was assigned successfully");
          dispatch(initializeCompanies());
        });
      } catch (error) {
        toast.error("Failed to assign the job");
      }
      setSelectedJobIdToAssignCleint(null);
    }
  };

  /* Delete Jobs Functions */
  const handleDeleteJobs = () => {
    const selectedJobs = Object.keys(rowSelection).map(
      (key) => filteredData[key]
    );
    if (selectedJobs) {
      dispatch(
        deleteJobs(
          selectedJobs.map((job) => job.id),
          false
        )
      );
    }
  };

  const data = useMemo(
    () =>
      (company?.jobs.map((job) => ({
        ...job,
        candidates:
          `(${job.stages.map((stage) => stage.candidates).flat().length}) ` +
          job.stages
            .map((stage) => stage.candidates)
            .flat()
            ?.map((candidate) => `${candidate.firstName} ${candidate.lastName}`)
            .join(", "),
      })) as any) || ([] as any),
    [company]
  );

  const filteredData = useMemo(() => {
    return data.filter((job) => {
      return job.title.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [data, searchTerm]);

  return (
    <SubClientTemplate
      table={{
        columns,
        filteredJobs: filteredData,
        rowSelection,
        setRowSelection,
      }}
      assignClientModal={{
        isAssignClientModalOpen: !!selectedJobIdToAssignCleint,
        handleCloseAssignModal: () => setSelectedJobIdToAssignCleint(null),
        handleAssignSubmit: handleAssignJobSubmit,
        setAssignedClient,
        assignedClient,
        onCancelAssign: () => setSelectedJobIdToAssignCleint(null),
      }}
      addJobModal={{
        isAddJobModalOpen: openCreateModal,
        handleCloseCreateModal,
        handleAddJobSubmit,
        setJob,
        job,
        onCancel: onCancelNew,
      }}
      editJobModal={{
        isEditJobModalOpen: !!selectedJobId,
        handleCloseEditModal: () => setSelectedJobId(null),
        handleEditJobSubmit,
        setEditedJob,
        editedJob,
        onCancelEdit,
      }}
      assignCandidateModal={{
        isAssignCandidateModalOpen: !!selectedJobIdToAssignCandidates,
        handleCloseCandidateModal: () =>
          setSelectedJobIdToAssignCandidates(null),
        handleAssignCandidateSubmit,
        setAssignedCandidates,
        assignedCandidates,
        candidates,
        email,
        setEmail,
        onCancelAssign: onCancelAssignCandidates,
      }}
      state={{ loading: false, error: null }}
      actionsBar={{
        setSearchTerm,
        handleDeleteJobs,
        handleOpenCreateModal,
      }}
    />
  );
}
