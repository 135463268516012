import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../../utils/store";
import { AxiosError } from "axios";
import roleService from "./roles.api";
import Role, { CreateRoleDto } from "./roles.types";
import toast from "react-hot-toast";

interface RoleState {
  roles: Role[];
  loading: boolean;
  error: string | null;
}

const initialState: RoleState = {
  loading: false,
  error: null,
  roles: [],
};

const roleSlice = createSlice({
  name: "Roles",
  initialState,
  reducers: {
    setRoles(state, { payload: roles }: PayloadAction<Role[]>) {
      state.roles = roles;
    },
    addRole(state, { payload: role }: PayloadAction<Role>) {
      state.roles.push(role);
    },
    replaceOne(state, { payload: role }: PayloadAction<Role>) {
      state.roles = state.roles.map((r) => (r.name === role.name ? role : r));
    },
    removeRole(state, { payload: name }: PayloadAction<string>) {
      state.roles = state.roles.filter((r) => r.name !== name);
    },
    clearRoles(state) {
      state.roles = [];
    },
    initializingRoles(state) {
      state.loading = true;
      state.error = null;
      state.roles = [];
    },
    initializeRolesSuccess(state, { payload: roles }: PayloadAction<Role[]>) {
      state.roles = roles;
      state.loading = false;
    },
    initializeRolesError(state, { payload: error }: PayloadAction<string>) {
      state.error = error;
      state.loading = false;
    },
  },
});

export const {
  setRoles,
  addRole,
  replaceOne,
  removeRole,
  clearRoles,
  initializingRoles,
  initializeRolesSuccess,
  initializeRolesError,
} = roleSlice.actions;

export const initializeRoles = () => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(initializingRoles());
      const roles = await roleService.getAll();
      dispatch(initializeRolesSuccess(roles));
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(initializeRolesError(error.message));
        toast.error("Failed to fetch Roles");
      }
    }
  };
};

export const createRole = (roleDto: CreateRoleDto) => {
  return async (dispatch: AppDispatch) => {
    try {
      const role = await roleService.create(roleDto);
      dispatch(addRole(role));
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        toast.error("Failed to add Role");
      }
    }
  };
};

export const deleteRole = (name: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      await roleService.remove(name);
      dispatch(removeRole(name));
      toast.success("Role removed successfully");
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        toast.error("Failed to remove Role");
      }
    }
  };
};

export const deleteManyRoles = (names: string[]) => {
  return async (dispatch: AppDispatch) => {
    try {
      for (const name of names) {
        await roleService.remove(name);
        dispatch(removeRole(name));
      }
      toast.success("Roles removed successfully");
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        toast.error("Failed to remove Role");
      }
    }
  };
};

export const updateRole = (role: CreateRoleDto) => {
  return async (dispatch: AppDispatch) => {
    try {
      const updatedRole = await roleService.update(role.name, role);
      dispatch(replaceOne(updatedRole));
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        toast.error("Failed to update Role");
      }
    }
  };
};

export default roleSlice.reducer;
