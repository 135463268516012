import { Email, LinkedIn } from "@mui/icons-material";
import { Avatar, Box, Card, Typography } from "@mui/material";
import React from "react";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../utils/store";
import { Drawer } from "@anytimesoftware0/anytime-ui";
import styles from "./CandidateProfile.module.scss";

interface CandidateProfileProps {
  candidateId?: number;
  openDrawer: boolean;
  setOpenDrawer: (openDrawer: boolean) => void;
}
const CandidateProfile: React.FC<CandidateProfileProps> = ({
  candidateId,
  openDrawer,
  setOpenDrawer,
}) => {
  const { candidates } = useSelector((state: RootState) => ({
    candidates: state.candidates,
    companies: state.companies,
  }));
  const candidate = candidates.find((c) => c.id === candidateId);
  const candidateJob = candidate?.stage?.job;

  const capitalizeFirstLetter = (str: string | undefined) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <Drawer
      anchor="right"
      openDrawer={openDrawer}
      setOpenDrawer={setOpenDrawer}
      withCloseBtn
    >
      <Box className={styles.main}>
        {/* Header */}
        <Box className={styles.header}>
          <Avatar
            className={styles.avatar}
            src={candidate?.firstName}
            alt={`${candidate?.firstName}_photo`}
          />
          <Box className={styles.info}>
            <Typography className={styles.title}>
              {candidate?.firstName} {candidate?.lastName}
            </Typography>
            <Typography className={styles.desc}>{candidate?.email}</Typography>
          </Box>
        </Box>
        {candidate && (
          <>
            <Box className={styles.labels}>
              <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                <Email />
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  {`${candidate.email}`}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                <LinkedIn />
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  {`${candidate.linkedinUrl || "-"}`}
                </Typography>
              </Box>
            </Box>
            <Card className={styles.card}>
              <Typography variant="h5">{t("Jobs")}</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  border: "1px solid steelblue",
                  marginTop: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 2,
                  }}
                >
                  <Typography variant="h6" sx={{ color: "blue" }}>
                    {`${capitalizeFirstLetter(candidateJob?.title)}`}
                  </Typography>
                  <Typography variant="subtitle1" sx={{}}>
                    {`Company: ${candidateJob?.company?.name || "-"}`}
                  </Typography>
                  <Typography variant="subtitle1" sx={{}}>
                    {`Stage: ${candidate.stage?.title || "-"}`}
                  </Typography>
                </Box>
              </Box>
            </Card>
          </>
        )}
      </Box>
    </Drawer>
  );
};

export default CandidateProfile;
