import { type MRT_ColumnDef } from "material-react-table";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../utils/store";
import { useEffect, useMemo, useState } from "react";
import { User } from "../../pages/Users/users.types";
import RecruitersTemplate from "../../components/templates/RecruitersTemplate";
import {
  addRecruiter,
  initializeRecruiters,
  removeManyRecruiters,
} from "./recruiters.slice";

export default function Recruiters() {
  const dispatch: AppDispatch = useDispatch();
  const { user, recruiters: recruitersData } = useSelector(
    (state: RootState) => ({
      user: state.user,
      recruiters: state.recruiters,
    })
  );

  const [openCreateModal, setCreateOpenModal] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [recruiterData, setRecruiterData] = useState<{
    email: string;
    password: string;
  }>({
    email: "",
    password: "",
  });

  const { recruiters, error, loading } = recruitersData;

  const columns: MRT_ColumnDef<User>[] = useMemo(
    () =>
      [
        {
          accessorKey: "id",
          header: "ID",
          size: 40,
        },

        {
          accessorKey: "email",
          header: "Email",
          size: 120,
        },
      ] as any,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [recruiters]
  );

  const filteredRecruiters = useMemo(() => {
    return recruiters.filter((recruiter) => {
      return recruiter.email
        .toLowerCase()
        .includes(searchTerm.toLowerCase().trim());
    });
  }, [searchTerm, recruiters]);

  const handleAddRecruiterSubmit = () => {
    if (recruiterData.email && recruiterData.password) {
      dispatch(addRecruiter(recruiterData));
    }
    clear();
    setCreateOpenModal(false);
  };

  const handleDeleteRecruiters = () => {
    const recruiters = Object.keys(rowSelection).map(
      (key) => filteredRecruiters[key]
    );
    dispatch(
      removeManyRecruiters(recruiters.map((recruiters) => recruiters.id))
    );
    setRowSelection({});
  };

  const handleOpenCreateModal = () => setCreateOpenModal(true);

  const handleCloseCreateModal = () => setCreateOpenModal(false);

  const onCancel = () => {
    if (recruiterData.email || recruiterData.password) {
      const isConfirmed = window.confirm(
        "Are you sure you want to discard the changes?"
      );

      if (isConfirmed) {
        clear();
        handleCloseCreateModal();
      }
    } else {
      handleCloseCreateModal();
    }
  };

  const clear = () =>
    setRecruiterData({
      email: "",
      password: "",
    });

  useEffect(() => {
    if (user) {
      dispatch(initializeRecruiters());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RecruitersTemplate
      state={{
        error,
        loading,
      }}
      addRecruiterModal={{
        isAddRecruiterModalOpen: openCreateModal,
        handleCloseCreateModal,
        handleAddRecruiterSubmit,
        onCancel,
        recruiter: recruiterData,
        setRecruiter: setRecruiterData,
      }}
      actionsBar={{
        handleDeleteRecruiters,
        handleOpenCreateModal,
        setSearchTerm,
      }}
      table={{
        columns,
        filteredRecruiters,
        rowSelection,
        setRowSelection,
      }}
    />
  );
}
