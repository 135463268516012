import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../../utils/store";
import { AxiosError } from "axios";
import customUsersService from "./users.api";
import { CreateCustomUserDto, customUser } from "./users.types";
import toast from "react-hot-toast";

interface CustomUserState {
  loading: boolean;
  error: string | null;
  customUsers: customUser[];
}

const initialState: CustomUserState = {
  loading: false,
  error: null,
  customUsers: [],
};

const customUsersSlice = createSlice({
  name: "customUsers",
  initialState,
  reducers: {
    customUsersStart(state) {
      state.loading = true;
      state.error = null;
      state.customUsers = [];
    },
    customUsersSuccess(
      state,
      { payload: customUsers }: PayloadAction<customUser[]>
    ) {
      state.loading = false;
      state.error = null;
      state.customUsers = customUsers;
    },
    customUsersFailure(state, { payload: error }: PayloadAction<string>) {
      state.loading = false;
      state.error = error;
      state.customUsers = [];
    },
    setCustomUsers(
      state,
      { payload: customUsers }: PayloadAction<customUser[]>
    ) {
      state.customUsers = customUsers;
    },
    addCustomUser(state, { payload: customUser }: PayloadAction<customUser>) {
      state.customUsers.push(customUser);
    },
    replaceOne(
      state,
      { payload: updatedCustomUser }: PayloadAction<customUser>
    ) {
      if (state.customUsers) {
        const index = state.customUsers.findIndex(
          (user) => user.id === updatedCustomUser.id
        );
        if (index !== -1) {
          state.customUsers[index] = updatedCustomUser;
        }
      }
    },
    removeCustomUser(state, { payload: id }: PayloadAction<number>) {
      state.customUsers =
        state.customUsers?.filter((customUser) => customUser.id !== id) || null;
    },
    clearCustomUsers(state) {
      state.customUsers = [];
    },
  },
});

export const {
  customUsersStart,
  customUsersSuccess,
  customUsersFailure,
  setCustomUsers,
  addCustomUser,
  replaceOne,
  removeCustomUser,
  clearCustomUsers,
} = customUsersSlice.actions;

export const initializeCustomUsers = () => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(customUsersStart());
      const canditates = await customUsersService.getAll();
      dispatch(customUsersSuccess(canditates));
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(customUsersFailure(error.message));
        toast.error(`Failed to fetch custom Users`);
      }
    }
  };
};

export const createOneCustomUser = (
  createCustomUserDto: CreateCustomUserDto
) => {
  return async (dispatch: AppDispatch) => {
    try {
      const customUser = await customUsersService.addOne(createCustomUserDto);
      dispatch(addCustomUser(customUser));
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        toast.error(
          `Failed to add customUser. ${error.response?.data?.message?.join(
            ". "
          )}`
        );
      }
    }
  };
};

export const deleteCustomUser = (id: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      await customUsersService.deleteOne(id);
      dispatch(removeCustomUser(id));
      toast.success("User removed successfully");
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        toast.error(`Failed to remove User`);
      }
    }
  };
};

export const deleteManyCustomUsers = (ids: number[]) => {
  return async (dispatch: AppDispatch) => {
    try {
      for (const id of ids) {
        await customUsersService.deleteOne(id);
        dispatch(removeCustomUser(id));
      }
      toast.success("Users removed successfully");
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        toast.error(`Failed to remove Users`);
      }
    }
  };
};

export default customUsersSlice.reducer;
