import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User, CreateUserDto } from "../../pages/Users/users.types";
import recruitersService from "./recruiters.api";
import { AppDispatch } from "../../utils/store";
import { AxiosError } from "axios";
import toast from "react-hot-toast";

interface UserState {
  loading: boolean;
  error: string | null;
  recruiters: User[];
}

const initialState: UserState = {
  loading: false,
  error: null,
  recruiters: [],
};

export const recruitersSlice = createSlice({
  name: "recruiters",
  initialState,
  reducers: {
    recruitersStart(state) {
      state.loading = true;
      state.error = null;
      state.recruiters = [];
    },
    recruitersSuccess(state, { payload: recruiters }: PayloadAction<User[]>) {
      state.loading = false;
      state.error = null;
      state.recruiters = recruiters;
    },
    recruitersFailure(state, { payload: error }: PayloadAction<string>) {
      state.loading = false;
      state.error = error;
      state.recruiters = [];
    },
    setRecruiters(state, { payload: recruiters }: PayloadAction<User[]>) {
      state.recruiters = recruiters;
    },
    newRecruiter(state, { payload: recruiter }: PayloadAction<User>) {
      state.recruiters.push(recruiter);
    },
    deleteRecruiter(state, { payload: id }: PayloadAction<number>) {
      state.recruiters = state.recruiters.filter(
        (recruiter) => recruiter.id !== id
      );
    },
  },
});

export const {
  recruitersStart,
  recruitersSuccess,
  recruitersFailure,
  setRecruiters,
  newRecruiter,
  deleteRecruiter,
} = recruitersSlice.actions;

export const initializeRecruiters = () => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(recruitersStart());
      const users = await recruitersService.getAll();
      dispatch(recruitersSuccess(users));
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        toast.error(`Failed to fetch recruiters`);
      }
    }
  };
};

export type CreateRecruiter = {
  name: string;
};

export const addRecruiter = (recruiter: CreateUserDto) => {
  return async (dispatch: AppDispatch) => {
    try {
      const user = await recruitersService.addOne(recruiter);
      dispatch(newRecruiter(user));
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        toast.error(`Failed to add recruiter`);
      }
    }
  };
};

export const removeRecruiter = (id: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      await recruitersService.deleteOne(id);
      dispatch(deleteRecruiter(id));
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        toast.error(`Failed to remove recruiter`);
      }
    }
  };
};

export const removeManyRecruiters = (ids: number[]) => {
  return async (dispatch: AppDispatch) => {
    try {
      for (const id of ids) {
        await recruitersService.deleteOne(id);
        dispatch(deleteRecruiter(id));
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        toast.error(`Failed to remove recruiters`);
      }
    }
  };
};

export default recruitersSlice.reducer;
