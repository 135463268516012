import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../utils/store";
import { login } from "./auth.slice";
import { useNavigate } from "react-router-dom";
import AuthTemplate from "../../components/templates/AuthTemplate";

export default function SignIn() {
  const user = useSelector((state: RootState) => state.user);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmitForm = () => {
    const credentials = {
      email: email,
      password: password,
    };
    dispatch(login(credentials /*remember*/));
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [navigate, user]);

  if (user) {
    return null;
  }

  return (
    <AuthTemplate
      email={email}
      password={password}
      setEmail={setEmail}
      setPassword={setPassword}
      handleSubmitForm={handleSubmitForm}
    />
  );
}
