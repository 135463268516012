import { Button } from "@anytimesoftware0/anytime-ui";
import styles from "./clients.module.scss";
import ClientsTemplate from "../../components/templates/ClientsTemplate";
import { type MRT_ColumnDef } from "material-react-table";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { AppDispatch, RootState } from "../../utils/store";
import { useEffect, useMemo, useState } from "react";
import { UpdateCompanyDto } from "./clients.api";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CompaniesWithJobsLabel } from "./clients.types";
import {
  initializeCandidates,
  updateManyCandidates,
} from "../../pages/Candidates/candidates.slice";
import {
  createCompany,
  deleteManyCompanies,
  initializeCompanies,
  updateCompany,
} from "./clients.slice";
import { Option } from "@/types/Option";

const enEmail = {
  subject: "A new recommended candidate",
  message:
    "<h1>A candidate has been added</h1><p><br></p><p>Hi there,</p><p><br></p><p>{{firstName}} {{lastName}} has been added to {{title}}</p>",
  language: { label: "EN", value: "EN" },
};

const czEmail = {
  subject: "Nový doporučený kandidát",
  message:
    "<h1>Byl přidán kandidát</h1><p><br></p><p>Ahoj,</p><p><br></p><p>{{firstName}} {{lastName}} byl přidán do {{title}}</p>",
  language: { label: "CZ", value: "CZ" },
};

export default function Clients() {
  const dispatch: AppDispatch = useDispatch();
  const {
    user,
    candidates,
    companies: companiesData,
  } = useSelector((state: RootState) => ({
    user: state.user,
    candidates: state.candidates,
    companies: state.companies,
  }));

  const { companies, loading, error } = companiesData;

  const navigate = useNavigate();
  const [t] = useTranslation(["common"]);

  const [searchTerm, setSearchTerm] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [openCreateModal, setCreateOpenModal] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(
    null
  );
  const [selectedCompanyIdForCandidate, setSelectedCompanyIdForCandidate] =
    useState<number | null>(null);
  const [company, setCompany] = useState<{
    name: string;
    language: Option;
    email: string;
    password: string;
  }>({
    name: "",
    language: { label: "", value: "" },
    email: "",
    password: "",
  });
  const [editedCompany, setEditedCompany] = useState<UpdateCompanyDto>({
    name: "",
    language: "",
  });
  const [assignedCandidates, setAssignedCandidates] = useState<{
    candidatesIds: Option[];
    jobId: Option;
  }>({
    candidatesIds: [],
    jobId: { label: "", value: "" },
  });
  const [email, setEmail] = useState<{
    subject: string;
    message: string;
    language: Option;
  }>({
    subject: "",
    message: "",
    language: { label: "EN", value: "EN" },
  });

  const columns: MRT_ColumnDef<CompaniesWithJobsLabel>[] = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 40,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnOrdering: false,
      },
      {
        accessorKey: "edit",
        header: "",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnOrdering: false,
        size: 40,
        Cell: ({ row }) => {
          return (
            <Button
              className="edit"
              color="primary"
              onClick={() => onEditClick(row.original.id)}
              variant="contained"
            >
              {t("edit")}
            </Button>
          );
        },
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 120,
      },
      {
        accessorKey: "language",
        header: "Language",
        size: 120,
      },
      {
        accessorKey: "jobs",
        header: "Jobs",
        Cell: ({ cell }: any) => {
          const Element = cell.getValue().map((one: string) => (
            <Typography sx={{ color: "#fff" }} className={styles.job} key={one}>
              {one}
            </Typography>
          ));
          return Element;
        },
        enableSorting: false,
        enableColumnActions: false,
        enableColumnOrdering: false,
      },
      {
        accessorKey: "actions",
        header: "Actions",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnOrdering: false,
        Cell: ({ row }) => {
          return (
            <Box className={styles.actionsWrapper}>
              <Box className={styles.actions}>
                <Button
                  color="primary"
                  onClick={() => navigate(`/clients/${row.original.id}`)}
                  sx={{ mr: 1 }}
                  variant="contained"
                >
                  Open
                </Button>
              </Box>
              <Box className={styles.actions}>
                <Button
                  color="primary"
                  onClick={() =>
                    setSelectedCompanyIdForCandidate(row.original.id)
                  }
                  variant="contained"
                >
                  AssignCandidates
                </Button>
              </Box>
            </Box>
          );
        },
      },
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const companiesWithJobsLabel = companies.map((company) => ({
    ...company,
    jobs: company.jobs.map((job) => {
      const jobTitle = job.title;
      const numOfCandidates = job.stages
        .map(({ candidates }) => candidates)
        .flat().length;

      return `${jobTitle}, ${numOfCandidates} candidates`;
    }),
  })) as CompaniesWithJobsLabel[];

  const filteredData = useMemo(() => {
    return companiesWithJobsLabel.filter((item) => {
      return item.name.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [companiesWithJobsLabel, searchTerm]);

  useEffect(() => {
    if (user) {
      dispatch(initializeCandidates());
      dispatch(initializeCompanies());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Add Company Functions */
  const handleOpenCreateModal = () => setCreateOpenModal(true);
  const handleCloseCreateModal = () => setCreateOpenModal(false);
  const handleAddCompanySubmit = () => {
    const emails = [company.email];
    const companyDto = {
      name: company.name,
      language: company.language.value || "EN",
      users: emails.map((email) => ({
        email,
        password: company.password,
      })),
    };
    dispatch(createCompany(companyDto));
    clearAddCompanyForm();
    handleCloseCreateModal();
  };
  const onCancel = () => {
    if (company.email || company.name || company.password) {
      const isConfirmed = window.confirm(
        "Are you sure you want to discard the changes?"
      );

      if (isConfirmed) {
        clearAddCompanyForm();
        handleCloseCreateModal();
      }
    } else {
      handleCloseCreateModal();
    }
  };
  const clearAddCompanyForm = () => {
    setCompany({
      name: "",
      email: "",
      password: "",
      language: { label: "", value: "" },
    });
  };

  /* Edit Company Functions */
  const onEditClick = (id: number) => setSelectedCompanyId(id);
  const handleEditCompanySubmit = () => {
    if (selectedCompanyId) {
      dispatch(updateCompany(selectedCompanyId, editedCompany));
    }
    setSelectedCompanyId(null);
  };

  useEffect(() => {
    setEditedCompany({
      name:
        companies.find((company) => company.id === selectedCompanyId)?.name ||
        "",
      language:
        companies.find((company) => company.id === selectedCompanyId)
          ?.language || "",
    });
  }, [companies, selectedCompanyId]);

  const onCancelEdit = () => {
    if (!selectedCompanyId) {
      return;
    }
    const oldCompany = companies.find(
      (company) => company.id === selectedCompanyId
    );

    if (
      editedCompany.name !== oldCompany?.name ||
      editedCompany.language !== oldCompany?.language
    ) {
      const isConfirmed = window.confirm(
        "Are you sure you want to discard the changes?"
      );

      if (isConfirmed) {
        setEditedCompany({
          name: "",
          language: "",
        });
        setSelectedCompanyId(null);
      }
    } else {
      setSelectedCompanyId(null);
    }
  };

  /* Assign Candidates Functions */
  const handleAssignCandidates = () => {
    if (
      selectedCompanyIdForCandidate &&
      assignedCandidates.candidatesIds.length > 0 &&
      assignedCandidates.jobId
    ) {
      const stageId = companies
        .find((company) => company.id === Number(selectedCompanyIdForCandidate))
        ?.jobs.find((job) => job.id === Number(assignedCandidates.jobId.value))
        ?.stages.find((stage) => stage.order === 1)?.id as number;

      const emailMessage = {
        subject: email.subject,
        message: email.message,
      };
      const candidateDto = {
        staging: {
          companyId: selectedCompanyIdForCandidate,
          jobId: Number(assignedCandidates.jobId.value),
          stageId,
          emailMessage,
        },
      };
      dispatch(
        updateManyCandidates(
          assignedCandidates.candidatesIds.map((candidate) =>
            Number(candidate.value)
          ),
          candidateDto
        )
      );

      clearAssignCandidateForm();
    }
  };
  const clearAssignCandidateForm = () => {
    setEmail({
      subject: "",
      message: "",
      language: { label: "EN", value: "EN" },
    });
    setAssignedCandidates({
      candidatesIds: [],
      jobId: { label: "", value: "" },
    });
    setSelectedCompanyIdForCandidate(null);
  };

  /* Delete Companies Functions */
  const handleDeleteCompanies = () => {
    const selectedCompanies = Object.keys(rowSelection).map(
      (key) => filteredData[key]
    );
    dispatch(
      deleteManyCompanies(selectedCompanies.map((company) => company.id))
    );
    setRowSelection({});
  };

  useEffect(() => {
    if (email.language.value === "EN") {
      setEmail(enEmail);
    } else {
      setEmail(czEmail);
    }
  }, [email.language.value]);

  return (
    <ClientsTemplate
      state={{
        loading,
        error,
      }}
      actionsBar={{
        handleDeleteCompanies,
        handleOpenCreateModal,
        setSearchTerm,
      }}
      addCompanyModal={{
        isAddCompanyModalOpen: openCreateModal,
        handleCloseCreateModal,
        company,
        setCompany,
        handleAddCompanySubmit,
        onCancel: onCancel,
      }}
      editCompanyModal={{
        isEditCompanyModalOpen: selectedCompanyId !== null,
        handleCloseEditModal: () => setSelectedCompanyId(null),
        editedCompany,
        setEditedCompany,
        handleEditCompanySubmit,
        onCancelEdit,
      }}
      assignCandidateModal={{
        isAssignCandidateModalOpen: selectedCompanyIdForCandidate !== null,
        handleCloseCandidateModal: () => setSelectedCompanyIdForCandidate(null),
        handleAssignCandidateSubmit: handleAssignCandidates,
        onCancelAssign: () => setSelectedCompanyIdForCandidate(null),
        jobs:
          companies.find(
            (company) => company.id === selectedCompanyIdForCandidate
          )?.jobs || [],
        candidates,
        setAssignedCandidates,
        assignedCandidates,
        email,
        setEmail,
      }}
      table={{
        columns,
        filteredCompanies: filteredData,
        rowSelection,
        setRowSelection,
      }}
    />
  );
}
