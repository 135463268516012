import { useForm } from "react-hook-form";
import { Box, FormControl, Typography } from "@mui/material";
import { Button, Select } from "@anytimesoftware0/anytime-ui";
import styles from "./AssignCandidateModal.module.scss";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";
import { Job } from "../../../pages/Jobs/jobs.types";
import EmailModal from "../EmailModal";
import Company from "../../../pages/Clients/clients.types";
import { Option } from "../../../types/Option";

interface AssignCandidateModalProps {
  handleCloseCandidateModal: () => void;
  handleAssignCandidateSubmit: () => void;
  setAssigneCandidate: Dispatch<
    SetStateAction<{
      companyId: Option;
      jobId: Option;
    }>
  >;
  assigneCandidate: {
    companyId: Option;
    jobId: Option;
  };
  onCancelAssign: () => void;
  jobs: Job[];
  companies: Company[];
  email: {
    subject: string;
    message: string;
    language: Option;
  };
  setEmail: Dispatch<
    SetStateAction<{
      subject: string;
      message: string;
      language: Option;
    }>
  >;
}

interface IFormInput {
  companyId: string;
  jobId: string;
}

const AssignCandidateModal: React.FC<AssignCandidateModalProps> = ({
  email,
  setEmail,
  assigneCandidate,
  handleAssignCandidateSubmit,
  onCancelAssign,
  handleCloseCandidateModal,
  setAssigneCandidate,
  companies,
  jobs,
}) => {
  const [t] = useTranslation(["common"]);

  const { handleSubmit } = useForm<IFormInput>();

  const submit = () => {
    handleAssignCandidateSubmit();
    handleCloseCandidateModal();
  };

  return (
    <form
      noValidate
      className={styles.modalContent}
      onSubmit={handleSubmit(submit)}
    >
      <Typography variant="h3" component="h3">
        Assign Candidate
      </Typography>
      <FormControl fullWidth>
        <Select
          placeholder="Select Company"
          onChange={(v) =>
            setAssigneCandidate({ ...assigneCandidate, companyId: v as Option })
          }
          isSearchable
          options={companies.map((company) => ({
            label: company.name,
            value: String(company.id),
          }))}
        />
      </FormControl>
      {Array.isArray(assigneCandidate.companyId) ? null : assigneCandidate
          .companyId?.value ? (
        <FormControl fullWidth>
          <Select
            placeholder="Select job"
            onChange={(v) => {
              setAssigneCandidate({ ...assigneCandidate, jobId: v as Option });
            }}
            isSearchable
            options={jobs.map((job) => ({
              label: job.title,
              value: String(job.id),
            }))}
          />
        </FormControl>
      ) : null}
      <Box className={styles.buttons}>
        <EmailModal email={email} setEmail={setEmail} />
        <Button type="submit" color="primary" className={styles.add}>
          {t("assignCandidate")}
        </Button>
        <Button variant="contained" color="danger" onClick={onCancelAssign}>
          {t("cancel")}
        </Button>
      </Box>
    </form>
  );
};

export default AssignCandidateModal;
