import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../utils/store";
import { AxiosError } from "axios";
import routesService from "../services/routes";
import toast from "react-hot-toast";

const initialstate = [] as string[];

const routesSlice = createSlice({
  name: "routes",
  initialState: initialstate,
  reducers: {
    setRoutes(state, action: PayloadAction<string[]>): string[] {
      return action.payload;
    },
  },
});

const { setRoutes } = routesSlice.actions;

export const initializeRoutes = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const routes = await routesService.getRoutes();
      dispatch(setRoutes(routes));
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(`Failed to fetch routes`);
      }
    }
  };
};

export default routesSlice.reducer;
