import { CreateCustomUserDto, customUser } from "./users.types";
import axios from "../../utils/axios";
import config from "../../utils/config";

const baseUrl = `${config.backendUrl}/users`;

const getAll = async (): Promise<customUser[]> => {
  const { data } = await axios.get<customUser[]>(`${baseUrl}`);
  return data;
};

const getOne = async (id: string): Promise<customUser> => {
  const { data } = await axios.get<customUser>(`${baseUrl}/${id}`);
  return data;
};

const deleteOne = async (id: number): Promise<customUser> => {
  const { data } = await axios.delete<customUser>(`${baseUrl}/${id}`);
  return data;
};

const addOne = async (user: CreateCustomUserDto): Promise<customUser> => {
  const userWithRole: CreateCustomUserDto = {
    ...user,
  };

  const { data } = await axios.post<customUser>(
    `${baseUrl}/with-permissions`,
    userWithRole
  );
  return data;
};

const customUsersService = {
  getAll,
  getOne,
  addOne,
  deleteOne,
};

export default customUsersService;
