import { ActionsBar, Table, Modal, Loader } from "@anytimesoftware0/anytime-ui";
import { CompaniesWithJobsLabel } from "../../../pages/Clients/clients.types";
import AddCompanyModal from "../../../pages/Clients/AddCompanyModal";
import { Grid } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { Dispatch, SetStateAction } from "react";
import EditCompanyModal from "../../../pages/Clients/EditCompanyModal";
import { Job } from "../../../pages/Jobs/jobs.types";
import Candidate from "../../../pages/Candidates/candidates.types";
import AssignCandidateModal from "../../../pages/Clients/AssignCandidateModal";
import { Option } from "../../../types/Option";

export interface ClientsProps {
  state: {
    error: string | null;
    loading: boolean;
  };
  table: {
    columns: MRT_ColumnDef<CompaniesWithJobsLabel>[];
    filteredCompanies: CompaniesWithJobsLabel[];
    rowSelection: { [key: string]: boolean };
    setRowSelection: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  };
  addCompanyModal: {
    isAddCompanyModalOpen: boolean;
    handleCloseCreateModal: () => void;
    company: {
      name: string;
      language: Option;
      email: string;
      password: string;
    };
    setCompany: Dispatch<
      SetStateAction<{
        name: string;
        language: Option;
        email: string;
        password: string;
      }>
    >;
    handleAddCompanySubmit: () => void;
    onCancel: () => void;
  };
  editCompanyModal: {
    isEditCompanyModalOpen: boolean;
    handleCloseEditModal: () => void;
    editedCompany: {
      name: string;
      language: string;
    };
    setEditedCompany: Dispatch<
      SetStateAction<{
        name: string;
        language: string;
      }>
    >;
    handleEditCompanySubmit: () => void;
    onCancelEdit: () => void;
  };
  assignCandidateModal: {
    isAssignCandidateModalOpen: boolean;
    handleCloseCandidateModal: () => void;
    handleAssignCandidateSubmit: () => void;
    setAssignedCandidates: Dispatch<
      SetStateAction<{
        candidatesIds: Option[];
        jobId: Option;
      }>
    >;
    assignedCandidates: {
      candidatesIds: Option[];
      jobId: Option;
    };
    onCancelAssign: () => void;
    jobs: Job[];
    candidates: Candidate[];
    email: {
      subject: string;
      message: string;
      language: Option;
    };
    setEmail: Dispatch<
      SetStateAction<{
        subject: string;
        message: string;
        language: Option;
      }>
    >;
  };
  actionsBar: {
    setSearchTerm: Dispatch<SetStateAction<string>>;
    handleDeleteCompanies: () => void;
    handleOpenCreateModal: () => void;
  };
}

const ClientsTemplate: React.FC<ClientsProps> = ({
  addCompanyModal,
  editCompanyModal,
  assignCandidateModal,
  table,
  actionsBar,
  state,
}) => {
  const {
    isAddCompanyModalOpen,
    handleCloseCreateModal,
    company,
    setCompany,
    handleAddCompanySubmit,
    onCancel,
  } = addCompanyModal;
  const {
    isEditCompanyModalOpen,
    handleCloseEditModal,
    editedCompany,
    setEditedCompany,
    handleEditCompanySubmit,
    onCancelEdit,
  } = editCompanyModal;
  const {
    isAssignCandidateModalOpen,
    handleCloseCandidateModal,
    handleAssignCandidateSubmit,
    setAssignedCandidates,
    assignedCandidates,
    onCancelAssign,
    jobs,
    candidates,
    email,
    setEmail,
  } = assignCandidateModal;
  const { columns, filteredCompanies, rowSelection, setRowSelection } = table;
  const { setSearchTerm, handleDeleteCompanies, handleOpenCreateModal } =
    actionsBar;
  const { loading } = state;

  return (
    <>
      <Modal isOpen={isAddCompanyModalOpen} onCancel={onCancel}>
        <AddCompanyModal
          company={company}
          setCompany={setCompany}
          onCancel={onCancel}
          handleCloseCreateModal={handleCloseCreateModal}
          handleAddCompanySubmit={handleAddCompanySubmit}
        />
      </Modal>
      {isEditCompanyModalOpen && (
        <Modal isOpen={true} onCancel={onCancelEdit}>
          <EditCompanyModal
            editedCompany={editedCompany}
            setEditedCompany={setEditedCompany}
            onCancelEdit={onCancelEdit}
            handleCloseCreateModal={handleCloseEditModal}
            handleEditCompanySubmit={handleEditCompanySubmit}
          />
        </Modal>
      )}
      {isAssignCandidateModalOpen && (
        <Modal isOpen={true} onCancel={onCancelAssign}>
          <AssignCandidateModal
            assignedCandidates={assignedCandidates}
            handleAssignCandidateSubmit={handleAssignCandidateSubmit}
            onCancelAssign={onCancelAssign}
            handleCloseCandidateModal={handleCloseCandidateModal}
            setAssignedCandidates={setAssignedCandidates}
            candidates={candidates}
            jobs={jobs}
            email={email}
            setEmail={setEmail}
          />
        </Modal>
      )}
      <ActionsBar
        searchHandler={setSearchTerm}
        showDelete={Object.keys(rowSelection).length > 0}
        addBtnLabel="Add Company"
        deleteHandler={handleDeleteCompanies}
        deleteBtnLabel={
          Object.keys(rowSelection).length > 1
            ? "Delete Companies"
            : "Delete Company"
        }
        addHandler={handleOpenCreateModal}
      />
      {loading ? (
        <Loader />
      ) : (
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Table
              onRowSelectionChange={setRowSelection}
              columns={columns}
              data={filteredCompanies}
              rowSelection={rowSelection}
              enableColumnOrdering
              enableSorting
              enableColumnActions
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ClientsTemplate;
