import { Box, FormControlLabel, Typography } from "@mui/material";
import styles from "./ColumnsEditModal.module.scss";
import { Checkbox } from "@anytimesoftware0/anytime-ui";

interface ColumnsEditFormProps {
  SelectedColumnsToShow: string[];
  handleCheckboxChange: (column: string) => void;
}
const ColumnsEditForm: React.FC<ColumnsEditFormProps> = ({
  SelectedColumnsToShow,
  handleCheckboxChange,
}) => {
  return (
    <form noValidate className={styles.modalContent}>
      <Typography variant="h3" component="h3">
        Edit Columns
      </Typography>
      <Box className={styles.columnsContainer}>
        {[
          "ID",
          "Actions",
          "First Name",
          "Last Name",
          "Email",
          "Linkedin URL",
          "Stage",
          "CV File",
        ].map((column) => (
          <FormControlLabel
            key={column}
            control={
              <Checkbox
                isChecked={SelectedColumnsToShow.includes(column)}
                onChange={() => handleCheckboxChange(column)}
              />
            }
            label={column}
            className={styles.columnLabel}
          />
        ))}
      </Box>
    </form>
  );
};

export default ColumnsEditForm;
