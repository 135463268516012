import { MRT_RowData, type MRT_ColumnDef } from "material-react-table";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../utils/store";
import { useEffect, useMemo, useState } from "react";
import {
  initializeCustomUsers,
  createOneCustomUser,
  deleteManyCustomUsers,
} from "./users.slice";
import { initializeRoles } from "../../pages/Roles/roles.slice";
import UsersTemplate from "../../components/templates/UsersTemplate";
import { Option } from "../../types/Option";

export default function Users() {
  const dispatch: AppDispatch = useDispatch();
  const {
    user,
    customUsers: users,
    roles: { roles },
  } = useSelector((state: RootState) => ({
    user: state.user,
    customUsers: state.customUsers,
    roles: state.roles,
  }));

  const [openCreateModal, setCreateOpenModal] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [userData, setUserData] = useState<{
    email: string;
    role: Option | Option[] | undefined;
    password: string;
  }>({
    email: "",
    role: { label: "", value: "" },
    password: "",
  });

  const { customUsers, error, loading } = users;

  const columns: MRT_ColumnDef<MRT_RowData>[] = useMemo(
    () =>
      [
        {
          accessorKey: "id",
          header: "ID",
          size: 40,
        },

        {
          accessorKey: "email",
          header: "Email",
          size: 120,
        },
        {
          accessorKey: "role",
          header: "Role",
          size: 120,
        },
      ] as any,
    []
  );

  const handleOpenCreateModal = () => setCreateOpenModal(true);

  const handleCloseCreateModal = () => setCreateOpenModal(false);

  const onCancel = () => {
    if (userData.email || userData.password) {
      const isConfirmed = window.confirm(
        "Are you sure you want to discard the changes?"
      );

      if (isConfirmed) {
        clear();
        handleCloseCreateModal();
      }
    } else {
      handleCloseCreateModal();
    }
  };

  const clear = () =>
    setUserData({
      email: "",
      role: { label: "", value: "" },
      password: "",
    });

  const handleAddUserSubmit = () => {
    if (userData.email && userData.password && userData.role) {
      const user = {
        email: userData.email,
        password: userData.password,
        role: !Array.isArray(userData.role)
          ? (userData.role.value as string)
          : (userData.role[0].value as string),
      };
      dispatch(createOneCustomUser(user));
    }
    clear();
    setCreateOpenModal(false);
  };

  const filteredCustomUsers = useMemo(() => {
    return customUsers.filter((customUser) => {
      return (
        customUser.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        customUser.role.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }, [customUsers, searchTerm]);

  const handleDeleteCustomUser = () => {
    const customUsers = Object.keys(rowSelection).map(
      (key) => filteredCustomUsers[key]
    );
    dispatch(
      deleteManyCustomUsers(customUsers.map((customUser) => customUser.id))
    );
    setRowSelection({});
  };

  useEffect(() => {
    if (user) {
      dispatch(initializeCustomUsers());
      dispatch(initializeRoles());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UsersTemplate
      state={{
        error,
        loading,
      }}
      addUserModal={{
        isAddUserModalOpen: openCreateModal,
        handleCloseCreateModal,
        onCancel,
        roles,
        user: userData,
        setUser: setUserData,
        handleAddUserSubmit,
      }}
      table={{
        columns,
        filteredCustomUsers,
        rowSelection,
        setRowSelection,
      }}
      actionsBar={{
        setSearchTerm,
        handleDeleteCustomUser,
        handleOpenCreateModal,
      }}
    />
  );
}
