import { Modal, ActionsBar, Loader, Table } from "@anytimesoftware0/anytime-ui";
import AddJobModal from "../../../pages/Jobs/AddJobModal";
import EditJobModal from "../../../pages/Jobs/EditJobModal";
import AssignToClientModal from "../../../pages/Jobs/AssignToClientModal";
import { Grid } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { JobWithCandidates } from "../../../pages/Jobs/jobs.types";
import { Dispatch, SetStateAction } from "react";
import Candidate from "../../../pages/Candidates/candidates.types";
import AssignCandidatesModal from "../../../pages/Clients/[id]/AssignCandidateModal";
import { Option } from "../../../types/Option";

export interface SubClientTemplateProps {
  state: {
    error: string | null;
    loading: boolean;
  };
  table: {
    columns: MRT_ColumnDef<JobWithCandidates>[];
    filteredJobs: JobWithCandidates[];
    rowSelection: { [key: string]: boolean };
    setRowSelection: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  };
  addJobModal: {
    isAddJobModalOpen: boolean;
    handleCloseCreateModal: () => void;
    handleAddJobSubmit: () => void;
    setJob: Dispatch<
      SetStateAction<{
        title: string;
        description: string;
      }>
    >;
    job: {
      title: string;
      description: string;
    };
    onCancel: () => void;
  };
  editJobModal: {
    isEditJobModalOpen: boolean;
    handleCloseEditModal: () => void;
    handleEditJobSubmit: () => void;
    setEditedJob: Dispatch<
      SetStateAction<{
        title: string;
        description: string;
      }>
    >;
    editedJob: {
      title: string;
      description: string;
    };
    onCancelEdit: () => void;
  };
  assignCandidateModal: {
    isAssignCandidateModalOpen: boolean;
    handleCloseCandidateModal: () => void;
    handleAssignCandidateSubmit: () => void;
    setAssignedCandidates: Dispatch<SetStateAction<Option[]>>;
    assignedCandidates: Option[];
    onCancelAssign: () => void;
    candidates: Candidate[];
    email: {
      subject: string;
      message: string;
      language: Option;
    };
    setEmail: Dispatch<
      SetStateAction<{
        subject: string;
        message: string;
        language: Option;
      }>
    >;
  };
  assignClientModal: {
    isAssignClientModalOpen: boolean;
    handleCloseAssignModal: () => void;
    handleAssignSubmit: () => void;
    setAssignedClient: Dispatch<
      SetStateAction<{
        email: string;
      }>
    >;
    assignedClient: {
      email: string;
    };
    onCancelAssign: () => void;
  };
  actionsBar: {
    setSearchTerm: Dispatch<SetStateAction<string>>;
    handleDeleteJobs: () => void;
    handleOpenCreateModal: () => void;
  };
}

const SubClientTemplate: React.FC<SubClientTemplateProps> = ({
  addJobModal,
  editJobModal,
  assignCandidateModal,
  table,
  actionsBar,
  state,
  assignClientModal,
}) => {
  const {
    isAddJobModalOpen,
    handleCloseCreateModal,
    job,
    setJob,
    handleAddJobSubmit,
    onCancel,
  } = addJobModal;
  const {
    isEditJobModalOpen,
    handleCloseEditModal,
    handleEditJobSubmit,
    setEditedJob,
    editedJob,
    onCancelEdit,
  } = editJobModal;
  const {
    isAssignCandidateModalOpen,
    assignedCandidates,
    handleAssignCandidateSubmit,
    setAssignedCandidates,
    candidates,
    email,
    handleCloseCandidateModal,
    setEmail,
    onCancelAssign: onAssignCancel,
  } = assignCandidateModal;
  const {
    isAssignClientModalOpen,
    handleCloseAssignModal,
    handleAssignSubmit,
    setAssignedClient,
    assignedClient,
    onCancelAssign,
  } = assignClientModal;
  const { columns, filteredJobs, rowSelection, setRowSelection } = table;
  const { setSearchTerm, handleDeleteJobs, handleOpenCreateModal } = actionsBar;
  const { loading } = state;

  return (
    <>
      <Modal isOpen={isAddJobModalOpen} onCancel={onCancel}>
        <AddJobModal
          job={job}
          setJob={setJob}
          onCancel={onCancel}
          handleCloseCreateModal={handleCloseCreateModal}
          handleAddJobSubmit={handleAddJobSubmit}
        />
      </Modal>
      {isEditJobModalOpen && (
        <Modal isOpen={true} onCancel={onCancelEdit}>
          <EditJobModal
            editedJob={editedJob}
            setEditedJob={setEditedJob}
            onCancelEdit={onCancelEdit}
            handleCloseEditModal={handleCloseEditModal}
            handleEditJobSubmit={handleEditJobSubmit}
          />
        </Modal>
      )}
      {isAssignClientModalOpen && (
        <Modal isOpen={true} onCancel={onCancelAssign}>
          <AssignToClientModal
            assignedClient={assignedClient}
            handleAssignSubmit={handleAssignSubmit}
            onCancelAssign={onCancelAssign}
            handleCloseAssignModal={handleCloseAssignModal}
            setAssignedClient={setAssignedClient}
          />
        </Modal>
      )}
      {isAssignCandidateModalOpen && (
        <Modal isOpen={true} onCancel={onAssignCancel}>
          <AssignCandidatesModal
            email={email}
            setEmail={setEmail}
            assignedCandidates={assignedCandidates}
            handleAssignCandidateSubmit={handleAssignCandidateSubmit}
            onCancelAssign={onAssignCancel}
            candidates={candidates}
            handleCloseCandidateModal={handleCloseCandidateModal}
            setAssignedCandidates={setAssignedCandidates}
          />
        </Modal>
      )}
      <ActionsBar
        searchHandler={setSearchTerm}
        showDelete={Object.keys(rowSelection).length > 0}
        addBtnLabel="Add Job"
        deleteHandler={handleDeleteJobs}
        deleteBtnLabel={
          Object.keys(rowSelection).length > 1 ? "Delete Jobs" : "Delete Job"
        }
        addHandler={handleOpenCreateModal}
      />
      {loading ? (
        <Loader />
      ) : (
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Table
              onRowSelectionChange={setRowSelection}
              columns={columns}
              data={filteredJobs}
              rowSelection={rowSelection}
              enableColumnOrdering
              enableSorting
              enableColumnActions
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SubClientTemplate;
