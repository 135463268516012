import { useSelector } from "react-redux";
import { RootState } from "../../utils/store";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function Home() {
  const user = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [navigate, user]);

  return <p>{`${user?.role} Home`}</p>;
}
