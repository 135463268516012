import { type MRT_ColumnDef } from "material-react-table";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../utils/store";
import { useEffect, useMemo, useState } from "react";
import { updateManyCandidates } from "../../pages/Candidates/candidates.slice";
import { Link } from "@mui/material";
import Candidate from "../../pages/Candidates/candidates.types";
import { initializeCompanies } from "../../pages/Clients/clients.slice";
import { UserRole } from "../../pages/Users/users.types";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import stagesService from "./stages.api";
import { Stage, StageWithCandidates } from "./stages.types";
import StagesTemplate from "../../components/templates/StagesTemplate";
import { Button } from "@anytimesoftware0/anytime-ui";
import { Option } from "../../types/Option";

export default function Stages() {
  const jobId = useParams().id;
  const [t] = useTranslation(["common"]);
  const dispatch: AppDispatch = useDispatch();
  const { user, companies } = useSelector((state: RootState) => ({
    user: state.user,
    companies: state.companies,
  }));

  const job = companies.companies
    .map((company) => company.jobs)
    ?.flat()
    ?.find((job) => job.id === Number(jobId));

  const stagesNoRepetitions = job?.stages
    .slice()
    .sort((a, b) => a.order - b.order)
    .map(({ id, title, order }) => ({ id, title, order }));
  const [newOrder, setNewOrder] = useState(() => stagesNoRepetitions || []);

  // Sorting Current Stages By Order
  const stagesSortedByOrder = useMemo(() => {
    return job?.stages?.slice().sort((a, b) => a.order - b.order) || undefined;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job, companies.companies, job?.stages.length]);

  useEffect(() => {
    setNewOrder(stagesNoRepetitions || []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stagesSortedByOrder]);

  const [addStageName, setAddStageName] = useState("");
  const [editTitleStage, setEditTitleStage] = useState("");
  const [openStagingModal, setOpenStagingModal] = useState(false);
  const [openAddStageModal, setOpenAddStageModal] = useState(false);
  const [openEditStageModal, setOpenEditStageModal] = useState(false);
  const [openDeleteStageModal, setOpenDeleteStageModal] = useState(false);
  const [openOrderStageModal, setOpenOrderStageModal] = useState(false);
  const [activeStage, setActiveStage] = useState<
    StageWithCandidates | undefined
  >(stagesSortedByOrder ? stagesSortedByOrder[0] : undefined);
  const [searchTerm, setSearchTerm] = useState("");

  const candidates = job?.stages
    .map((stage) => ({
      candidates: stage.candidates.map((candidate) => ({
        ...candidate,
        stage: {
          id: stage,
          title: stage.title,
          order: stage.order,
          job: {
            job: job.id,
            title: job.title,
          },
        },
      })),
    }))
    .map((stage) => stage.candidates)
    .flat();

  const [selectedCandidatesIds, setSelectedCandidatesIds] = useState<number[]>(
    []
  );
  const [selectedEditStage, setSelectedEditStage] = useState<Stage | null>(
    null
  );

  const [selectedStage, setSelectedStage] = useState<Option>();

  const [selectedDeleteStage, setSelectedDeleteStage] = useState<Stage | null>(
    null
  );
  const columns: MRT_ColumnDef<Candidate>[] = useMemo(() => {
    const customVariables = candidates?.map(
      ({ customVariables }) => customVariables
    );
    const customColumns = Array.from(
      new Set(
        customVariables
          ?.map((customVariable) => Object.keys(customVariable))
          .flat()
      )
    ).map((customVariableKey) => {
      const spacedHeader = customVariableKey.replace(/([A-Z])/g, " $1");
      const header =
        spacedHeader.charAt(0).toUpperCase() + spacedHeader.slice(1);

      return {
        accessorKey: `customVariables.${customVariableKey}` as any,
        header,
      };
    });

    return [
      {
        accessorKey: "id",
        header: "ID",
        size: 20,
      },
      {
        header: "Actions",
        size: 400,
        Cell: ({ row }: any) => (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectedCandidatesIds([row.original.id]);
              handleOpenStagingModal();
            }}
          >
            Move to Stage
          </Button>
        ),
      },
      {
        accessorKey: "firstName",
        header: "First Name",
        size: 120,
      },
      {
        accessorKey: "lastName",
        header: "Last Name",
        size: 120,
      },
      {
        accessorKey: "email",
        header: "Email",
        enableClickToCopy: true,
        size: 300,
      },
      {
        accessorKey: "linkedinUrl",
        header: "Linkedin URL",
      },
      ...customColumns,
      {
        accessorKey: "file",
        header: "CV File",
        Cell: ({ cell }: any) => {
          return cell.getValue() ? (
            <Link color="primary" href={cell.getValue()} download>
              {t("download")}
            </Link>
          ) : null;
        },
      },

      {
        header: "Order",
        accessorKey: "order",
      },
      {
        header: "Hidden",
        accessorKey: "isHidden",
      },
    ] as any;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidates]);
  interface CustomColumnDef<T> {
    accessorKey: keyof T;
    header: string;
  }

  const orderingStagesColumns: CustomColumnDef<{
    id: number;
    title: string;
    order: number;
  }>[] = [{ accessorKey: "title", header: "Stage Title" }];

  const handleOpenStagingModal = () => setOpenStagingModal(true);
  const handleCloseStagingModal = () => setOpenStagingModal(false);
  const handleOpenAddStageModal = () => setOpenAddStageModal(true);
  const handleCloseAddStageModal = () => setOpenAddStageModal(false);
  const handleOpenDeleteStageModal = () => {
    setOpenDeleteStageModal(true);
  };
  const handleCloseDeleteStageModal = () => {
    setSelectedDeleteStage(null);
    setOpenDeleteStageModal(false);
  };
  const handleOpenEditStageModal = () => {
    setOpenEditStageModal(true);
  };
  const handleCloseEditStageModal = () => {
    setSelectedEditStage(null);
    setOpenEditStageModal(false);
  };
  const handleOpenOrderStageModal = () => {
    setOpenOrderStageModal(true);
  };
  const handleCloseOrderStageModal = () => {
    setOpenOrderStageModal(false);
  };
  const handleStaginCandidate = () => {
    if (selectedCandidatesIds) {
      const candidateDto = {
        staging: {
          companyId: companies.companies[0].id,
          jobId: job?.id,
          stageId: selectedStage?.value,
        },
      };
      dispatch(
        updateManyCandidates(
          selectedCandidatesIds.filter((id) => typeof id === "number"),
          candidateDto as any,
          user?.role === UserRole.CLIENT
        )
      );
      setActiveStage(
        job?.stages.find((stage) => stage.id === Number(selectedStage?.value))
      );
      dispatch(initializeCompanies(user?.role === UserRole.CLIENT));
      setSelectedCandidatesIds([]);
      setSelectedStage(undefined);
      handleCloseStagingModal();
    }
  };
  const handleAddStage = () => {
    const title = addStageName;
    if (job) {
      stagesService
        .create(job?.id, {
          title,
          order: job.stages.length + 1,
        })
        .then(() => {
          dispatch(initializeCompanies(user?.role === UserRole.CLIENT));
        });
    }
    handleCloseAddStageModal();
  };
  const handleDeleteStage = () => {
    if (job) {
      if (selectedDeleteStage) {
        stagesService.remove(job?.id, selectedDeleteStage?.id).then(() => {
          dispatch(initializeCompanies(user?.role === UserRole.CLIENT));
        });
      }
    }
    setSelectedDeleteStage(null);
    handleCloseDeleteStageModal();
  };

  const handleEditStage = () => {
    if (selectedEditStage) {
      const title = editTitleStage;
      if (job && selectedEditStage?.id !== undefined) {
        stagesService
          .update(job?.id, selectedEditStage?.id, {
            title: title,
            order: selectedEditStage?.order,
          })
          .then(() => {
            dispatch(initializeCompanies(user?.role === UserRole.CLIENT));
          });
      }
      setSelectedEditStage(null);
      handleCloseEditStageModal();
    }
  };
  const handleOrderStages = () => {
    newOrder.forEach((stage, index) => {
      if (job) {
        stagesService
          .update(job?.id, stage.id, {
            title: stage.title,
            order: index + 1,
          })
          .then(() => {
            dispatch(initializeCompanies(user?.role === UserRole.CLIENT));
          });
      }
    });
    handleCloseOrderStageModal();
  };

  useEffect(() => {
    if (user && companies.companies?.length <= 0) {
      dispatch(initializeCompanies(user.role === UserRole.CLIENT));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredData = useMemo(() => {
    return activeStage?.candidates.filter((candidate) => {
      return (
        candidate.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        candidate.lastName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStage, searchTerm]);

  return (
    <StagesTemplate
      state={{
        loading: false,
        error: null,
      }}
      table={{
        columns: columns,
        filteredCandidates: filteredData || [],
        rowSelection: {},
        setRowSelection: () => {},
      }}
      actionsBar={{
        setSearchTerm,
        handleOpenDeleteStageModal,
        handleOpenAddStageModal,
        handleOpenEditStageModal,
        handleOpenOrderStageModal,
      }}
      stageSwitcher={{
        uniqueStages: stagesSortedByOrder || [],
        activeStage: activeStage,
        setActiveStage,
      }}
      stagingModal={{
        handleCloseStagingModal,
        handleSubmitStaging: handleStaginCandidate,
        isOpenStagingModal: openStagingModal,
        setSelectedStage,
        stages: stagesSortedByOrder || [],
      }}
      addStageModal={{
        addStageName,
        setAddStageName,
        handleAddStageSubmit: handleAddStage,
        handleCloseCreateModal: handleCloseAddStageModal,
        isCreateStageModalOpen: openAddStageModal,
      }}
      editStageModal={{
        isOpenEditStageModal: openEditStageModal,
        editTitleStage,
        handleCloseEditModal: handleCloseEditStageModal,
        handleEditStageSubmit: handleEditStage,
        setEditTitleStage,
        setSelectedEditStage,
        stages: stagesSortedByOrder || [],
      }}
      deleteStageModal={{
        isOpenDeleteStageModal: openDeleteStageModal,
        handleCloseDeleteModal: handleCloseDeleteStageModal,
        handleDeleteStageSubmit: handleDeleteStage,
        setSelectedDeleteStage,
        stages: stagesSortedByOrder || [],
      }}
      orderingModal={{
        columns: orderingStagesColumns,
        newOrder,
        handleCloseOrderingModal: handleCloseOrderStageModal,
        isOrderingModalOpen: openOrderStageModal,
        handleOrderStagesSubmit: handleOrderStages,
        setNewOrder,
      }}
    />
  );
}
