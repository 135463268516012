import Company from "../../../pages/Clients/clients.types";
import { Checkbox } from "@anytimesoftware0/anytime-ui";
import { t } from "i18next";
import {
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import styles from "./filters.module.scss";

interface FiltersProps {
  filteredCompanies: string[];
  setFilteredCompanies: (companies: string[]) => void;
  filteredJobs: string[];
  setFiliteredJobs: (jobs: string[]) => void;
  filteredStages: string[];
  setFiliteredStages: (stages: string[]) => void;
  companies: Company[];
}

const Filters: React.FC<FiltersProps> = ({
  filteredCompanies,
  setFilteredCompanies,
  filteredJobs,
  setFiliteredJobs,
  filteredStages,
  setFiliteredStages,
  companies,
}) => {
  return (
    <Box className={styles.filtersWrapper}>
      <Box>
        <Typography variant="h6">{t("filterByCompanies")}</Typography>
        <Divider />
        <List className={styles.list}>
          {companies.map(({ name: companyName }) => (
            <ListItem key={companyName}>
              <ListItemButton
                role={undefined}
                onClick={(e) =>
                  filteredCompanies.some((company) => company === companyName)
                    ? setFilteredCompanies(
                        Array.from(
                          new Set(
                            filteredCompanies.filter(
                              (company) => company !== companyName
                            )
                          )
                        )
                      )
                    : setFilteredCompanies(
                        Array.from(new Set([...filteredCompanies, companyName]))
                      )
                }
                dense
              >
                <ListItemIcon>
                  <Checkbox
                    isChecked={filteredCompanies.some(
                      (name) => name === companyName
                    )}
                    onChange={(e) =>
                      e
                        ? setFiliteredJobs(
                            Array.from(new Set([...filteredJobs, companyName]))
                          )
                        : setFiliteredJobs(
                            Array.from(
                              new Set(
                                filteredJobs.filter(
                                  (job) => job !== companyName
                                )
                              )
                            )
                          )
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  id={companyName}
                  primary={companyName}
                  primaryTypographyProps={{ style: { color: "white" } }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
      <Box>
        <Typography variant="h6">{t("filterByJobs")}</Typography> <Divider />
        <List className={styles.list}>
          {companies.map(({ jobs, name }) =>
            jobs
              .filter((job) =>
                filteredCompanies.length === 0
                  ? true
                  : filteredCompanies.includes(name)
              )
              .map((job) => {
                const jobId = job.id;
                const jobTitle = job.title;
                const labelId = `checkbox-list-label-${jobTitle}`;

                return (
                  <ListItem key={jobId}>
                    <ListItemButton
                      role={undefined}
                      onClick={(e) => {
                        setFiliteredStages([]);
                        return filteredJobs.some((job) => job === jobTitle)
                          ? setFiliteredJobs(
                              Array.from(
                                new Set(
                                  filteredJobs.filter((job) => job !== jobTitle)
                                )
                              )
                            )
                          : setFiliteredJobs(
                              Array.from(new Set([...filteredJobs, jobTitle]))
                            );
                      }}
                      dense
                    >
                      <ListItemIcon>
                        <Checkbox
                          isChecked={filteredJobs.some(
                            (job) => job === jobTitle
                          )}
                          onChange={(e) =>
                            e
                              ? setFiliteredJobs(
                                  Array.from(
                                    new Set([...filteredJobs, jobTitle])
                                  )
                                )
                              : setFiliteredJobs(
                                  Array.from(
                                    new Set(
                                      filteredJobs.filter(
                                        (job) => job !== jobTitle
                                      )
                                    )
                                  )
                                )
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={jobTitle}
                        primaryTypographyProps={{
                          style: { color: "white" },
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })
          )}
        </List>
      </Box>
      {filteredJobs.length > 0 && (
        <Box>
          <Typography variant="h6" textAlign="center">
            {t("filterByStages")}
          </Typography>
          <Divider />
          <List className={styles.list}>
            {companies.map(({ jobs, name }) =>
              jobs
                .filter((job) =>
                  filteredCompanies.length === 0
                    ? true
                    : filteredCompanies.includes(name)
                )
                .map(({ stages }) => stages)
                .flat()
                .filter(
                  (stage, index, stages) =>
                    !stages
                      .slice(index + 1)
                      .some((s) => s.title === stage.title)
                )
                .map((stage) => {
                  const stageId = stage.id;
                  const stageTitle = stage.title;
                  const labelId = `checkbox-list-label-${stageTitle}`;

                  return (
                    <ListItem key={stageId}>
                      <ListItemButton
                        role={undefined}
                        onClick={(e) =>
                          filteredStages.some((stage) => stage === stageTitle)
                            ? setFiliteredStages(
                                Array.from(
                                  new Set(
                                    filteredStages.filter(
                                      (stage) => stage !== stageTitle
                                    )
                                  )
                                )
                              )
                            : setFiliteredStages(
                                Array.from(
                                  new Set([...filteredStages, stageTitle])
                                )
                              )
                        }
                        dense
                      >
                        <ListItemIcon>
                          <Checkbox
                            isChecked={filteredStages.some(
                              (stage) => stage === stageTitle
                            )}
                            onChange={(e) =>
                              e
                                ? setFiliteredStages(
                                    Array.from(
                                      new Set([...filteredStages, stageTitle])
                                    )
                                  )
                                : setFiliteredStages(
                                    Array.from(
                                      new Set(
                                        filteredStages.filter(
                                          (stage) => stage !== stageTitle
                                        )
                                      )
                                    )
                                  )
                            }
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={stageTitle}
                          primaryTypographyProps={{
                            style: { color: "white" },
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })
            )}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default Filters;
