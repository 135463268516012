import { useForm } from "react-hook-form";
import { Box, FormControl, Typography } from "@mui/material";
import { Button, Select } from "@anytimesoftware0/anytime-ui";
import styles from "./StagingModal.module.scss";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";
import { Stage } from "../stages.types";
import { Option } from "../../../types/Option";

interface StagingModalProps {
  handleCloseStagingModal: () => void;
  handleSubmitStaging: () => void;
  stages: Stage[];
  setSelectedStage: Dispatch<SetStateAction<Option | undefined>>;
}

const StagingModal: React.FC<StagingModalProps> = ({
  setSelectedStage,
  stages,
  handleCloseStagingModal,
  handleSubmitStaging,
}) => {
  const [t] = useTranslation(["common"]);
  const { handleSubmit } = useForm();

  const submit = () => {
    handleSubmitStaging();
    handleCloseStagingModal();
  };

  return (
    <form
      noValidate
      className={styles.modalContent}
      onSubmit={handleSubmit(submit)}
    >
      <Typography variant="h3" component="h3">
        {t("changeStageHeader")}
      </Typography>
      <FormControl fullWidth>
        <Select
          placeholder="Select stage"
          onChange={(v) => setSelectedStage(v as Option)}
          isSearchable
          options={stages.map((stage) => ({
            label: stage.title,
            value: String(stage.id),
          }))}
        />
      </FormControl>
      <Box className={styles.buttons}>
        <Button type="submit" color="primary" className={styles.add}>
          {t("save")}
        </Button>
        <Button
          variant="contained"
          color="danger"
          onClick={handleCloseStagingModal}
        >
          {t("cancel")}
        </Button>
      </Box>
    </form>
  );
};

export default StagingModal;
