import { useForm } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import { Button } from "@anytimesoftware0/anytime-ui";
import { TextField } from "@anytimesoftware0/anytime-ui";
import styles from "./AddStageModal.module.scss";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";

interface AddStageModalProps {
  handleCloseCreateModal: () => void;
  handleAddStageSubmit: () => void;
  setAddStageName: Dispatch<SetStateAction<string>>;
  addStageName: string;
}

interface IFormInput {
  title: string;
}

const AddStageModal: React.FC<AddStageModalProps> = ({
  handleCloseCreateModal,
  handleAddStageSubmit,
  addStageName,
  setAddStageName,
}) => {
  const [t] = useTranslation(["common"]);
  const title = addStageName;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();

  const submit = () => {
    handleAddStageSubmit();
    handleCloseCreateModal();
  };

  return (
    <form
      noValidate
      className={styles.modalContent}
      onSubmit={handleSubmit(submit)}
    >
      <Typography variant="h3" component="h3">
        Add Stage
      </Typography>
      <TextField
        required
        fullWidth
        onChange={(e) => setAddStageName(e.target.value)}
        value={title}
        error={!!errors.title}
        helperText={errors.title?.message}
        register={register}
        validations={{
          required: { value: true, message: "title is required" },
        }}
        label={t("title")}
        name="title"
        focused
      />
      <Box className={styles.buttons}>
        <Button type="submit" color="primary" className={styles.add}>
          {t("save")}
        </Button>
        <Button
          variant="contained"
          color="danger"
          onClick={handleCloseCreateModal}
        >
          {t("cancel")}
        </Button>
      </Box>
    </form>
  );
};

export default AddStageModal;
