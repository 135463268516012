import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, TextField } from "@anytimesoftware0/anytime-ui";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import styles from "./Login.module.scss";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

interface AuthTemplateProps {
  email: string;
  password: string;
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
  handleSubmitForm: () => void;
}

interface IFormInput {
  email: string;
  password: string;
}

const AuthTemplate: React.FC<AuthTemplateProps> = ({
  email,
  password,
  setEmail,
  setPassword,
  handleSubmitForm,
}) => {
  const [t] = useTranslation(["common"]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage:
            "url(https://media.istockphoto.com/id/1384110533/photo/asian-male-director-is-interviewing-to-recruit-new-employees.jpg?b=1&s=170667a&w=0&k=20&c=RKlwV1dxFayGWKLj3eu9PwNekIzQwQBtRkEjHVEEnYI=)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
          opacity: 0.8,
        }}
      />
      <Grid
        item
        sx={{ bgcolor: "#111525" }}
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box className={styles.container}>
          <Avatar>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("signIn")}
          </Typography>
          <form
            onSubmit={handleSubmit(handleSubmitForm)}
            noValidate
            className={styles.form}
          >
            <TextField
              required
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              error={!!errors.email}
              helperText={errors.email?.message}
              register={register}
              validations={{
                required: { value: true, message: "Email is required" },
                pattern: { value: /^\S+@\S+$/i, message: "Invalid email" },
              }}
              label={t("email")}
              name="email"
              focused
            />
            <TextField
              required
              fullWidth
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              error={!!errors.password}
              helperText={errors.password?.message}
              register={register}
              validations={{
                required: { value: true, message: "Password is required" },
              }}
              label={t("password")}
              name="password"
              type="password"
            />
            <Button type="submit" className={styles.button} variant="contained">
              Sign In
            </Button>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AuthTemplate;
