import { useForm } from "react-hook-form";
import { Box, FormControl, Typography } from "@mui/material";
import { Select } from "@anytimesoftware0/anytime-ui";
import { Button } from "@anytimesoftware0/anytime-ui";
import { TextField } from "@anytimesoftware0/anytime-ui";
import styles from "./AddUserModal.module.scss";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";
import { Option } from "../../../types/Option";

interface AddUserModalProps {
  handleCloseCreateModal: () => void;
  handleAddUserSubmit: () => void;
  setUser: Dispatch<
    SetStateAction<{
      email: string;
      role: Option | Option[] | undefined;
      password: string;
    }>
  >;
  user: {
    email: string;
    role: Option | Option[] | undefined;
    password: string;
  };
  roles: any;
  onCancel: () => void;
}

interface IFormInput {
  email: string;
  password: string;
  role: string;
}

const AddUserModal: React.FC<AddUserModalProps> = ({
  handleCloseCreateModal,
  handleAddUserSubmit,
  roles,
  onCancel,
  setUser,
  user,
}) => {
  const [t] = useTranslation(["common"]);
  const { email, password, role } = user;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();

  const submit = () => {
    handleAddUserSubmit();
    handleCloseCreateModal();
  };

  return (
    <form
      noValidate
      className={styles.modalContent}
      onSubmit={handleSubmit(submit)}
    >
      <Typography variant="h3" component="h3">
        Add New User
      </Typography>
      <TextField
        required
        fullWidth
        onChange={(e) => setUser({ ...user, email: e.target.value })}
        error={!!errors.email}
        helperText={errors.email?.message}
        register={register}
        validations={{
          required: { value: true, message: "Email is required" },
          pattern: {
            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: "Invalid email",
          },
        }}
        value={email}
        label={t("email")}
        name="email"
        focused
      />
      <FormControl fullWidth>
        <Select
          placeholder="Select Role"
          onChange={(v) => {
            setUser({ ...user, role: v as Option });
          }}
          isSearchable
          value={role}
          options={roles.map((role: { name: string }) => ({
            label: role.name,
            value: role.name,
          }))}
        />
      </FormControl>
      <TextField
        required
        fullWidth
        onChange={(e) => setUser({ ...user, password: e.target.value })}
        value={password}
        error={!!errors.password}
        helperText={errors.password?.message}
        register={register}
        validations={{
          required: { value: true, message: "Password is required" },
          minLength: {
            value: 6,
            message: "Password must be at least 6 characters",
          },
        }}
        type="password"
        label={t("password")}
        name="password"
      />
      <Box className={styles.buttons}>
        <Button type="submit" color="primary" className={styles.add}>
          {t("addUser")}
        </Button>
        <Button variant="contained" color="danger" onClick={onCancel}>
          {t("cancel")}
        </Button>
      </Box>
    </form>
  );
};

export default AddUserModal;
