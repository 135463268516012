import { useForm } from "react-hook-form";
import { Box, FormControl, Typography } from "@mui/material";
import { Button, Select } from "@anytimesoftware0/anytime-ui";
import styles from "./AssignCandidateModal.module.scss";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";
import { Job } from "../../../pages/Jobs/jobs.types";
import Candidate from "../../../pages/Candidates/candidates.types";
import EmailModal from "../EmailModal";
import { Option } from "../../../types/Option";

interface AssignCandidateModalProps {
  handleCloseCandidateModal: () => void;
  handleAssignCandidateSubmit: () => void;
  setAssignedCandidates: Dispatch<
    SetStateAction<{
      candidatesIds: Option[];
      jobId: Option;
    }>
  >;
  assignedCandidates: {
    candidatesIds: Option[];
    jobId: Option;
  };
  onCancelAssign: () => void;
  jobs: Job[];
  candidates: Candidate[];
  email: {
    subject: string;
    message: string;
    language: Option;
  };
  setEmail: Dispatch<
    SetStateAction<{
      subject: string;
      message: string;
      language: Option;
    }>
  >;
}

interface IFormInput {
  candidateId: string;
  jobId: string;
}

const EditCompanyModal: React.FC<AssignCandidateModalProps> = ({
  email,
  setEmail,
  assignedCandidates,
  handleAssignCandidateSubmit,
  onCancelAssign,
  handleCloseCandidateModal,
  setAssignedCandidates,
  candidates,
  jobs,
}) => {
  const [t] = useTranslation(["common"]);

  const { handleSubmit } = useForm<IFormInput>();

  const submit = () => {
    handleAssignCandidateSubmit();
    handleCloseCandidateModal();
  };

  return (
    <form
      noValidate
      className={styles.modalContent}
      onSubmit={handleSubmit(submit)}
    >
      <Typography variant="h3" component="h3">
        Assign Candidates
      </Typography>
      <FormControl fullWidth>
        <Select
          placeholder="Select candidate"
          onChange={(v) =>
            setAssignedCandidates({
              ...assignedCandidates,
              candidatesIds: v as Option[],
            })
          }
          isSearchable
          isMulti
          options={candidates
            .filter(({ stage }) => !stage)
            .map((candidate) => ({
              label: `${candidate.firstName} ${candidate.lastName}`,
              value: String(candidate.id),
            }))}
        />
      </FormControl>
      <FormControl fullWidth>
        <Select
          placeholder="Select job"
          onChange={(v) =>
            setAssignedCandidates({ ...assignedCandidates, jobId: v as Option })
          }
          isSearchable
          options={jobs.map((c) => ({ label: c.title, value: String(c.id) }))}
        />
      </FormControl>
      <Box className={styles.buttons}>
        <EmailModal email={email} setEmail={setEmail} />
        <Button type="submit" color="primary" className={styles.add}>
          {t("assignCandidates")}
        </Button>
        <Button variant="contained" color="danger" onClick={onCancelAssign}>
          {t("cancel")}
        </Button>
      </Box>
    </form>
  );
};

export default EditCompanyModal;
