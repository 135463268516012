import {
  Button,
  ActionsBar,
  Modal,
  Table,
  Loader,
} from "@anytimesoftware0/anytime-ui";
import Company from "../../../pages/Clients/clients.types";
import Filters from "../../../pages/Candidates/Filters";
import Candidate from "../../../pages/Candidates/candidates.types";
import ColumnsEditForm from "../../../pages/Candidates/ColumnsEditModal";
import CandidateProfile from "../../../pages/Candidates/CandidateProfile";
import AddCandidateModal from "../../../pages/Candidates/AddCandidateModal";
import EditCandidateModal from "../../../pages/Candidates/EditCandidateModal";
import AssignCandidateModal from "../../../pages/Candidates/AssignCandidateModal";
import { Job } from "../../../pages/Jobs/jobs.types";
import { Grid } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { Dispatch, forwardRef, SetStateAction } from "react";
import { Option } from "../../../types/Option";

export interface CandidatesTemplateProps {
  state: {
    error: string | null;
    loading: boolean;
  };
  table: {
    columns: MRT_ColumnDef<Candidate & { stageText: string }>[];
    filteredCandidates: Candidate[];
    rowSelection: { [key: string]: boolean };
    setRowSelection: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  };
  actionsBar: {
    setSearchTerm: Dispatch<SetStateAction<string>>;
    handleDeleteCandidates: () => void;
    handleOpenCreateModal: () => void;
  };
  addCandidateModal: {
    isAddCandidateModalOpen: boolean;
    onCancel: () => void;
    candidate: {
      firstName: string;
      lastName: string;
      email: string;
      linkedin: string;
    };
    setCandidate: Dispatch<
      SetStateAction<{
        firstName: string;
        lastName: string;
        email: string;
        linkedin: string;
      }>
    >;
    customColumns: { [key: string]: { id: number; value: string } };
    setCustomColumns: Dispatch<
      SetStateAction<{ [key: string]: { id: number; value: string } }>
    >;
    ref: React.RefObject<HTMLInputElement>;
    handleAddCandidateSubmit: () => void;
    handleCloseCreateModal: () => void;
  };
  filters: {
    filteredCompanies: string[];
    setFilteredCompanies: (companies: string[]) => void;
    filteredJobs: string[];
    setFiliteredJobs: (jobs: string[]) => void;
    filteredStages: string[];
    setFiliteredStages: (stages: string[]) => void;
    companies: Company[];
  };
  columnsView: {
    ShowColumnsEditModal: boolean;
    handleCloseColumnsModal: () => void;
    handkeOpenColumnsModal: () => void;
    SelectedColumnsToShow: string[];
    handleCheckboxChange: (column: string) => void;
  };
  assginCandidateModal: {
    isAssignCandidateModalOpen: boolean;
    onAssignCancel: () => void;
    assigneCandidate: {
      companyId: Option;
      jobId: Option;
    };
    onCancelAssign: () => void;
    jobs: Job[];
    companies: Company[];
    email: {
      subject: string;
      message: string;
      language: Option;
    };
    setEmail: Dispatch<
      SetStateAction<{
        subject: string;
        message: string;
        language: Option;
      }>
    >;
    handleCloseCandidateModal: () => void;
    handleAssignCandidateSubmit: () => void;
    setAssigneCandidate: Dispatch<
      SetStateAction<{
        companyId: Option;
        jobId: Option;
      }>
    >;
  };
  editCandidateModal: {
    isEditCandidateModalOpen: boolean;
    handleCloseEditModal: () => void;
    handleEditCandidateSubmit: () => void;
    setSelectedCandidate: Dispatch<SetStateAction<Candidate | undefined>>;
    selectedCandidate: Candidate | undefined;
    customColumns: { [key: string]: { id: number; value: string } };
    setCustomColumns: Dispatch<
      SetStateAction<{ [key: string]: { id: number; value: string } }>
    >;
    ref: React.RefObject<HTMLInputElement>;
    onCancelEdit: () => void;
  };
  showProfile: {
    isShowProfileModalOpen: boolean;
    setIsShowProfileModalOpen: Dispatch<SetStateAction<boolean>>;
    selectedCandidateId: number | null;
  };
  fileUploadRef: React.RefObject<HTMLInputElement>;
  handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleClick: () => void;
}

const CandidatesTemplate = forwardRef<
  HTMLInputElement,
  CandidatesTemplateProps
>(
  (
    {
      table,
      actionsBar,
      state,
      addCandidateModal,
      filters,
      columnsView,
      assginCandidateModal,
      editCandidateModal,
      showProfile,
      fileUploadRef,
      handleFileUpload,
      handleClick,
    },
    ref
  ) => {
    const {
      isAddCandidateModalOpen,
      onCancel,
      candidate,
      setCandidate,
      customColumns,
      setCustomColumns,
      handleAddCandidateSubmit,
      handleCloseCreateModal,
    } = addCandidateModal;
    const { columns, filteredCandidates, rowSelection, setRowSelection } =
      table;
    const { setSearchTerm, handleDeleteCandidates, handleOpenCreateModal } =
      actionsBar;
    const { loading } = state;
    const {
      filteredCompanies,
      setFilteredCompanies,
      filteredJobs,
      setFiliteredJobs,
      filteredStages,
      setFiliteredStages,
    } = filters;
    const {
      ShowColumnsEditModal,
      handleCloseColumnsModal,
      SelectedColumnsToShow,
      handkeOpenColumnsModal,
      handleCheckboxChange,
    } = columnsView;
    const {
      isAssignCandidateModalOpen,
      onAssignCancel,
      assigneCandidate,
      companies,
      email,
      jobs,
      onCancelAssign,
      setEmail,
      handleAssignCandidateSubmit,
      handleCloseCandidateModal,
      setAssigneCandidate,
    } = assginCandidateModal;
    const {
      selectedCandidate,
      handleCloseEditModal,
      handleEditCandidateSubmit,
      isEditCandidateModalOpen,
      onCancelEdit,
      setSelectedCandidate,
    } = editCandidateModal;
    const {
      setIsShowProfileModalOpen,
      isShowProfileModalOpen,
      selectedCandidateId,
    } = showProfile;

    return (
      <>
        <Modal isOpen={isAddCandidateModalOpen} onCancel={onCancel}>
          <AddCandidateModal
            candidate={candidate}
            setCandidate={setCandidate}
            customColumns={customColumns}
            setCustomColumns={setCustomColumns}
            onCancel={onCancel}
            handleCloseCreateModal={handleCloseCreateModal}
            handleAddCandidateSubmit={handleAddCandidateSubmit}
            handleClick={handleClick}
          />
        </Modal>
        {ShowColumnsEditModal && (
          <Modal isOpen={true} onCancel={handleCloseColumnsModal}>
            <ColumnsEditForm
              SelectedColumnsToShow={SelectedColumnsToShow}
              handleCheckboxChange={handleCheckboxChange}
            />
          </Modal>
        )}
        {isAssignCandidateModalOpen && (
          <Modal isOpen onCancel={onAssignCancel}>
            <AssignCandidateModal
              assigneCandidate={assigneCandidate}
              companies={companies}
              email={email}
              handleAssignCandidateSubmit={handleAssignCandidateSubmit}
              handleCloseCandidateModal={handleCloseCandidateModal}
              jobs={jobs}
              onCancelAssign={onCancelAssign}
              setAssigneCandidate={setAssigneCandidate}
              setEmail={setEmail}
            />
          </Modal>
        )}
        {isEditCandidateModalOpen && (
          <Modal isOpen onCancel={onCancelEdit}>
            <EditCandidateModal
              customColumns={customColumns}
              handleCloseEditModal={handleCloseEditModal}
              handleEditCandidateSubmit={handleEditCandidateSubmit}
              onCancel={onCancelEdit}
              selectedCandidate={selectedCandidate}
              setCustomColumns={setCustomColumns}
              setSelectedCandidate={setSelectedCandidate}
            />
          </Modal>
        )}
        {isShowProfileModalOpen && selectedCandidateId && (
          <CandidateProfile
            candidateId={selectedCandidateId}
            openDrawer={isShowProfileModalOpen}
            setOpenDrawer={setIsShowProfileModalOpen}
          />
        )}
        <input
          type="file"
          accept=".csv,.xlsx,.xls"
          style={{ display: "none" }}
          onChange={handleFileUpload}
          ref={ref}
        />
        <ActionsBar
          searchHandler={setSearchTerm}
          showDelete={Object.keys(rowSelection).length > 0}
          addBtnLabel="Add Candidate"
          deleteHandler={handleDeleteCandidates}
          deleteBtnLabel={
            Object.keys(rowSelection).length > 1
              ? "Delete Candidates"
              : "Delete Candidate"
          }
          addHandler={handleOpenCreateModal}
        >
          <Button
            onClick={() => {
              handkeOpenColumnsModal();
            }}
          >
            Columns view
          </Button>
        </ActionsBar>
        {loading ? (
          <Loader />
        ) : (
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item md={9} xs={12} sx={{ mb: 3 }}>
              <Table
                onRowSelectionChange={setRowSelection}
                columns={columns}
                data={filteredCandidates}
                rowSelection={rowSelection}
                enableColumnOrdering
                enableSorting
                enableColumnActions
              />
            </Grid>
            <Grid
              item
              md
              sx={{
                display: {
                  xs: "none",
                  md: "block",
                },
              }}
            >
              <Filters
                filteredCompanies={filteredCompanies}
                setFilteredCompanies={setFilteredCompanies}
                filteredJobs={filteredJobs}
                setFiliteredJobs={setFiliteredJobs}
                filteredStages={filteredStages}
                setFiliteredStages={setFiliteredStages}
                companies={filters.companies}
              />
            </Grid>
          </Grid>
        )}
      </>
    );
  }
);

export default CandidatesTemplate;
