import { useForm } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import { TextField, Button } from "@anytimesoftware0/anytime-ui";
import styles from "./AddCandidateModal.module.scss";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";
import { UploadFile } from "@mui/icons-material";

interface AddCandidateModalProps {
  handleCloseCreateModal: () => void;
  handleAddCandidateSubmit: () => void;
  setCandidate: Dispatch<
    SetStateAction<{
      firstName: string;
      lastName: string;
      email: string;
      linkedin: string;
    }>
  >;
  candidate: {
    firstName: string;
    lastName: string;
    email: string;
    linkedin: string;
  };
  customColumns: { [key: string]: { id: number; value: string } };
  setCustomColumns: Dispatch<
    SetStateAction<{ [key: string]: { id: number; value: string } }>
  >;
  onCancel: () => void;
  handleClick: () => void;
}

interface IFormInput {
  firstName: string;
  lastName: string;
  email: string;
  linkedin: string;
}

const AddCandidateModal: React.FC<AddCandidateModalProps> = ({
  handleCloseCreateModal,
  handleAddCandidateSubmit,
  onCancel,
  setCandidate,
  candidate,
  customColumns,
  setCustomColumns,
  handleClick,
}) => {
  const [t] = useTranslation(["common"]);
  const { firstName, lastName, email, linkedin } = candidate;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();

  const submit = () => {
    handleAddCandidateSubmit();
    handleCloseCreateModal();
  };

  return (
    <form
      noValidate
      className={styles.modalContent}
      onSubmit={handleSubmit(submit)}
    >
      <Typography variant="h3" component="h3">
        Add New Candidate
      </Typography>
      <TextField
        required
        fullWidth
        onChange={(e) =>
          setCandidate({ ...candidate, firstName: e.target.value })
        }
        value={firstName}
        error={!!errors.firstName}
        helperText={errors.firstName?.message}
        register={register}
        validations={{
          required: { value: true, message: "Name is required" },
        }}
        label={t("firstName")}
        name="firstName"
        focused
      />
      <TextField
        required
        fullWidth
        onChange={(e) =>
          setCandidate({ ...candidate, lastName: e.target.value })
        }
        value={lastName}
        error={!!errors.lastName}
        helperText={errors.lastName?.message}
        register={register}
        validations={{
          required: { value: true, message: "Last name is required" },
        }}
        label={t("lastName")}
        name="lastName"
      />
      <TextField
        required
        fullWidth
        onChange={(e) => setCandidate({ ...candidate, email: e.target.value })}
        error={!!errors.email}
        helperText={errors.email?.message}
        register={register}
        validations={{
          required: { value: true, message: "Email is required" },
          pattern: {
            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: "Invalid email",
          },
        }}
        value={email}
        label={t("email")}
        name="email"
      />
      <TextField
        fullWidth
        onChange={(e) =>
          setCandidate({ ...candidate, linkedin: e.target.value })
        }
        error={!!errors.linkedin}
        helperText={errors.linkedin?.message}
        register={register}
        value={linkedin}
        label={t("linkedin")}
        name="linkedin"
      />
      {Object.entries(customColumns)
        .sort((e1, e2) => e1[1].id - e2[1].id)
        .map((entry) => entry[0])
        .map((columnKey) => (
          <Box
            key={customColumns[columnKey].id}
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <TextField
              label="Column Name"
              value={columnKey}
              onChange={(e) => {
                const newCustomColumns = { ...customColumns };
                newCustomColumns[e.target.value] = newCustomColumns[columnKey];
                delete newCustomColumns[columnKey];
                setCustomColumns(newCustomColumns);
              }}
            />
            <TextField
              label="Column Value"
              value={customColumns[columnKey].value}
              onChange={(e) => {
                const newCustomColumns = { ...customColumns };
                newCustomColumns[columnKey] = {
                  id: newCustomColumns[columnKey].id,
                  value: e.target.value,
                };
                setCustomColumns(newCustomColumns);
              }}
            />
          </Box>
        ))}
      <Box className={styles.buttons}>
        <Button
          startIcon={<UploadFile />}
          color="primary"
          variant="contained"
          onClick={() => handleClick}
        >
          {t("uploadCSV")}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={(e) => {
            if (Object.keys(customColumns).every((key) => key !== "")) {
              const newCustomColumns = customColumns;
              newCustomColumns[""] = {
                id: Object.keys(customColumns).length,
                value: "",
              };
              setCustomColumns({ ...newCustomColumns });
            }
          }}
        >
          Add Column
        </Button>
        <Button type="submit" color="primary" className={styles.add}>
          {t("addCandidate")}
        </Button>
        <Button variant="contained" color="danger" onClick={onCancel}>
          {t("cancel")}
        </Button>
      </Box>
    </form>
  );
};

export default AddCandidateModal;
