import { Dispatch, SetStateAction, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Button, TextField, Modal, Select } from "@anytimesoftware0/anytime-ui";
import "../../../../styles/quill-snow.css";
import { useTranslation } from "react-i18next";
import styles from "./EmailModal.module.scss";
import { Option } from "../../../../types/Option";

const ReactQuill =
  typeof window === "object" ? require("react-quill") : () => false;

const quillModules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, false] }, { font: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ color: [] }, { background: [] }, "link", "image"],
    [{ align: [] }, { direction: "rtl" }, { indent: "-1" }, { indent: "+1" }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["clean"],
  ],
};

const quilFormats = [
  "header",
  "font",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "code-block",
  "link",
  "image",
  "align",
  "indent",
  "list",
  "bullet",
  "color",
  "background",
  "direction",
];

interface EmailModalProps {
  email: {
    subject: string;
    message: string;
    language: Option;
  };
  setEmail: Dispatch<
    SetStateAction<{
      subject: string;
      message: string;
      language: Option;
    }>
  >;
}

const EmailModal = ({ email, setEmail }: EmailModalProps) => {
  const [t] = useTranslation(["common"]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleOpen}>{t("writeEmail")}</Button>
      <Modal
        isOpen={open}
        onCancel={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box component="form" className={styles.modalContent} noValidate>
          <Typography variant="h4" textAlign="center" sx={{ mb: 1 }}>
            {t("writeEmail")}
          </Typography>
          <TextField
            required
            fullWidth
            label={t("subject")}
            name="subject"
            value={email.subject}
            onChange={(e) => setEmail({ ...email, subject: e.target.value })}
            focused
          />
          <Select
            placeholder="Select language"
            options={[
              { label: "EN", value: "EN" },
              { label: "CZ", value: "CZ" },
            ]}
            value={email.language}
            onChange={(v) => setEmail({ ...email, language: v as Option })}
          />
          <ReactQuill
            value={email.message}
            onChange={(value) => setEmail({ ...email, message: value })}
            modules={quillModules}
            formats={quilFormats}
          />
          <Box className={styles.buttons}>
            <Button
              type="submit"
              color="success"
              variant="contained"
              onClick={() => {
                handleClose();
              }}
            >
              {t("save")}
            </Button>
            <Button
              variant="contained"
              color="danger"
              onClick={() => {
                setEmail({
                  subject: "",
                  message: "",
                  language: { label: "EN", value: "EN" },
                });
                handleClose();
              }}
            >
              {t("cancel")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default EmailModal;
