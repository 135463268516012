import axios from "../../utils/axios";
import config from "../../utils/config";
import Role, { CreateRoleDto } from "./roles.types";

const baseUrl = `${config.backendUrl}/roles`;

const create = async (createRoleDto: CreateRoleDto): Promise<Role> => {
  const { data } = await axios.post<Role>(baseUrl, createRoleDto);
  return data;
};

const getAll = async (): Promise<Role[]> => {
  const { data } = await axios.get<Role[]>(baseUrl);
  return data;
};

const update = async (name: string, update: CreateRoleDto): Promise<Role> => {
  const { data } = await axios.put<Role>(`${baseUrl}/${name}`, update);
  return data;
};

const remove = async (name: string): Promise<Role> => {
  const { data } = await axios.delete<Role>(`${baseUrl}/${name}`);
  return data;
};

const roleService = { create, update, remove, getAll };
export default roleService;
