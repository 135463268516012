import { useForm } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import { Button } from "@anytimesoftware0/anytime-ui";
import { TextField } from "@anytimesoftware0/anytime-ui";
import styles from "./AddJobModal.module.scss";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";

interface AddJobMoadlProps {
  handleCloseCreateModal: () => void;
  handleAddJobSubmit: () => void;
  setJob: Dispatch<
    SetStateAction<{
      title: string;
      description: string;
    }>
  >;
  job: {
    title: string;
    description: string;
  };
  onCancel: () => void;
}

interface IFormInput {
  title: string;
  description: string;
}

const AddJobModal: React.FC<AddJobMoadlProps> = ({
  handleCloseCreateModal,
  handleAddJobSubmit,
  onCancel,
  job,
  setJob,
}) => {
  const [t] = useTranslation(["common"]);
  const { description, title } = job;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();

  const submit = () => {
    handleAddJobSubmit();
    handleCloseCreateModal();
  };

  return (
    <form
      noValidate
      className={styles.modalContent}
      onSubmit={handleSubmit(submit)}
    >
      <Typography variant="h3" component="h3">
        Add New Job
      </Typography>
      <TextField
        required
        fullWidth
        onChange={(e) => setJob({ ...job, title: e.target.value })}
        value={title}
        error={!!errors.title}
        helperText={errors.title?.message}
        register={register}
        validations={{
          required: { value: true, message: "title is required" },
        }}
        label={t("jobTitle")}
        name="title"
        focused
      />
      <TextField
        required
        fullWidth
        onChange={(e) => setJob({ ...job, description: e.target.value })}
        error={!!errors.description}
        helperText={errors.description?.message}
        register={register}
        validations={{
          required: { value: true, message: "Description is required" },
        }}
        value={description}
        label={t("jobDescription")}
        name="description"
      />
      <Box className={styles.buttons}>
        <Button type="submit" color="primary" className={styles.add}>
          {t("addNewJob")}
        </Button>
        <Button variant="contained" color="danger" onClick={onCancel}>
          {t("cancel")}
        </Button>
      </Box>
    </form>
  );
};

export default AddJobModal;
