import React from "react";
import { Job } from "../../../pages/Jobs/jobs.types";
import Company from "../../../pages/Clients/clients.types";
import styles from "./CompanyCompnentClient.module.scss";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../utils/store";
import { useSelector } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { t } from "i18next";
import { Box, Typography } from "@mui/material";
import { Button } from "@anytimesoftware0/anytime-ui";

interface CompanyProps {
  company: Company;
  handleAssignOpen: (id: number) => void;
  handleEditOpen: (id: number) => void;
  handleDeleteOpen: (id: number) => void;
}

interface JobComponentProps {
  job: Job;
  handleAssignOpen: (id: number) => void;
  handleEditOpen: (id: number) => void;
  handleDeleteOpen: (id: number) => void;
}

const JobComponent: React.FC<JobComponentProps> = ({
  job,
  handleAssignOpen,
  handleDeleteOpen,
  handleEditOpen,
}) => {
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => ({
    user: state.user,
  }));

  return (
    <Box className={styles.jobCard}>
      <Box className={styles.title}>
        <Typography noWrap variant="h5">
          {job.title}
        </Typography>
        <Box className={styles.actions}>
          <Button
            sx={{ height: "fit-content" }}
            size="small"
            color="primary"
            onClick={() => navigate(`/jobs/${job.id}`)}
            variant="contained"
          >
            {t("open")}
          </Button>
          {((user as any)?.isClientOwner || user?.role === "admin") && (
            <Button
              sx={{ height: "fit-content" }}
              size="small"
              color="primary"
              onClick={() => handleAssignOpen(job.id)}
              variant="contained"
            >
              {t("assignToClient")}
            </Button>
          )}
          <Button
            color="primary"
            sx={{ height: "fit-content" }}
            size="small"
            onClick={() => handleEditOpen(job.id)}
            variant="contained"
          >
            {t("edit")}
          </Button>
          <Button
            color="danger"
            onClick={() => handleDeleteOpen(job.id)}
            startIcon={<DeleteForeverIcon />}
            variant="contained"
          >
            {t("deleteSelectedJobs")}
          </Button>
        </Box>
      </Box>
      <Box className={styles.jobStats}>
        {job.stages
          .slice()
          .sort((a, b) => a.order - b.order)
          .map((stage) => (
            <Box className={styles.stage} key={stage.order}>
              <Typography variant="h6">
                {stage.title.toLocaleUpperCase()}
              </Typography>
              <Typography variant="h5">{stage.candidates.length}</Typography>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

const CompanyComponent: React.FC<CompanyProps> = ({
  company,
  handleAssignOpen,
  handleDeleteOpen,
  handleEditOpen,
}) => {
  return (
    <Box className={styles.company}>
      <Typography variant="h3">{company.name}</Typography>
      <Box className={styles.jobsWrapper}>
        {company.jobs.map((job) => (
          <JobComponent
            key={job.id}
            job={job}
            handleAssignOpen={handleAssignOpen}
            handleEditOpen={handleEditOpen}
            handleDeleteOpen={handleDeleteOpen}
          />
        ))}
      </Box>
    </Box>
  );
};

export default CompanyComponent;
