import jobsService, { CreateJobDto } from "../../../pages/Jobs/jobs.api";
import { JobDto } from "../../../pages/Jobs/jobs.types";
import { AppDispatch } from "../../../utils/store";
import { AxiosError } from "axios";
import { initializeCompanies } from "../../../pages/Clients/clients.slice";
import toast from "react-hot-toast";

export const addJob = (job: CreateJobDto) => {
  return async (dispatch: AppDispatch) => {
    try {
      await jobsService.create(job).then(() => {
        toast.success("A new job was added successfully");
        dispatch(initializeCompanies());
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error("Failed to add the new job");
      }
    }
  };
};

export const updateJob = (job: JobDto, id: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      await jobsService.update(id, job).then(() => {
        toast.success("The job was updated successfully");
        dispatch(initializeCompanies());
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error("Failed to update the job");
      }
    }
  };
};

export const deleteJobs = (ids: number[], isCompany = true) => {
  return async (dispatch: AppDispatch) => {
    try {
      for (const id of ids) {
        await jobsService.remove(id);
      }
      dispatch(initializeCompanies());
      toast.success("Jobs are removed successfully");
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        toast.error(`Failed to remove jobs`);
      }
    }
  };
};

export const assignJobToClient = (email: string, jobId: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      await jobsService.assignJobToClient({ emails: email, jobId }).then(() => {
        toast.success("The job was assigned to the client successfully");
        dispatch(initializeCompanies(true));
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error("Failed to assign the job to the client");
      }
    }
  };
};
