import { Table, Modal, ActionsBar, Loader } from "@anytimesoftware0/anytime-ui";
import Role from "../../../pages/Roles/roles.types";
import AddUserModal from "../../../pages/Users/AddUsersModal";
import { customUser } from "../../../pages/Users/users.types";
import { Grid } from "@mui/material";
import { MRT_ColumnDef, MRT_RowData } from "material-react-table";
import { Dispatch, SetStateAction } from "react";
import { Option } from "../../../types/Option";

export interface UsersTemplateProps {
  state: {
    error: string | null;
    loading: boolean;
  };
  table: {
    columns: MRT_ColumnDef<MRT_RowData>[];
    filteredCustomUsers: customUser[];
    rowSelection: { [key: string]: boolean };
    setRowSelection: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  };
  addUserModal: {
    isAddUserModalOpen: boolean;
    handleCloseCreateModal: () => void;
    roles: Role[];
    user: {
      email: string;
      role: Option | Option[] | undefined;
      password: string;
    };
    setUser: Dispatch<
      SetStateAction<{
        email: string;
        role: Option | Option[] | undefined;
        password: string;
      }>
    >;
    handleAddUserSubmit: () => void;
    onCancel: () => void;
  };
  actionsBar: {
    setSearchTerm: Dispatch<SetStateAction<string>>;
    handleDeleteCustomUser: () => void;
    handleOpenCreateModal: () => void;
  };
}

const UsersTemplate: React.FC<UsersTemplateProps> = ({
  addUserModal,
  table,
  actionsBar,
  state,
}) => {
  const {
    isAddUserModalOpen,
    handleCloseCreateModal,
    roles,
    user,
    setUser,
    handleAddUserSubmit,
    onCancel,
  } = addUserModal;
  const { columns, filteredCustomUsers, rowSelection, setRowSelection } = table;
  const { setSearchTerm, handleDeleteCustomUser, handleOpenCreateModal } =
    actionsBar;
  const { loading } = state;

  return (
    <>
      <Modal isOpen={isAddUserModalOpen} onCancel={onCancel}>
        <AddUserModal
          user={user}
          setUser={setUser}
          roles={roles}
          onCancel={onCancel}
          handleCloseCreateModal={handleCloseCreateModal}
          handleAddUserSubmit={handleAddUserSubmit}
        />
      </Modal>
      <ActionsBar
        searchHandler={setSearchTerm}
        showDelete={Object.keys(rowSelection).length > 0}
        addBtnLabel="Add User"
        deleteHandler={handleDeleteCustomUser}
        deleteBtnLabel="Delete Users"
        addHandler={handleOpenCreateModal}
      />
      {loading ? (
        <Loader />
      ) : (
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Table
              columns={columns}
              data={filteredCustomUsers}
              rowSelection={rowSelection}
              enableColumnOrdering
              enableSorting
              enableColumnActions
              onRowSelectionChange={setRowSelection}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UsersTemplate;
