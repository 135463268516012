import { useForm } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import { Button, Checkbox } from "@anytimesoftware0/anytime-ui";
import styles from "./EditRoleModal.module.scss";
import { useTranslation } from "react-i18next";
import { Permission } from "../roles.types";

interface EditRoleModalProps {
  handleCloseEditModal: () => void;
  handleEditRoleSubmit: () => void;
  selectedRole: {
    name: string;
  };
  onCancel: () => void;
  permissions: Permission[];
  selectedPermissions: Permission[];
  handlePermissionToggle: (permission: Permission) => void;
}

const EditRoleModal: React.FC<EditRoleModalProps> = ({
  handlePermissionToggle,
  permissions,
  selectedPermissions,
  handleCloseEditModal,
  handleEditRoleSubmit,
  onCancel,
  selectedRole,
}) => {
  const [t] = useTranslation(["common"]);

  const { handleSubmit } = useForm();

  const submit = () => {
    handleEditRoleSubmit();
    handleCloseEditModal();
  };

  return (
    <form
      noValidate
      className={styles.modalContent}
      onSubmit={handleSubmit(submit)}
    >
      <Typography variant="h3" component="h3">
        Edit Role
      </Typography>
      {permissions.map((permission) => (
        <Checkbox
          key={permission.name}
          defaultChecked={false}
          label={permission.name}
          isChecked={selectedPermissions.some(
            (p) => p.id === permission.id && p.name === permission.name
          )}
          onChange={() => handlePermissionToggle(permission)}
        />
      ))}
      <Box className={styles.buttons}>
        <Button type="submit" color="primary" className={styles.add}>
          {t("edit")}
        </Button>
        <Button variant="contained" color="danger" onClick={onCancel}>
          {t("cancel")}
        </Button>
      </Box>
    </form>
  );
};

export default EditRoleModal;
