import Role, { Permission } from "../../../pages/Roles/roles.types";
import { Grid } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { Dispatch, SetStateAction } from "react";
import AddRoleModal from "../../../pages/Roles/AddRoleModal";
import EditRoleModal from "../../../pages/Roles/EditRoleModal";
import { Loader, Modal, Table, ActionsBar } from "@anytimesoftware0/anytime-ui";

export interface RolesTemplateProps {
  state: {
    error: string | null;
    loading: boolean;
  };
  table: {
    columns: MRT_ColumnDef<Role>[];
    filteredRoles: Role[];
    rowSelection: { [key: string]: boolean };
    setRowSelection: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  };
  addRoleModal: {
    isAddRoleModalOpen: boolean;
    handleCloseCreateModal: () => void;
    handleAddRoleSubmit: () => void;
    setRole: Dispatch<
      SetStateAction<{
        name: string;
      }>
    >;
    role: {
      name: string;
    };
    onCancel: () => void;
    permissions: Permission[];
    selectedPermissions: Permission[];
    handlePermissionToggle: (permission: Permission) => void;
  };
  editRoleModal: {
    isEditRoleModalOpen: boolean;
    handleCloseEditModal: () => void;
    handleEditRoleSubmit: () => void;
    setSelectedRole: Dispatch<
      SetStateAction<{
        name: string;
      }>
    >;
    selectedRole: {
      name: string;
    };
    onCancelEdit: () => void;
  };
  actionsBar: {
    setSearchTerm: Dispatch<SetStateAction<string>>;
    handleDeleteRoles: () => void;
    handleOpenCreateModal: () => void;
  };
}

const RolesTemplate: React.FC<RolesTemplateProps> = ({
  table,
  actionsBar,
  state,
  addRoleModal,
  editRoleModal,
}) => {
  const { columns, filteredRoles, rowSelection, setRowSelection } = table;
  const { setSearchTerm, handleDeleteRoles, handleOpenCreateModal } =
    actionsBar;
  const { loading } = state;
  const {
    isAddRoleModalOpen,
    handleCloseCreateModal,
    handleAddRoleSubmit,
    setRole,
    role,
    onCancel,
    handlePermissionToggle,
    permissions,
    selectedPermissions,
  } = addRoleModal;
  const {
    isEditRoleModalOpen,
    handleCloseEditModal,
    handleEditRoleSubmit,
    onCancelEdit,
  } = editRoleModal;

  return (
    <>
      <Modal isOpen={isAddRoleModalOpen} onCancel={onCancel}>
        <AddRoleModal
          handleCloseCreateModal={handleCloseCreateModal}
          handleAddRoleSubmit={handleAddRoleSubmit}
          setRole={setRole}
          role={role}
          onCancel={onCancel}
          handlePermissionToggle={handlePermissionToggle}
          permissions={permissions}
          selectedPermissions={selectedPermissions}
        />
      </Modal>
      {isEditRoleModalOpen && (
        <Modal isOpen={true} onCancel={onCancelEdit}>
          <EditRoleModal
            handleCloseEditModal={handleCloseEditModal}
            handleEditRoleSubmit={handleEditRoleSubmit}
            selectedRole={role}
            onCancel={onCancelEdit}
            permissions={permissions}
            selectedPermissions={selectedPermissions}
            handlePermissionToggle={handlePermissionToggle}
          />
        </Modal>
      )}
      <ActionsBar
        searchHandler={setSearchTerm}
        showDelete={Object.keys(rowSelection).length > 0}
        addBtnLabel="Add Role"
        deleteHandler={handleDeleteRoles}
        deleteBtnLabel={
          Object.keys(rowSelection).length > 1 ? "Delete Roles" : "Delete Role"
        }
        addHandler={handleOpenCreateModal}
      />
      {loading ? (
        <Loader />
      ) : (
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Table
              onRowSelectionChange={setRowSelection}
              columns={columns}
              data={filteredRoles}
              rowSelection={rowSelection}
              enableColumnOrdering
              enableSorting
              enableColumnActions
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default RolesTemplate;
