import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../pages/Auth/auth.slice";
import candidatesReducer from "../pages/Candidates/candidates.slice";
import companiesReducer from "../pages/Clients/clients.slice";
import recruitersReduce from "../pages/Recruiters/recruiters.slice";
import customUserReduce from "../pages/Users/users.slice";
import jobReduce from "../pages/Jobs/jobs.slice";
import rolesReduce from "../pages/Roles/roles.slice";
import permissionsReduce from "../reducers/permissions";
import routesReduce from "../reducers/routes";

const store = configureStore({
  reducer: {
    user: userReducer,
    candidates: candidatesReducer,
    companies: companiesReducer,
    jobs: jobReduce,
    recruiters: recruitersReduce,
    customUsers: customUserReduce,
    roles: rolesReduce,
    permissions: permissionsReduce,
    routes: routesReduce,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
