import {
  Routes,
  Route,
  useNavigate,
  Link,
  useLocation,
} from "react-router-dom";
import routes from "./pages/routes";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addUser } from "./pages/Auth/auth.slice";
import { UserWithToken } from "./pages/Auth/auth.types";
import { setToken } from "./utils/axios";
import { AppDispatch, RootState } from "./utils/store";
import "./styles/globals.scss";
import Cookies from "js-cookie";
import "@anytimesoftware0/anytime-ui/dist/esm/dist/styles.css";
import { Layout } from "@anytimesoftware0/anytime-ui";
import {
  ProfileIcon,
  MailOutlineIcon,
  MediationIcon,
  SettingsIcon,
  SearchIcon,
} from "./svg/icons";
import { GrCube } from "react-icons/gr";
import { Toaster } from "react-hot-toast";

const commonLinks = [
  { id: 4, src: GrCube, label: "candidates", href: "/candidates" },
  { id: 3, src: MediationIcon, label: "clients", href: "/clients" },
];

const adminLinks = [
  { id: 0, src: ProfileIcon, label: "users", href: "/users" },
  { id: 1, src: SearchIcon, href: "/recruiters", label: "recruiters" },
  { id: 5, src: SettingsIcon, label: "Roles", href: "/roles" },
  ...commonLinks,
];

const clientLinks = [
  { id: 2, src: MailOutlineIcon, label: "jobs", href: "/jobs" },
  ...commonLinks,
];

function App() {
  const user = useSelector((state: RootState) => state.user);
  const dispatch: AppDispatch = useDispatch();

  const navigate = useNavigate();
  const pathname = useLocation().pathname;

  useEffect(() => {
    const auth = Cookies.get("auth");
    if (auth) {
      const userWithToken: UserWithToken = JSON.parse(auth);
      dispatch(addUser(userWithToken.user));
      setToken(userWithToken.accessToken);
    } else {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [navigate, user]);

  const sidebarRouts =
    user?.role === "admin"
      ? adminLinks
      : user?.role === "client"
      ? clientLinks
      : commonLinks;

  const navRoutes = [
    { title: "Users", path: "/users" },
    { title: "Recruiters", path: "/recruiters" },
    { title: "Jobs", path: "/jobs" },
    { title: "Clients", path: "/clients" },
    { title: "Candidates", path: "/candidates" },
    { title: "Roles", path: "/roles" },
  ];

  return (
    <>
      <Routes>
        {routes.map(({ url, component: Component }) => (
          <Route
            key={url}
            path={url}
            element={
              url === "/login" ? (
                <Component />
              ) : (
                <Layout
                  accessToken={user?.email as string}
                  navRoutes={navRoutes}
                  routes={sidebarRouts}
                  navigate={navigate}
                  pathname={pathname}
                  linkComponent={Link}
                >
                  <Component />
                </Layout>
              )
            }
          />
        ))}
      </Routes>
      <Toaster
        position="top-right"
        toastOptions={{
          style: {
            border: "1px solid white",
            color: "white",
            fontSize: "16px",
            padding: "10px 25px",
            backgroundColor: "#111525",
          },
        }}
      />
    </>
  );
}

export default App;
