import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../../utils/store";
import { AxiosError } from "axios";
import jobsService, { CreateJobDto } from "./jobs.api";
import { Job, JobDto } from "./jobs.types";
import { initializeCompanies } from "../../pages/Clients/clients.slice";
import toast from "react-hot-toast";

interface JobState {
  loading: boolean;
  error: string | null;
  jobs: Job[];
}

const initialState: JobState = {
  loading: false,
  error: null,
  jobs: [],
};

const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    setJobs(state, { payload: jobs }: PayloadAction<Job[]>) {
      state.jobs = jobs;
    },
    addJobs(state, { payload: jobs }: PayloadAction<Job[]>) {
      state.jobs = state.jobs.concat(jobs);
    },
    replaceOne(state, { payload: updatedJob }: PayloadAction<Job>) {
      state.jobs = state.jobs.map((job) =>
        job.id === updatedJob.id ? updatedJob : job
      );
    },
    removeJob(state, { payload: id }: PayloadAction<number>) {
      state.jobs = state.jobs.filter((job) => job.id !== id);
    },
    clearJobs(state) {
      state.jobs = [];
    },
  },
});

export const { replaceOne, addJobs, clearJobs, removeJob, setJobs } =
  jobsSlice.actions;

export default jobsSlice.reducer;

export const addJob = (job: CreateJobDto) => {
  return async (dispatch: AppDispatch) => {
    try {
      await jobsService.create(job).then(() => {
        toast.success("A new job was added successfully");
        dispatch(initializeCompanies(true));
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error("Failed to add the new job");
      }
    }
  };
};

export const updateJob = (job: JobDto, id: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      await jobsService.update(id, job).then(() => {
        toast.success("The job was updated successfully");
        dispatch(initializeCompanies(true));
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error("Failed to update the job");
      }
    }
  };
};

export const deleteJobs = (ids: number[], isCompany = true) => {
  return async (dispatch: AppDispatch) => {
    try {
      for (const id of ids) {
        await jobsService.remove(id);
      }
      dispatch(initializeCompanies(isCompany));
      toast.success("Jobs are removed successfully");
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        toast.error(`Failed to remove jobs`);
      }
    }
  };
};

export const assignJobToClient = (email: string, jobId: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      await jobsService.assignJobToClient({ emails: email, jobId }).then(() => {
        toast.success("The job was assigned to the client successfully");
        dispatch(initializeCompanies(true));
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error("Failed to assign the job to the client");
      }
    }
  };
};
