import { useForm } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import { Button } from "@anytimesoftware0/anytime-ui";
import { TextField } from "@anytimesoftware0/anytime-ui";
import styles from "./EditJobModal.module.scss";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";

interface EditJobModalProps {
  handleCloseEditModal: () => void;
  handleEditJobSubmit: () => void;
  setEditedJob: Dispatch<
    SetStateAction<{
      title: string;
      description: string;
    }>
  >;
  editedJob: {
    title: string;
    description: string;
  };
  onCancelEdit: () => void;
}

interface IFormInput {
  title: string;
  description: string;
}

const EditJobModal: React.FC<EditJobModalProps> = ({
  handleCloseEditModal,
  handleEditJobSubmit,
  onCancelEdit,
  setEditedJob,
  editedJob,
}) => {
  const [t] = useTranslation(["common"]);
  const { title, description } = editedJob;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();

  const submit = () => {
    handleEditJobSubmit();
    handleCloseEditModal();
  };

  return (
    <form
      noValidate
      className={styles.modalContent}
      onSubmit={handleSubmit(submit)}
    >
      <Typography variant="h3" component="h3">
        Edit Job
      </Typography>
      <TextField
        required
        fullWidth
        onChange={(e) => setEditedJob({ ...editedJob, title: e.target.value })}
        value={title}
        error={!!errors.title}
        helperText={errors.title?.message}
        register={register}
        validations={{
          required: { value: true, message: "title is required" },
        }}
        label={t("jobTitle")}
        name="title"
        focused
      />
      <TextField
        required
        fullWidth
        onChange={(e) =>
          setEditedJob({ ...editedJob, description: e.target.value })
        }
        error={!!errors.description}
        helperText={errors.description?.message}
        register={register}
        validations={{
          required: { value: true, message: "Description is required" },
        }}
        value={description}
        label={t("jobDescription")}
        name="description"
      />
      <Box className={styles.buttons}>
        <Button type="submit" color="primary" className={styles.add}>
          {t("edit")}
        </Button>
        <Button variant="contained" color="danger" onClick={onCancelEdit}>
          {t("cancel")}
        </Button>
      </Box>
    </form>
  );
};

export default EditJobModal;
