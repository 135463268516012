import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../../utils/store";
import { AxiosError } from "axios";
import companiesService, {
  CreateCompanyDto,
  UpdateCompanyDto,
} from "./clients.api";
import Company from "./clients.types";
import { initializeCandidates } from "../../pages/Candidates/candidates.slice";
import { setJobs } from "../Jobs/jobs.slice";
import toast from "react-hot-toast";

export interface CompanyState {
  loading: boolean;
  error: string | null;
  companies: Company[];
}

const initialState: CompanyState = {
  loading: false,
  error: null,
  companies: [],
};

const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    companiesStart(state) {
      state.loading = true;
      state.error = null;
      state.companies = [];
    },
    companiesSuccess(state, { payload: companies }: PayloadAction<Company[]>) {
      state.loading = false;
      state.error = null;
      state.companies = companies;
    },
    companiesFailure(state, { payload: error }: PayloadAction<string>) {
      state.loading = false;
      state.error = error;
      state.companies = [];
    },
    setCompanies(state, { payload: companies }: PayloadAction<Company[]>) {
      state.companies = companies;
    },
    addCompanies(state, { payload: companies }: PayloadAction<Company[]>) {
      state.companies = state.companies.concat(companies);
    },
    replaceOne(state, { payload: updatedCompany }: PayloadAction<Company>) {
      state.companies = state.companies.map((company) =>
        company.id === updatedCompany.id ? updatedCompany : company
      );
    },
    removeCompany(state, { payload: id }: PayloadAction<number>) {
      state.companies = state.companies.filter((company) => company.id !== id);
    },
    clearCompanies(state) {
      state.companies = [];
    },
  },
});

export const {
  companiesStart,
  companiesSuccess,
  companiesFailure,
  setCompanies,
  addCompanies,
  replaceOne,
  removeCompany,
  clearCompanies,
} = companiesSlice.actions;

export const initializeCompanies = (me = false) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(companiesStart());
      const companies = me
        ? await companiesService.me()
        : await companiesService.getAll();
      dispatch(companiesSuccess(companies));
      if (me) {
        const jobs =
          companies[0]?.jobs?.map((job) => ({
            ...job,
            candidates: job.stages.map((stage) => stage.candidates).flat()
              ?.length,
          })) || [];
        dispatch(setJobs(jobs));
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        toast.error(`Failed to fetch companies`);
      }
    }
  };
};

export const createCompany = (createCandidateDto: CreateCompanyDto) => {
  return async (dispatch: AppDispatch) => {
    try {
      const company = await companiesService.create(createCandidateDto);
      toast.success("New company was added successfully");
      dispatch(addCompanies([company]));
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        toast.error(`Failed to add company. ${error.response?.data.message}`);
      }
    }
  };
};

export const updateCompany = (
  id: number,
  updateCompanyDto: UpdateCompanyDto
) => {
  return async (dispatch: AppDispatch) => {
    try {
      await companiesService.updateOne(id, updateCompanyDto);
      toast.success("Company was updated successfully");
      dispatch(initializeCompanies());
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        toast.error(
          `Failed to update company. ${error.response?.data.message}`
        );
      }
    }
  };
};

export const deleteManyCompanies = (ids: number[]) => {
  return async (dispatch: AppDispatch) => {
    try {
      for (const id of ids) {
        await companiesService.deleteOne(id);
        dispatch(removeCompany(id));
      }
      dispatch(initializeCandidates());
      toast.success("Companies are removed successfully");
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        toast.error(`Failed to remove companies`);
      }
    }
  };
};

export default companiesSlice.reducer;
