import { useForm } from "react-hook-form";
import { Box, FormControl, Typography } from "@mui/material";
import { Button, Select } from "@anytimesoftware0/anytime-ui";
import styles from "./AssignCandidateModal.module.scss";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";
import Candidate from "../../../../pages/Candidates/candidates.types";
import EmailModal from "../EmailModal";
import { Option } from "../../../../types/Option";

interface AssignCandidateModalProps {
  handleCloseCandidateModal: () => void;
  handleAssignCandidateSubmit: () => void;
  setAssignedCandidates: Dispatch<SetStateAction<Option[]>>;
  assignedCandidates: Option[];
  onCancelAssign: () => void;
  candidates: Candidate[];
  email: {
    subject: string;
    message: string;
    language: Option;
  };
  setEmail: Dispatch<
    SetStateAction<{
      subject: string;
      message: string;
      language: Option;
    }>
  >;
}

interface IFormInput {
  label: string;
  value: number;
}

const AssignCandidatesModal: React.FC<AssignCandidateModalProps> = ({
  email,
  setEmail,
  assignedCandidates,
  handleAssignCandidateSubmit,
  onCancelAssign,
  handleCloseCandidateModal,
  setAssignedCandidates,
  candidates,
}) => {
  const [t] = useTranslation(["common"]);

  const { handleSubmit } = useForm<IFormInput>();

  const submit = () => {
    handleAssignCandidateSubmit();
    handleCloseCandidateModal();
  };

  return (
    <form
      noValidate
      className={styles.modalContent}
      onSubmit={handleSubmit(submit)}
    >
      <Typography variant="h3" component="h3">
        Assign Candidates
      </Typography>
      <FormControl fullWidth>
        <Select
          placeholder="Select candidate"
          onChange={(v) => setAssignedCandidates(v as Option[])}
          isSearchable
          isMulti
          options={candidates
            .filter(({ stage }) => !stage)
            .map((candidate) => ({
              label: `${candidate.firstName} ${candidate.lastName}`,
              value: String(candidate.id),
            }))}
        />
      </FormControl>
      <Box className={styles.buttons}>
        <EmailModal email={email} setEmail={setEmail} />
        <Button type="submit" color="primary" className={styles.add}>
          {t("assignCandidates")}
        </Button>
        <Button variant="contained" color="danger" onClick={onCancelAssign}>
          {t("cancel")}
        </Button>
      </Box>
    </form>
  );
};

export default AssignCandidatesModal;
