import Home from "./Home";
import Candidates from "./Candidates/index.page";
import Clients from "./Clients/index.page";
import Login from "./Auth/index.page";
import Jobs from "./Jobs/index.page";
import Stages from "./Stages/index.page";
import Client from "./Clients/[id]/index";
import Recruiters from "./Recruiters/index.page";
import Users from "./Users/index.page";
import Roles from "./Roles";

const commonRoutes = [
  {
    component: Home,
    url: "/",
  },
  {
    component: Candidates,
    url: "/candidates",
  },
  {
    component: Clients,
    url: "/clients",
  },
  {
    component: Client,
    url: "/clients/:id",
  },
];

const adminRoutes = [
  {
    component: Login,
    url: "/login",
  },
  {
    component: Roles,
    url: "/roles",
  },
  {
    component: Recruiters,
    url: "/recruiters",
  },
  {
    component: Users,
    url: "/users",
  },
];

const clientRoutes = [
  {
    component: Jobs,
    url: "/jobs",
  },
  {
    component: Stages,
    url: "/jobs/:id",
  },
];

const routes = [...commonRoutes, ...adminRoutes, ...clientRoutes];

export default routes;
