import { Box, Grid } from "@mui/material";
import styles from "./StagesTemplate.module.scss";
import {
  Button,
  Modal,
  ActionsBar,
  Table,
  StagesSwitcher,
} from "@anytimesoftware0/anytime-ui";
import { useTranslation } from "react-i18next";
import { Stage, StageWithCandidates } from "../../../pages/Stages/stages.types";
import { MRT_ColumnDef } from "material-react-table";
import { Dispatch, SetStateAction } from "react";
import Candidate from "../../../pages/Candidates/candidates.types";
import StagingModal from "../../../pages/Stages/StagingModal";
import AddStageModal from "../../../pages/Stages/AddStageModal";
import EditStageModal from "../../../pages/Stages/EditStageModal";
import DeleteStageModal from "../../../pages/Stages/DeleteStageModal";
import OrderingStagesModal from "../../../pages/Stages/OrderingStagesModal";
import { Option } from "../../../types/Option";

export interface StagesTemplateProps {
  state: {
    error: string | null;
    loading: boolean;
  };
  table: {
    columns: MRT_ColumnDef<Candidate>[];
    filteredCandidates: Candidate[];
    rowSelection: { [key: string]: boolean };
    setRowSelection: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  };
  actionsBar: {
    setSearchTerm: Dispatch<SetStateAction<string>>;
    handleOpenDeleteStageModal: () => void;
    handleOpenAddStageModal: () => void;
    handleOpenEditStageModal: () => void;
    handleOpenOrderStageModal: () => void;
  };
  stageSwitcher: {
    uniqueStages: Stage[];
    activeStage: StageWithCandidates | undefined;
    setActiveStage: Dispatch<SetStateAction<StageWithCandidates | undefined>>;
  };
  stagingModal: {
    isOpenStagingModal: boolean;
    handleCloseStagingModal: () => void;
    handleSubmitStaging: () => void;
    stages: Stage[];
    setSelectedStage: Dispatch<SetStateAction<Option | undefined>>;
  };
  addStageModal: {
    isCreateStageModalOpen: boolean;
    handleCloseCreateModal: () => void;
    handleAddStageSubmit: () => void;
    setAddStageName: Dispatch<SetStateAction<string>>;
    addStageName: string;
  };
  editStageModal: {
    isOpenEditStageModal: boolean;
    handleCloseEditModal: () => void;
    handleEditStageSubmit: () => void;
    setEditTitleStage: Dispatch<SetStateAction<string>>;
    editTitleStage: string;
    stages: Stage[];
    setSelectedEditStage: Dispatch<SetStateAction<Stage | null>>;
  };
  deleteStageModal: {
    isOpenDeleteStageModal: boolean;
    handleCloseDeleteModal: () => void;
    handleDeleteStageSubmit: () => void;
    setSelectedDeleteStage: Dispatch<SetStateAction<Stage | null>>;
    stages: Stage[];
  };
  orderingModal: {
    isOrderingModalOpen: boolean;
    handleCloseOrderingModal: () => void;
    handleOrderStagesSubmit: () => void;
    columns: MRT_ColumnDef<{
      id: number;
      title: string;
      order: number;
    }>[];
    newOrder: {
      id: number;
      title: string;
      order: number;
    }[];
    setNewOrder: Dispatch<
      SetStateAction<
        {
          id: number;
          title: string;
          order: number;
        }[]
      >
    >;
  };
}

const StagesTemplate: React.FC<StagesTemplateProps> = ({
  table,
  actionsBar,
  stageSwitcher,
  stagingModal,
  addStageModal,
  editStageModal,
  deleteStageModal,
  orderingModal,
}) => {
  const [t] = useTranslation(["common"]);
  const { columns, filteredCandidates } = table;
  const {
    setSearchTerm,
    handleOpenDeleteStageModal,
    handleOpenAddStageModal,
    handleOpenEditStageModal,
    handleOpenOrderStageModal,
  } = actionsBar;
  const {
    handleCloseStagingModal,
    handleSubmitStaging,
    isOpenStagingModal,
    setSelectedStage,
    stages,
  } = stagingModal;
  const {
    addStageName,
    handleAddStageSubmit,
    handleCloseCreateModal,
    isCreateStageModalOpen,
    setAddStageName,
  } = addStageModal;
  const {
    editTitleStage,
    handleCloseEditModal,
    handleEditStageSubmit,
    isOpenEditStageModal,
    setEditTitleStage,
    setSelectedEditStage,
  } = editStageModal;
  const {
    handleCloseDeleteModal,
    handleDeleteStageSubmit,
    isOpenDeleteStageModal,
    setSelectedDeleteStage,
  } = deleteStageModal;
  const {
    columns: stagesColumns,
    handleCloseOrderingModal,
    handleOrderStagesSubmit,
    isOrderingModalOpen,
    newOrder,
    setNewOrder,
  } = orderingModal;
  const { uniqueStages, activeStage, setActiveStage } = stageSwitcher;
  return (
    <>
      <ActionsBar
        searchHandler={setSearchTerm}
        showDelete={true}
        addBtnLabel="Add Stage"
        deleteBtnLabel="Delete Stage"
        deleteHandler={handleOpenDeleteStageModal}
        addHandler={handleOpenAddStageModal}
        children={
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenEditStageModal}
            >
              {t("Edit Stages")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenOrderStageModal}
            >
              {t("Order Stages")}
            </Button>
          </>
        }
      />
      <Box className={styles.leadBox}>
        <Box className={styles.container2}>
          <Box className={styles.wrapper}>
            <StagesSwitcher
              uniqueStages={uniqueStages}
              activeStage={activeStage}
              setActiveStage={
                setActiveStage as Dispatch<SetStateAction<Stage | undefined>>
              }
            />
            <Box className={styles.tableBox}>
              <Grid container direction={"column"}>
                <Table columns={columns} data={filteredCandidates} />
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal isOpen={isOpenStagingModal} onCancel={handleCloseStagingModal}>
        <StagingModal
          handleCloseStagingModal={handleCloseStagingModal}
          handleSubmitStaging={handleSubmitStaging}
          setSelectedStage={setSelectedStage}
          stages={stages}
        />
      </Modal>
      <Modal isOpen={isCreateStageModalOpen} onCancel={handleCloseCreateModal}>
        <AddStageModal
          addStageName={addStageName}
          handleAddStageSubmit={handleAddStageSubmit}
          handleCloseCreateModal={handleCloseCreateModal}
          setAddStageName={setAddStageName}
        />
      </Modal>
      <Modal isOpen={isOpenEditStageModal} onCancel={handleCloseEditModal}>
        <EditStageModal
          editTitleStage={editTitleStage}
          handleCloseEditModal={handleCloseEditModal}
          handleEditStageSubmit={handleEditStageSubmit}
          setEditTitleStage={setEditTitleStage}
          setSelectedEditStage={setSelectedEditStage}
          stages={stages}
        />
      </Modal>
      <Modal isOpen={isOpenDeleteStageModal} onCancel={handleCloseDeleteModal}>
        <DeleteStageModal
          handleCloseDeleteModal={handleCloseDeleteModal}
          handleDeleteStageSubmit={handleDeleteStageSubmit}
          setSelectedDeleteStage={setSelectedDeleteStage}
          stages={stages}
        />
      </Modal>
      <Modal isOpen={isOrderingModalOpen} onCancel={handleCloseOrderingModal}>
        <OrderingStagesModal
          columns={stagesColumns}
          newOrder={newOrder}
          handleCloseOrderingModal={handleCloseOrderingModal}
          handleOrderStagesSubmit={handleOrderStagesSubmit}
          setNewOrder={setNewOrder}
        />
      </Modal>
    </>
  );
};

export default StagesTemplate;
