import { Button, TruncateModal } from "@anytimesoftware0/anytime-ui";
import styles from "./jobs.module.scss";
import JobsTemplate from "../../components/templates/JobsTemplate";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { JobWithCandidates } from "./jobs.types";
import { type MRT_ColumnDef } from "material-react-table";
import { initializeCompanies } from "../../pages/Clients/clients.slice";
import { AppDispatch, RootState } from "../../utils/store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { addJob, assignJobToClient, deleteJobs, updateJob } from "./jobs.slice";

export default function Jobs() {
  const navigate = useNavigate();
  const [t] = useTranslation(["common"]);
  const dispatch: AppDispatch = useDispatch();
  const {
    user,
    companies: companiesData,
    jobs: jobsData,
  } = useSelector((state: RootState) => ({
    user: state.user,
    companies: state.companies,
    jobs: state.jobs,
  }));

  const { companies, error, loading } = companiesData;

  const { jobs } = jobsData;

  const [view, setView] = useState("list");
  const [searchTerm, setSearchTerm] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [isAddJobModalOpen, setAddJobModalOpen] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState<number | null>(null);
  const [job, setJob] = useState({
    title: "",
    description: "",
  });
  const [editedJob, setEditedJob] = useState({
    title: "",
    description: "",
  });
  const [assignedClient, setAssignedClient] = useState({
    email: "",
  });
  const [selectedJobIdToAssign, setSelectedJobIdToAssign] = useState<
    number | null
  >(null);

  const handleChangeView = (
    event: React.MouseEvent<HTMLElement>,
    nextView: string
  ) => {
    setView(nextView ? nextView : "list");
  };

  /* Add Job Functions */
  const handleCloseCreateModal = () => setAddJobModalOpen(false);
  const handleOpenCreateModal = () => setAddJobModalOpen(true);
  const clearAddJobForm = () => {
    setJob({
      title: "",
      description: "",
    });
  };
  const onAddJobCancel = () => {
    if (job.title || job.description) {
      const isConfirmed = window.confirm(
        "Are you sure you want to discard the changes?"
      );

      if (isConfirmed) {
        clearAddJobForm();
        handleCloseCreateModal();
      }
    } else {
      handleCloseCreateModal();
    }
  };
  const handleAddJobSubmit = async () => {
    const { title, description } = job;
    if (title && description) {
      dispatch(addJob({ title, description, userId: user?.id as number }));
      handleCloseCreateModal();
      clearAddJobForm();
    }
  };

  /* Edit Job Functions */
  const clearEditJobForm = () => {
    setEditedJob({
      title: "",
      description: "",
    });
  };
  const onCancelEdit = () => {
    if (!selectedJobId) {
      return;
    }
    const oldJob = companies[0]?.jobs?.find((job) => job.id === selectedJobId);

    if (
      editedJob.title !== oldJob?.title ||
      editedJob.description !== oldJob?.description
    ) {
      const isConfirmed = window.confirm(
        "Are you sure you want to discard the changes?"
      );

      if (isConfirmed) {
        clearEditJobForm();
        setSelectedJobId(null);
      }
    } else {
      setSelectedJobId(null);
    }
  };
  useEffect(() => {
    if (selectedJobId) {
      const job = companies[0]?.jobs?.find((job) => job.id === selectedJobId);
      if (job) {
        setEditedJob({
          title: job.title,
          description: job.description,
        });
      }
    }
  }, [companies, selectedJobId]);
  const handleEditJobSubmit = () => {
    const { title, description } = editedJob;
    if (selectedJobId && title && description) {
      dispatch(updateJob({ title, description }, selectedJobId));
      clearEditJobForm();
    }
  };

  /* Assign Job Functions */
  const clearAssignClientForm = () => {
    setAssignedClient({
      email: "",
    });
  };
  const handleAssignJobSubmit = () => {
    const { email } = assignedClient;
    if (email && selectedJobIdToAssign) {
      dispatch(assignJobToClient(email, selectedJobIdToAssign));
      setSelectedJobIdToAssign(null);
    }
  };
  const onAssignCancel = () => {
    if (!selectedJobIdToAssign) {
      return;
    }
    const { email } = assignedClient;
    if (email) {
      const isConfirmed = window.confirm(
        "Are you sure you want to discard the changes?"
      );
      if (isConfirmed) {
        clearAssignClientForm();
        setSelectedJobIdToAssign(null);
      }
    } else {
      setSelectedJobIdToAssign(null);
    }
  };

  /* Delete Job Functions */
  const handleDeleteJobs = () => {
    const jobs = Object.keys(rowSelection).map((key) => filteredJobs[key]);
    dispatch(
      deleteJobs(
        jobs.map((job) => job.id),
        true
      )
    );
  };

  const columns: MRT_ColumnDef<JobWithCandidates>[] = useMemo(() => {
    return [
      {
        accessorKey: "id",
        header: "ID",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnOrdering: false,
      },
      {
        accessorKey: "title",
        header: "Title",
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "description",
        header: "Description",
        Cell: ({ cell }: any) => (
          <TruncateModal content={cell.getValue()} length={14} />
        ),
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        enableSorting: false,
        enableColumnActions: false,
      },
      {
        accessorKey: "candidates",
        header: "Assigned Candidates",
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        enableColumnActions: false,
        enableColumnOrdering: false,
      },
      {
        accessorKey: "actions",
        header: "Actions",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnOrdering: false,
        Cell: ({ row }: any) => {
          return (
            <Box className={styles.actionsWrapper}>
              <Button
                color="primary"
                onClick={() => {
                  setSelectedJobId(row.original.id);
                }}
                variant="contained"
              >
                {t("edit")}
              </Button>
              {((user as any)?.isClientOwner || user?.role === "admin") && (
                <Button
                  color="primary"
                  onClick={() => {
                    setSelectedJobIdToAssign(row.original.id);
                  }}
                  variant="contained"
                >
                  {t("assignToClient")}
                </Button>
              )}
              <Button
                color="primary"
                onClick={() => navigate(`/jobs/${row.original.id}`)}
                variant="contained"
              >
                {t("open")}
              </Button>
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies]);

  /* Company Compnent Actions Functions */
  const handleAssignOpen = (id: number) => {
    setSelectedJobIdToAssign(id);
  };

  const handleEditOpen = (id: number) => {
    setSelectedJobId(id);
  };

  const handleDeleteOpen = (id: number) => {
    dispatch(deleteJobs([id], true));
  };

  const data = useMemo(() => {
    return (
      jobs?.map((job) => ({
        ...job,
        candidates: job.stages.map((stage) => stage.candidates).flat()?.length,
      })) || []
    );
  }, [jobs]);

  const filteredJobs = useMemo(() => {
    return data.filter((job) => {
      return job.title.toLowerCase().includes(searchTerm.toLowerCase().trim());
    });
  }, [data, searchTerm]);

  useEffect(() => {
    if (user) {
      dispatch(initializeCompanies(true));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <JobsTemplate
      state={{ error, loading }}
      addJobModal={{
        isAddJobModalOpen,
        handleCloseCreateModal,
        handleAddJobSubmit,
        setJob,
        job,
        onCancel: onAddJobCancel,
      }}
      editJobModal={{
        isEditJobModalOpen: !!selectedJobId,
        handleCloseEditModal: () => setSelectedJobId(null),
        handleEditJobSubmit,
        setEditedJob,
        editedJob,
        onCancelEdit,
      }}
      assignCandidateModal={{
        isAssignCandidateModalOpen: !!selectedJobIdToAssign,
        handleCloseAssignModal: () => setSelectedJobIdToAssign(null),
        handleAssignSubmit: handleAssignJobSubmit,
        setAssignedClient,
        assignedClient,
        onCancelAssign: onAssignCancel,
      }}
      table={{
        columns,
        filteredJobs,
        rowSelection,
        setRowSelection,
      }}
      actionsBar={{
        setSearchTerm,
        handleDeleteJobs,
        handleOpenCreateModal,
      }}
      viewOptions={{
        view,
        handleChangeView,
        company: companies[0],
      }}
      companyAction={{
        handleAssignOpen,
        handleEditOpen,
        handleDeleteOpen,
      }}
    />
  );
}
