import Candidate from "./candidates.types";
import axios from "../../utils/axios";
import config from "../../utils/config";

const baseUrl = `${config.backendUrl}/candidates`;

export type CreateCandidateDto = Omit<
  Candidate,
  "id" | "companyId" | "jobId" | "stage"
>;

export type UpdateCandidateDto = Omit<
  Candidate,
  "id" | "companyId" | "jobId"
> & {
  staging: {
    companyId: number;
    jobId: number;
    stageId: number;
  };
  unstaging: boolean;
};

const createMany = async (
  createCandidateDtos: CreateCandidateDto[]
): Promise<Candidate[]> => {
  const { data } = await axios.post<Candidate[]>(baseUrl, createCandidateDtos, {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-rapidapi-host": "file-upload8.p.rapidapi.com",
      "x-rapidapi-key": "your-rapidapi-key-here",
    },
  });
  return data;
};

const getAll = async (): Promise<Candidate[]> => {
  const { data } = await axios.get<Candidate[]>(baseUrl);
  return data;
};

const getOne = async (id: number): Promise<Candidate> => {
  const { data } = await axios.get<Candidate>(`${baseUrl}/${id}`);
  return data;
};

const getPageCandidates = async (page: string): Promise<Candidate[]> => {
  const { data } = await axios.get<Candidate[]>(`${baseUrl}/pages/${page}`);
  return data;
};

const updateOne = async (
  id: number,
  updateCandidateDto: Partial<UpdateCandidateDto>
): Promise<Candidate> => {
  const { data } = await axios.patch<Candidate>(
    `${baseUrl}/${id}`,
    updateCandidateDto
  );
  return data;
};

const deleteOne = async (id: number): Promise<Candidate> => {
  const { data } = await axios.delete<Candidate>(`${baseUrl}/${id}`);
  return data;
};

const candidateService = {
  createMany,
  getAll,
  getOne,
  updateOne,
  deleteOne,
  getPageCandidates,
};

export default candidateService;
