import Papa from "papaparse";
import Candidate from "./candidates.types";
import CandidatesTemplate from "../../components/templates/CandidatesTemplate";
import { Box, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { createCandidates } from "./candidates.slice";
import { type MRT_ColumnDef } from "material-react-table";
import { initializeCompanies } from "../../pages/Clients/clients.slice";
import { AppDispatch, RootState } from "../../utils/store";
import { useDispatch, useSelector } from "react-redux";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import {
  deleteManyCandidates,
  initializeCandidates,
  updateManyCandidates,
} from "./candidates.slice";
import { Option } from "../../types/Option";
import { Button } from "@anytimesoftware0/anytime-ui";

const enEmail = {
  subject: "A new recommended candidate",
  message:
    "<h1>A candidate has been added</h1><p><br></p><p>Hi there,</p><p><br></p><p>{{firstName}} {{lastName}} has been added to {{title}}</p>",
  language: { label: "EN", value: "EN" },
};
const czEmail = {
  subject: "Nový doporučený kandidát",
  message:
    "<h1>Kandidát byl přidán</h1><p><br></p><p>Ahoj,</p><p><br></p><p>{{firstName}} {{lastName}} byl přidán do {{title}}</p>",
  language: { label: "CZ", value: "CZ" },
};

export default function Candidates() {
  const [t] = useTranslation(["common"]);
  const dispatch: AppDispatch = useDispatch();
  const {
    user,
    candidates,
    companies: companiesData,
  } = useSelector((state: RootState) => ({
    user: state.user,
    candidates: state.candidates,
    companies: state.companies,
  }));

  const { companies, error, loading } = companiesData;

  const ref = useRef<HTMLInputElement>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [filteredCompanies, setFilteredCompanies] = useState<string[]>([]);
  const [filteredJobs, setFiliteredJobs] = useState<string[]>([]);
  const [filteredStages, setFiliteredStages] = useState<string[]>([]);
  const [isAddCandidateModalOpen, setIsAddCandidateModalOpen] = useState(false);
  const [ShowColumnsEditModal, setShowColumnsEditModal] = useState(false);
  const [openEditCandidateMdoel, setOpenEditCandidateMdoel] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState<Candidate>();
  const [assignedCandidateId, setAssignedCandidateId] = useState<number>();
  const [viewCandidateId, setViewCandidateId] = useState<number | null>(null);
  const [SelectedColumnsToShow, setSelectedColumnsToShow] = useState<string[]>([
    "ID",
    "Actions",
    "Profile",
    "First Name",
    "Last Name",
    "Email",
    "Linkedin URL",
    "Stage",
    "CV File",
  ]);
  const [candidate, setCandidate] = useState({
    firstName: "",
    lastName: "",
    email: "",
    linkedin: "",
  });
  const [assigneCandidate, setAssigneCandidate] = useState<{
    companyId: Option;
    jobId: Option;
  }>({
    companyId: { label: "", value: "" },
    jobId: { label: "", value: "" },
  });
  const [email, setEmail] = useState<{
    subject: string;
    message: string;
    language: Option;
  }>({
    subject: "",
    message: "",
    language: { label: "EN", value: "EN" },
  });
  const [customColumns, setCustomColumns] = useState<
    Record<string, { id: number; value: string }>
  >({});
  const [openDrawer, setOpenDrawer] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newCandidates, setNewCandidates] = useState<
    { [key: string]: string }[] | null
  >(null);

  const fileUploadRef = useRef<HTMLInputElement | null>(null);

  const handleOpenEditCandidateModal = () => setOpenEditCandidateMdoel(true);
  const handleCloseEditCandidateModal = () => setOpenEditCandidateMdoel(false);

  const filteredByCompaniesCandidates =
    filteredCompanies.length > 0
      ? candidates.filter((candidate) =>
          filteredCompanies.some(
            (companyName) =>
              companyName ===
              companies.find(({ id }) => candidate.companyId === id)?.name
          )
        )
      : candidates;

  const filiteredByJobsCandidates =
    filteredJobs.length > 0
      ? filteredByCompaniesCandidates.filter((candidate) =>
          filteredJobs.some((job) => job === candidate.stage?.job.title)
        )
      : filteredByCompaniesCandidates;

  const filiteredByStagesCandidates =
    filteredStages.length > 0
      ? filteredByCompaniesCandidates.filter((candidate) =>
          filteredStages.some(
            (stageTitle) =>
              stageTitle === candidate.stage?.title &&
              filteredJobs.some((job) => job === candidate.stage?.job.title)
          )
        )
      : filiteredByJobsCandidates;

  const columns: MRT_ColumnDef<Candidate & { stageText: string }>[] =
    useMemo(() => {
      const customVariables = candidates.map(
        ({ customVariables }) => customVariables
      );
      const customColumns = Array.from(
        new Set(
          customVariables
            .map((customVariable) => Object.keys(customVariable))
            .flat()
        )
      ).map((customVariableKey) => {
        const spacedHeader = customVariableKey.replace(/([A-Z])/g, " $1");
        const header =
          spacedHeader.charAt(0).toUpperCase() + spacedHeader.slice(1);

        return {
          accessorKey: `customVariables.${customVariableKey}` as any,
          header,
        };
      });
      function filterColumns(
        originalArray: MRT_ColumnDef<Candidate & { stageText: string }>[],
        headersToFilter: string[]
      ) {
        return originalArray.filter(
          (column: MRT_ColumnDef<Candidate & { stageText: string }>) =>
            headersToFilter.includes(column.header)
        );
      }

      // Example usage
      const originalArray: MRT_ColumnDef<Candidate & { stageText: string }>[] =
        [
          {
            accessorKey: "id",
            header: "ID",
            size: 40,
            enableSorting: false,
            enableColumnActions: false,
            enableColumnOrdering: false,
          },
          {
            header: "Profile",
            Cell: ({ row }) => {
              return (
                <>
                  <Button
                    color="primary"
                    onClick={() => {
                      setViewCandidateId(row.original.id);
                      setOpenDrawer(true);
                    }}
                    variant="contained"
                  >
                    Show Profile
                  </Button>
                </>
              );
            },
            enableSorting: false,
            enableColumnActions: false,
            enableColumnOrdering: false,
          },
          {
            header: "Actions",
            Cell: ({ row }: any) => {
              return (
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Button
                    color="primary"
                    onClick={() => {
                      setSelectedCandidate(row.original);
                      handleOpenEditCandidateModal();
                    }}
                    variant="contained"
                  >
                    Edit
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => {
                      setAssignedCandidateId(row.original.id);
                    }}
                    variant="contained"
                  >
                    Assign
                  </Button>
                </Box>
              );
            },
            enableSorting: false,
            enableColumnActions: false,
            enableColumnOrdering: false,
          },
          {
            accessorKey: "firstName",
            header: "First Name",
            size: 120,
            enableColumnOrdering: false,
          },
          {
            accessorKey: "lastName",
            header: "Last Name",
            size: 120,
            enableSorting: false,
            enableColumnActions: false,
            enableColumnOrdering: false,
          },
          {
            accessorKey: "email",
            header: "Email",
            enableClickToCopy: true,
            size: 250,
            enableColumnOrdering: false,
          },
          {
            accessorKey: "linkedinUrl",
            header: "Linkedin URL",
            enableSorting: false,
            enableColumnActions: false,
            enableColumnOrdering: false,
          },
          {
            accessorKey: "stageText",
            header: "Stage",
            enableSorting: false,
            enableColumnActions: false,
            enableColumnOrdering: false,
          },
          // Assuming customColumns is defined somewhere
          ...customColumns,
          {
            accessorKey: "file",
            header: "CV File",
            Cell: ({ cell }: any) => {
              return cell.getValue() ? (
                <Link color="primary" href={cell.getValue()} download>
                  {t("download")}
                </Link>
              ) : null;
            },
            enableSorting: false,
            enableColumnActions: false,
            enableColumnOrdering: false,
          },
        ];

      return filterColumns(originalArray, SelectedColumnsToShow);
    }, [candidates, SelectedColumnsToShow, t]);

  const handleOpenCreateModal = () => setIsAddCandidateModalOpen(true);
  const handleCloseCreateModal = () => {
    setIsAddCandidateModalOpen(false);
    clearCreateForm();
  };

  const onCreateModalCancel = () => {
    if (candidate.firstName || candidate.lastName || candidate.email) {
      if (
        window.confirm(
          "Are you sure you want to cancel? All changes will be lost."
        )
      ) {
        handleCloseCreateModal();
        clearCreateForm();
      }
    } else {
      handleCloseCreateModal();
    }
  };

  const clearCreateForm = () => {
    setCandidate({
      firstName: "",
      lastName: "",
      email: "",
      linkedin: "",
    });
    setCustomColumns({});
  };

  const handleAddCandidateSubmit = () => {
    const cvFile = null;
    const { firstName, lastName, email, linkedin } = candidate;
    const candidateDto = {
      firstName: firstName || "",
      lastName: lastName || "",
      email: email || "",
      linkedinUrl: linkedin || null,
      customVariables: {},
    };

    Object.keys(customColumns)
      .filter((key) => key !== "")
      .forEach(
        (key) =>
          (candidateDto.customVariables = {
            ...candidateDto.customVariables,
            [key]: customColumns[key].value,
          })
      );

    const formData = new FormData();
    formData.append("candidateDto", JSON.stringify([candidateDto]));
    formData.append("cvFile", cvFile as any);
    dispatch(createCandidates(formData as any));
    handleCloseCreateModal();
    clearCreateForm();
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      Papa.parse(files[0], {
        complete: ({ data }) => {
          const objs = data.slice(1).map((row) => {
            const obj: { [key: string]: string } = {};
            const keys = data[0] as string[];
            for (let i = 0; i < keys.length; i++) {
              if (Array.isArray(row)) {
                if (typeof row[i] === "string") {
                  obj[keys[i]] = row[i];
                }
              }
            }

            return obj;
          });

          setNewCandidates(objs);

          if (fileUploadRef && fileUploadRef.current) {
            fileUploadRef.current.value = "";
            fileUploadRef.current.files = null;
          }
        },
      });
    }
  };

  const handleDeleteCandidates = () => {
    const candidates = Object.keys(rowSelection).map(
      (key) => filteredData[key]
    );
    dispatch(deleteManyCandidates(candidates.map(({ id }) => id)));
    setRowSelection({});
  };

  const handleAssignCandidates = () => {
    if (
      !Array.isArray(assigneCandidate.companyId) &&
      !Array.isArray(assigneCandidate.jobId) &&
      assigneCandidate.companyId?.value &&
      assigneCandidate.jobId?.value &&
      assignedCandidateId
    ) {
      const stageId = companies
        .find(
          (company) =>
            company.id ===
            Number(
              !Array.isArray(assigneCandidate.companyId)
                ? assigneCandidate.companyId?.value
                : 0
            )
        )
        ?.jobs.find(
          (job) =>
            job.id ===
            Number(
              !Array.isArray(assigneCandidate.jobId)
                ? assigneCandidate.jobId?.value
                : 0
            )
        )
        ?.stages.find((stage) => stage.order === 1)?.id;

      const emailForm =
        email.subject.length > 0 && email.message.length > 0
          ? {
              subject: email.subject,
              message: email.message,
            }
          : undefined;

      const candidateDto = {
        staging: {
          companyId: assigneCandidate.companyId.value,
          jobId: assigneCandidate.jobId.value,
          stageId,
          emailMessage: emailForm,
        },
      };

      dispatch(
        updateManyCandidates([assignedCandidateId], candidateDto as any)
      );
    }
  };

  const handleEditCandidateSubmit = () => {
    if (
      selectedCandidate &&
      !Object.values({
        ...selectedCandidate,
        ...selectedCandidate.customVariables,
        selectedCandidate: "",
      }).every((value) => value === "" || value === null)
    ) {
      const updateCandidateDto = {
        ...selectedCandidate,
        id: undefined,
        companyId: undefined,
        jobId: undefined,
        stage: undefined,
        stageText: undefined,
      };
      delete updateCandidateDto.stageText;
      Object.keys(customColumns)
        .filter((key) => key !== "")
        .forEach(
          (key) =>
            (updateCandidateDto.customVariables = {
              ...updateCandidateDto.customVariables,
              [key]: customColumns[key].value,
            })
        );

      dispatch(
        updateManyCandidates([selectedCandidate.id], updateCandidateDto as any)
      );
    }
    setCustomColumns({});
    handleCloseEditCandidateModal();
  };

  const handleCheckboxChange = (column: string) => {
    const selectedIndex = SelectedColumnsToShow.indexOf(column);
    const newSelectedColumns = [...SelectedColumnsToShow];

    if (selectedIndex === -1) {
      newSelectedColumns.push(column);
    } else {
      newSelectedColumns.splice(selectedIndex, 1);
    }

    setSelectedColumnsToShow(newSelectedColumns);
  };

  const date = filiteredByStagesCandidates.map((candidate) => ({
    ...candidate,
    stageText: !candidate.stage
      ? "does not assigned yet"
      : `Staged at "${candidate.stage.title}" for "${candidate.stage.job.title}" job, by "${candidate.stage.job.company.name}" company`,
  }));

  const filteredData = useMemo(() => {
    return date.filter((candidate) => {
      return (
        candidate.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        candidate.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        candidate.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }, [date, searchTerm]);

  useEffect(() => {
    if (user && candidates.length <= 0) {
      dispatch(initializeCandidates());
      dispatch(initializeCompanies());
    }
  }, [candidates, dispatch, user]);

  const jobs = useMemo(() => {
    const selectedCompanyId = Number(
      !Array.isArray(assigneCandidate.companyId)
        ? assigneCandidate.companyId?.value
        : 0
    );
    const selectedCompany = companies.find(
      (company) => company.id === selectedCompanyId
    );
    return selectedCompany?.jobs || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies, JSON.stringify(assigneCandidate.companyId)]);

  useEffect(() => {
    if (!Array.isArray(email.language) && email.language?.value === "EN") {
      setEmail(enEmail);
    } else {
      setEmail(czEmail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email.language, JSON.stringify(email.language)]);

  const handleClick = () => {
    ref.current?.click();
  };
  return (
    <CandidatesTemplate
      state={{
        error,
        loading,
      }}
      table={{
        columns,
        filteredCandidates: filteredData,
        rowSelection,
        setRowSelection,
      }}
      actionsBar={{
        setSearchTerm,
        handleDeleteCandidates,
        handleOpenCreateModal,
      }}
      addCandidateModal={{
        candidate,
        setCandidate,
        customColumns,
        setCustomColumns,
        ref,
        handleAddCandidateSubmit,
        handleCloseCreateModal,
        onCancel: onCreateModalCancel,
        isAddCandidateModalOpen,
      }}
      filters={{
        filteredCompanies,
        setFilteredCompanies,
        filteredJobs,
        setFiliteredJobs,
        filteredStages,
        setFiliteredStages,
        companies,
      }}
      columnsView={{
        ShowColumnsEditModal,
        handleCloseColumnsModal: () => setShowColumnsEditModal(false),
        SelectedColumnsToShow,
        handleCheckboxChange,
        handkeOpenColumnsModal: () => setShowColumnsEditModal(true),
      }}
      assginCandidateModal={{
        assigneCandidate,
        companies: companies,
        email,
        setEmail,
        handleAssignCandidateSubmit: handleAssignCandidates,
        onCancelAssign: () => setAssignedCandidateId(undefined),
        handleCloseCandidateModal: () => setAssignedCandidateId(undefined),
        setAssigneCandidate,
        isAssignCandidateModalOpen: !!assignedCandidateId,
        jobs,
        onAssignCancel: () => setAssignedCandidateId(undefined),
      }}
      editCandidateModal={{
        handleCloseEditModal: handleCloseEditCandidateModal,
        handleEditCandidateSubmit,
        onCancelEdit: () => {
          setCustomColumns({});
          handleCloseEditCandidateModal();
        },
        customColumns,
        setCustomColumns,
        ref,
        isEditCandidateModalOpen: openEditCandidateMdoel,
        selectedCandidate,
        setSelectedCandidate,
      }}
      showProfile={{
        setIsShowProfileModalOpen: setOpenDrawer,
        selectedCandidateId: viewCandidateId,
        isShowProfileModalOpen: openDrawer,
      }}
      fileUploadRef={fileUploadRef}
      handleFileUpload={handleFileUpload}
      handleClick={handleClick}
    />
  );
}
