import { useForm } from "react-hook-form";
import { Box, FormControl, Typography } from "@mui/material";
import { Button, TextField, Select } from "@anytimesoftware0/anytime-ui";
import styles from "./EditStageModal.module.scss";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";
import { Stage } from "../stages.types";
import { Option } from "../../../types/Option";

interface EditStageModalProps {
  handleCloseEditModal: () => void;
  handleEditStageSubmit: () => void;
  setEditTitleStage: Dispatch<SetStateAction<string>>;
  editTitleStage: string;
  stages: Stage[];
  setSelectedEditStage: Dispatch<SetStateAction<Stage | null>>;
}

const EditStageModal: React.FC<EditStageModalProps> = ({
  setSelectedEditStage,
  stages,
  handleCloseEditModal,
  handleEditStageSubmit,
  editTitleStage,
  setEditTitleStage,
}) => {
  const [t] = useTranslation(["common"]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submit = () => {
    handleEditStageSubmit();
    handleCloseEditModal();
  };

  return (
    <form
      noValidate
      className={styles.modalContent}
      onSubmit={handleSubmit(submit)}
    >
      <Typography variant="h3" component="h3">
        Edit Stage
      </Typography>
      <FormControl fullWidth>
        <Select
          placeholder="Select stage"
          onChange={(v) => {
            return setSelectedEditStage(
              stages.find(
                (stage) => stage.id === Number((v as Option)?.value)
              ) ?? null
            );
          }}
          isSearchable
          options={stages.map((stage) => ({
            label: stage.title,
            value: String(stage.id),
          }))}
        />
      </FormControl>
      <TextField
        required
        fullWidth
        onChange={(e) => setEditTitleStage(e.target.value)}
        value={editTitleStage}
        error={!!errors.root}
        helperText={errors.root?.message}
        register={register}
        validations={{
          required: { value: true, message: "title is required" },
        }}
        label={t("title")}
        name="title"
        focused
      />
      <Box className={styles.buttons}>
        <Button type="submit" color="primary" className={styles.add}>
          {t("save")}
        </Button>
        <Button
          variant="contained"
          color="danger"
          onClick={handleCloseEditModal}
        >
          {t("cancel")}
        </Button>
      </Box>
    </form>
  );
};

export default EditStageModal;
