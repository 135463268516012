import { ActionsBar, Table, Modal, Loader } from "@anytimesoftware0/anytime-ui";
import AddRecruiterModal from "../../../pages/Recruiters/AddRecruiterModal";
import { User } from "../../../pages/Users/users.types";
import { Grid } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { Dispatch, SetStateAction } from "react";

export interface RecruitersProps {
  state: {
    error: string | null;
    loading: boolean;
  };
  table: {
    columns: MRT_ColumnDef<User>[];
    filteredRecruiters: User[];
    rowSelection: { [key: string]: boolean };
    setRowSelection: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  };
  addRecruiterModal: {
    isAddRecruiterModalOpen: boolean;
    handleCloseCreateModal: () => void;
    recruiter: {
      email: string;
      password: string;
    };
    setRecruiter: Dispatch<
      SetStateAction<{
        email: string;
        password: string;
      }>
    >;
    handleAddRecruiterSubmit: () => void;
    onCancel: () => void;
  };
  actionsBar: {
    setSearchTerm: Dispatch<SetStateAction<string>>;
    handleDeleteRecruiters: () => void;
    handleOpenCreateModal: () => void;
  };
}

const RecruitersTemplate: React.FC<RecruitersProps> = ({
  addRecruiterModal,
  table,
  actionsBar,
  state,
}) => {
  const {
    isAddRecruiterModalOpen,
    handleCloseCreateModal,
    recruiter,
    setRecruiter,
    handleAddRecruiterSubmit,
    onCancel,
  } = addRecruiterModal;
  const { columns, filteredRecruiters, rowSelection, setRowSelection } = table;
  const { setSearchTerm, handleDeleteRecruiters, handleOpenCreateModal } =
    actionsBar;
  const { loading } = state;

  return (
    <>
      <Modal isOpen={isAddRecruiterModalOpen} onCancel={onCancel}>
        <AddRecruiterModal
          recruiter={recruiter}
          setRecruiter={setRecruiter}
          onCancel={onCancel}
          handleCloseCreateModal={handleCloseCreateModal}
          handleAddRecruiterSubmit={handleAddRecruiterSubmit}
        />
      </Modal>
      <ActionsBar
        searchHandler={setSearchTerm}
        showDelete={Object.keys(rowSelection).length > 0}
        addBtnLabel="Add User"
        deleteHandler={handleDeleteRecruiters}
        deleteBtnLabel="Delete Users"
        addHandler={handleOpenCreateModal}
      />
      {loading ? (
        <Loader />
      ) : (
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Table
              onRowSelectionChange={setRowSelection}
              columns={columns}
              data={filteredRecruiters}
              rowSelection={rowSelection}
              enableColumnOrdering
              enableSorting
              enableColumnActions
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default RecruitersTemplate;
