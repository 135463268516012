import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User } from "../../pages/Users/users.types";
import { AppDispatch } from "../../utils/store";
import authService from "./auth.api";
import { AxiosError } from "axios";
import { setToken } from "../../utils/axios";
import { Credentials } from "./auth.types";
import Cookies from "js-cookie";
import toast from "react-hot-toast";

const initialState = null as User | null;

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUser(state, { payload: user }: PayloadAction<User>) {
      return user;
    },
    clearUser(state) {
      return null;
    },
  },
});

export const { addUser, clearUser } = userSlice.actions;

export const login = (credentials: Credentials, remember = true) => {
  return async (dispatch: AppDispatch) => {
    try {
      const userWithToken = await authService.login(credentials);
      toast.success("Signed in successfully");
      setToken(userWithToken.accessToken);
      dispatch(addUser(userWithToken.user));
      if (remember) {
        Cookies.set("auth", JSON.stringify(userWithToken));
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        toast.error(`Failed to login. ${error.response?.data.message}`);
      }
    }
  };
};

export const logout = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(clearUser());
    Cookies.remove("auth");
    setToken("");
    toast.success("Signed out successfully");
  };
};

export default userSlice.reducer;
