import { useForm } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import { Button, TextField, Checkbox } from "@anytimesoftware0/anytime-ui";
import styles from "./AddRoleModal.module.scss";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";
import { Permission } from "../roles.types";

interface AddRoleModalProps {
  handleCloseCreateModal: () => void;
  handleAddRoleSubmit: () => void;
  setRole: Dispatch<
    SetStateAction<{
      name: string;
    }>
  >;
  role: {
    name: string;
  };
  onCancel: () => void;
  permissions: Permission[];
  selectedPermissions: Permission[];
  handlePermissionToggle: (permission: Permission) => void;
}

interface IFormInput {
  name: string;
}

const AddRoleModal: React.FC<AddRoleModalProps> = ({
  handlePermissionToggle,
  permissions,
  selectedPermissions,
  handleCloseCreateModal,
  handleAddRoleSubmit,
  onCancel,
  setRole,
  role,
}) => {
  const [t] = useTranslation(["common"]);
  const { name } = role;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();

  const submit = () => {
    handleAddRoleSubmit();
    handleCloseCreateModal();
  };

  return (
    <form
      noValidate
      className={styles.modalContent}
      onSubmit={handleSubmit(submit)}
    >
      <Typography variant="h3" component="h3">
        Add New Role
      </Typography>
      <TextField
        required
        fullWidth
        onChange={(e) => setRole({ ...role, name: e.target.value })}
        error={!!errors.name}
        helperText={errors.name?.message}
        register={register}
        validations={{
          required: { value: true, message: "name is required" },
        }}
        value={name}
        label={t("name")}
        name="name"
        focused
      />
      {permissions.map((permission) => (
        <Checkbox
          key={permission.name}
          defaultChecked={false}
          label={permission.name}
          isChecked={selectedPermissions.some(
            (p) => p.id === permission.id && p.name === permission.name
          )}
          onChange={() => handlePermissionToggle(permission)}
        />
      ))}
      <Box className={styles.buttons}>
        <Button type="submit" color="primary" className={styles.add}>
          {t("addRole")}
        </Button>
        <Button variant="contained" color="danger" onClick={onCancel}>
          {t("cancel")}
        </Button>
      </Box>
    </form>
  );
};

export default AddRoleModal;
