import { useForm } from "react-hook-form";
import { Box, FormControl, Typography } from "@mui/material";
import { Button, Select, TextField } from "@anytimesoftware0/anytime-ui";
import styles from "./EditCompanyModal.module.scss";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";
import { Option } from "../../../types/Option";

interface EditCompanyModalProps {
  handleCloseCreateModal: () => void;
  handleEditCompanySubmit: () => void;
  setEditedCompany: Dispatch<
    SetStateAction<{
      name: string;
      language: string;
    }>
  >;
  editedCompany: {
    name: string;
    language: string;
  };
  onCancelEdit: () => void;
}

interface IFormInput {
  name: string;
  langauge: string;
}

const languages = [
  { label: "EN", value: "EN" },
  { label: "CZ", value: "CZ" },
];

const EditCompanyModal: React.FC<EditCompanyModalProps> = ({
  handleCloseCreateModal,
  handleEditCompanySubmit,
  onCancelEdit,
  setEditedCompany,
  editedCompany,
}) => {
  const [t] = useTranslation(["common"]);
  const { name, language } = editedCompany;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();

  const submit = () => {
    handleEditCompanySubmit();
    handleCloseCreateModal();
  };

  return (
    <form
      noValidate
      className={styles.modalContent}
      onSubmit={handleSubmit(submit)}
    >
      <Typography variant="h3" component="h3">
        Edit Company
      </Typography>
      <TextField
        required
        fullWidth
        onChange={(e) =>
          setEditedCompany({ ...editedCompany, name: e.target.value })
        }
        value={name}
        error={!!errors.name}
        helperText={errors.name?.message}
        register={register}
        validations={{
          required: { value: true, message: "Name is required" },
        }}
        label={t("name")}
        name="name"
        focused
      />
      <FormControl fullWidth>
        <Select
          placeholder="Select Language"
          onChange={(v) => {
            setEditedCompany({
              ...editedCompany,
              language: (v as Option[])[0].value as string,
            });
          }}
          isSearchable
          value={languages.find((l) => l.value === language)}
          options={languages}
        />
      </FormControl>
      <Box className={styles.buttons}>
        <Button type="submit" color="primary" className={styles.add}>
          {t("edit")}
        </Button>
        <Button variant="contained" color="danger" onClick={onCancelEdit}>
          {t("cancel")}
        </Button>
      </Box>
    </form>
  );
};

export default EditCompanyModal;
