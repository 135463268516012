import axios from "../../utils/axios";
import config from "../../utils/config";
import { Credentials, UserWithToken } from "./auth.types";

const baseUrl = `${config.backendUrl}/auth`;
const login = async (credentials: Credentials): Promise<UserWithToken> => {
  const { data } = await axios.post<UserWithToken>(
    `${baseUrl}/login`,
    credentials
  );
  return data;
};

const authService = { login };

export default authService;
