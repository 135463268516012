import { useForm } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import { Button } from "@anytimesoftware0/anytime-ui";
import { TextField } from "@anytimesoftware0/anytime-ui";
import styles from "./AssignToClientModal.module.scss";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";

interface AssignToClientModalProps {
  handleCloseAssignModal: () => void;
  handleAssignSubmit: () => void;
  setAssignedClient: Dispatch<
    SetStateAction<{
      email: string;
    }>
  >;
  assignedClient: {
    email: string;
  };
  onCancelAssign: () => void;
}

interface IFormInput {
  email: string;
}

const AssignToClientModal: React.FC<AssignToClientModalProps> = ({
  handleCloseAssignModal,
  assignedClient,
  handleAssignSubmit,
  onCancelAssign,
  setAssignedClient,
}) => {
  const [t] = useTranslation(["common"]);
  const { email } = assignedClient;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();

  const submit = () => {
    handleAssignSubmit();
    handleCloseAssignModal();
  };

  return (
    <form
      noValidate
      className={styles.modalContent}
      onSubmit={handleSubmit(submit)}
    >
      <Typography variant="h3" component="h3">
        Assign To Client
      </Typography>
      <TextField
        required
        fullWidth
        onChange={(e) =>
          setAssignedClient({ ...assignedClient, email: e.target.value })
        }
        value={email}
        error={!!errors.email}
        helperText={errors.email?.message}
        register={register}
        validations={{
          required: { value: true, message: "Email is required" },
          pattern: {
            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: "Invalid email",
          },
        }}
        label={t("email")}
        name="email"
        focused
      />
      <Box className={styles.buttons}>
        <Button type="submit" color="primary" className={styles.add}>
          {t("save")}
        </Button>
        <Button variant="contained" color="danger" onClick={onCancelAssign}>
          {t("cancel")}
        </Button>
      </Box>
    </form>
  );
};

export default AssignToClientModal;
