import Company from "../../../pages/Clients/clients.types";
import AddJobModal from "../../../pages/Jobs/AddJobModal";
import EditJobModal from "../../../pages/Jobs/EditJobModal";
import CompanyComponentClient from "../../../pages/Jobs/CompanyComponentClient";
import AssignToClientModal from "../../../pages/Jobs/AssignToClientModal";
import { Grid } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { JobWithCandidates } from "../../../pages/Jobs/jobs.types";
import { Dispatch, SetStateAction } from "react";
import {
  ActionsBar,
  Loader,
  Modal,
  Table,
  ViewSwitcher,
} from "@anytimesoftware0/anytime-ui";

export interface JobsTemplateProps {
  state: {
    error: string | null;
    loading: boolean;
  };
  table: {
    columns: MRT_ColumnDef<JobWithCandidates>[];
    filteredJobs: JobWithCandidates[];
    rowSelection: { [key: string]: boolean };
    setRowSelection: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  };
  addJobModal: {
    isAddJobModalOpen: boolean;
    handleCloseCreateModal: () => void;
    handleAddJobSubmit: () => void;
    setJob: Dispatch<
      SetStateAction<{
        title: string;
        description: string;
      }>
    >;
    job: {
      title: string;
      description: string;
    };
    onCancel: () => void;
  };
  editJobModal: {
    isEditJobModalOpen: boolean;
    handleCloseEditModal: () => void;
    handleEditJobSubmit: () => void;
    setEditedJob: Dispatch<
      SetStateAction<{
        title: string;
        description: string;
      }>
    >;
    editedJob: {
      title: string;
      description: string;
    };
    onCancelEdit: () => void;
  };
  assignCandidateModal: {
    isAssignCandidateModalOpen: boolean;
    handleCloseAssignModal: () => void;
    handleAssignSubmit: () => void;
    setAssignedClient: Dispatch<
      SetStateAction<{
        email: string;
      }>
    >;
    assignedClient: {
      email: string;
    };
    onCancelAssign: () => void;
  };
  actionsBar: {
    setSearchTerm: Dispatch<SetStateAction<string>>;
    handleDeleteJobs: () => void;
    handleOpenCreateModal: () => void;
  };
  viewOptions: {
    view: string;
    handleChangeView: (
      event: React.MouseEvent<HTMLElement>,
      newAlignment: string
    ) => void;
    company: Company;
  };
  companyAction: {
    handleAssignOpen: (id: number) => void;
    handleEditOpen: (id: number) => void;
    handleDeleteOpen: (id: number) => void;
  };
}

const JobsTemplate: React.FC<JobsTemplateProps> = ({
  addJobModal,
  editJobModal,
  assignCandidateModal,
  table,
  actionsBar,
  viewOptions,
  state,
  companyAction,
}) => {
  const {
    isAddJobModalOpen,
    handleCloseCreateModal,
    job,
    setJob,
    handleAddJobSubmit,
    onCancel,
  } = addJobModal;
  const {
    isEditJobModalOpen,
    handleCloseEditModal,
    handleEditJobSubmit,
    setEditedJob,
    editedJob,
    onCancelEdit,
  } = editJobModal;
  const {
    isAssignCandidateModalOpen,
    handleCloseAssignModal,
    handleAssignSubmit,
    setAssignedClient,
    assignedClient,
    onCancelAssign,
  } = assignCandidateModal;
  const { columns, filteredJobs, rowSelection, setRowSelection } = table;
  const { setSearchTerm, handleDeleteJobs, handleOpenCreateModal } = actionsBar;
  const { loading } = state;
  const { view, handleChangeView, company } = viewOptions;
  const { handleAssignOpen, handleEditOpen, handleDeleteOpen } = companyAction;

  return (
    <>
      <Modal isOpen={isAddJobModalOpen} onCancel={onCancel}>
        <AddJobModal
          job={job}
          setJob={setJob}
          onCancel={onCancel}
          handleCloseCreateModal={handleCloseCreateModal}
          handleAddJobSubmit={handleAddJobSubmit}
        />
      </Modal>
      {isEditJobModalOpen && (
        <Modal isOpen={true} onCancel={onCancelEdit}>
          <EditJobModal
            editedJob={editedJob}
            setEditedJob={setEditedJob}
            onCancelEdit={onCancelEdit}
            handleCloseEditModal={handleCloseEditModal}
            handleEditJobSubmit={handleEditJobSubmit}
          />
        </Modal>
      )}
      {isAssignCandidateModalOpen && (
        <Modal isOpen={true} onCancel={onCancelAssign}>
          <AssignToClientModal
            assignedClient={assignedClient}
            handleAssignSubmit={handleAssignSubmit}
            onCancelAssign={onCancelAssign}
            handleCloseAssignModal={handleCloseAssignModal}
            setAssignedClient={setAssignedClient}
          />
        </Modal>
      )}
      <ActionsBar
        searchHandler={setSearchTerm}
        showDelete={Object.keys(rowSelection).length > 0}
        addBtnLabel="Add Job"
        deleteHandler={handleDeleteJobs}
        deleteBtnLabel={
          Object.keys(rowSelection).length > 1 ? "Delete Jobs" : "Delete Job"
        }
        addHandler={handleOpenCreateModal}
      />
      <ViewSwitcher view={view} handleChangeView={handleChangeView} />
      {loading ? (
        <Loader />
      ) : (
        <>
          {company && view === "module" && (
            <CompanyComponentClient
              company={company}
              handleAssignOpen={handleAssignOpen}
              handleEditOpen={handleEditOpen}
              handleDeleteOpen={handleDeleteOpen}
            />
          )}
          {view === "list" && (
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} sx={{ mb: 3 }}>
                <Table
                  onRowSelectionChange={setRowSelection}
                  columns={columns}
                  data={filteredJobs}
                  rowSelection={rowSelection}
                  enableColumnOrdering
                  enableSorting
                  enableColumnActions
                />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default JobsTemplate;
