import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../../utils/store";
import { AxiosError } from "axios";
import Candidate from "./candidates.types";
import candidateService, {
  CreateCandidateDto,
  UpdateCandidateDto,
} from "./candidates.api";
import { initializeCompanies } from "../../pages/Clients/clients.slice";
import toast from "react-hot-toast";

const initialState = [] as Candidate[];

const candidatesSlice = createSlice({
  name: "candidates",
  initialState,
  reducers: {
    setCandidates(state, { payload: candidates }: PayloadAction<Candidate[]>) {
      return candidates;
    },
    addCandidates(state, { payload: candidates }: PayloadAction<Candidate[]>) {
      return state.concat(candidates);
    },
    replaceOne(state, { payload: updatedCandidate }: PayloadAction<Candidate>) {
      return state.map((candidate) =>
        candidate.id === updatedCandidate.id ? updatedCandidate : candidate
      );
    },
    removeCandidate(state, { payload: id }: PayloadAction<number>) {
      return state.filter((candidate) => candidate.id !== id);
    },
    clearCandidates(state) {
      return [];
    },
  },
});

export const {
  setCandidates,
  addCandidates,
  replaceOne,
  removeCandidate,
  clearCandidates,
} = candidatesSlice.actions;

export const initializeCandidates = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const candidates = await candidateService.getAll();
      dispatch(setCandidates(candidates));
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        toast.error(`Failed to fetch candidates`);
      }
    }
  };
};

export const createCandidates = (createCandidateDtos: CreateCandidateDto[]) => {
  return async (dispatch: AppDispatch) => {
    try {
      const candidates = await candidateService.createMany(createCandidateDtos);
      toast.success("New candidates were added successfully");
      dispatch(addCandidates(candidates));
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        toast.error(
          `Failed to add candidates. ${error.response?.data?.message?.join(
            ". "
          )}`
        );
      }
    }
  };
};

export const updateOneCandidate = (
  id: number,
  updateCandidateDto: UpdateCandidateDto,
  me = false
) => {
  return async (dispatch: AppDispatch) => {
    try {
      const candidates = await candidateService.updateOne(
        id,
        updateCandidateDto
      );
      dispatch(initializeCompanies(me));
      toast.success("Candidate is updated successfully");
      dispatch(replaceOne(candidates));
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        toast.error(
          `Failed to update the candidate. ${error.response?.data?.message?.join(
            ". "
          )}`
        );
      }
    }
  };
};

export const updateManyCandidates = (
  ids: number[],
  updateCandidateDto: Partial<UpdateCandidateDto>,
  me = false
) => {
  return async (dispatch: AppDispatch) => {
    try {
      for (const id of ids) {
        await candidateService.updateOne(id, updateCandidateDto);
      }

      dispatch(initializeCompanies(me));
      if (!me) {
        dispatch(initializeCandidates());
      }

      toast.success("Candidate is updated successfully");
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        toast.error(
          `Failed to update the candidate. ${error.response?.data?.message}`
        );
      }
    }
  };
};

export const deleteManyCandidates = (ids: number[]) => {
  return async (dispatch: AppDispatch) => {
    try {
      for (const id of ids) {
        await candidateService.deleteOne(id);
        dispatch(removeCandidate(id));
      }
      dispatch(initializeCompanies());
      toast.success("Candidates are removed successfully");
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        toast.error(
          `Failed to remove candidates. ${error.response?.data.message}`
        );
      }
    }
  };
};

export default candidatesSlice.reducer;
