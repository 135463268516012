import { useForm } from "react-hook-form";
import { Box, Link, Typography } from "@mui/material";
import { Button, TextField } from "@anytimesoftware0/anytime-ui";
import styles from "./EditCandidateModal.module.scss";
import { useTranslation } from "react-i18next";
import { createRef, Dispatch, SetStateAction } from "react";
import { UploadFile } from "@mui/icons-material";
import Candidate from "../../../pages/Candidates/candidates.types";

interface EditCandidateModalProps {
  handleCloseEditModal: () => void;
  handleEditCandidateSubmit: () => void;
  customColumns: { [key: string]: { id: number; value: string } };
  setCustomColumns: Dispatch<
    SetStateAction<{ [key: string]: { id: number; value: string } }>
  >;
  onCancel: () => void;
  selectedCandidate: Candidate | undefined;
  setSelectedCandidate: Dispatch<SetStateAction<Candidate | undefined>>;
}

const EditCandidateModal: React.FC<EditCandidateModalProps> = ({
  handleCloseEditModal,
  handleEditCandidateSubmit,
  onCancel,
  customColumns,
  setCustomColumns,
  selectedCandidate,
  setSelectedCandidate,
}) => {
  const [t] = useTranslation(["common"]);
  const ref = createRef<HTMLInputElement>();
  const { handleSubmit } = useForm();

  const submit = () => {
    handleEditCandidateSubmit();
    handleCloseEditModal();
  };

  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  return (
    <form
      noValidate
      className={styles.modalContent}
      onSubmit={handleSubmit(submit)}
    >
      <Typography variant="h3" component="h3">
        Edit Candidate
      </Typography>
      {selectedCandidate &&
        Object.keys(selectedCandidate)
          .filter(
            (key) =>
              key !== "id" &&
              key !== "customVariables" &&
              key !== "companyId" &&
              key !== "jobId" &&
              key !== "stage" &&
              key !== "file" &&
              key !== "stageText"
          )
          .map((key, index) => {
            const value = selectedCandidate[key as keyof Candidate] as string;
            return (
              <TextField
                key={index}
                fullWidth
                label={key}
                value={value}
                onChange={(e) =>
                  setSelectedCandidate({
                    ...selectedCandidate,
                    [key]: e.currentTarget.value,
                  })
                }
              />
            );
          })}
      {selectedCandidate &&
        selectedCandidate.customVariables &&
        Object.keys(selectedCandidate.customVariables)
          .filter((key) => key !== "emailStatus")
          .map((key, index) => {
            return (
              <TextField
                key={Object.keys(selectedCandidate).length + index}
                fullWidth
                label={key}
                value={selectedCandidate.customVariables[key]}
                onChange={(e) =>
                  setSelectedCandidate({
                    ...selectedCandidate,
                    customVariables: {
                      ...selectedCandidate.customVariables,
                      [key]: e.currentTarget.value,
                    },
                  })
                }
              />
            );
          })}
      {Object.entries(customColumns)
        .sort((e1, e2) => e1[1].id - e2[1].id)
        .map((entry) => entry[0])
        .map((columnKey) => (
          <Box key={customColumns[columnKey].id} className={styles.keyValue}>
            <TextField
              label="Column Name"
              value={columnKey}
              onChange={(e) => {
                const newCustomColumns = { ...customColumns };
                newCustomColumns[e.target.value] = newCustomColumns[columnKey];
                delete newCustomColumns[columnKey];
                setCustomColumns(newCustomColumns);
              }}
            />
            <TextField
              label="Column Value"
              value={customColumns[columnKey].value}
              onChange={(e) => {
                const newCustomColumns = { ...customColumns };
                newCustomColumns[columnKey] = {
                  id: newCustomColumns[columnKey].id,
                  value: e.target.value,
                };
                setCustomColumns(newCustomColumns);
              }}
            />
          </Box>
        ))}
      <input
        type="file"
        ref={ref}
        style={{ display: "none" }}
        id="cvFile"
        name="cvFile"
        accept="application/msword, text/plain, application/pdf"
        onChange={async (e) => {
          if (e.currentTarget.files && e.currentTarget.files[0]) {
            setSelectedCandidate({
              ...selectedCandidate,
              file: (await toBase64(e.currentTarget.files[0])) as string,
            } as any);
          }
        }}
      />
      {selectedCandidate?.file && (
        <Link
          display="block"
          textAlign="center"
          color="primary"
          href={selectedCandidate.file}
          download
        >
          Download CSV File
        </Link>
      )}
      <Box className={styles.buttons}>
        <Button
          startIcon={<UploadFile />}
          color="primary"
          variant="contained"
          onClick={() => {
            ref.current?.click();
          }}
        >
          {t("uploadCSV")}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={(e) => {
            if (Object.keys(customColumns).every((key) => key !== "")) {
              const newCustomColumns = customColumns;
              newCustomColumns[""] = {
                id: Object.keys(customColumns).length,
                value: "",
              };
              setCustomColumns({ ...newCustomColumns });
            }
          }}
        >
          Add Column
        </Button>
        <Button type="submit" color="primary" className={styles.add}>
          {t("edit")}
        </Button>
        <Button variant="contained" color="danger" onClick={onCancel}>
          {t("cancel")}
        </Button>
      </Box>
    </form>
  );
};

export default EditCandidateModal;
