import { useForm } from "react-hook-form";
import { Box, FormControl, Typography } from "@mui/material";
import { Button, Select } from "@anytimesoftware0/anytime-ui";
import styles from "./DeleteStageModal.module.scss";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";
import { Stage } from "../stages.types";
import { Option } from "../../../types/Option";

interface DeleteStageModalProps {
  handleCloseDeleteModal: () => void;
  handleDeleteStageSubmit: () => void;
  setSelectedDeleteStage: Dispatch<SetStateAction<Stage | null>>;
  stages: Stage[];
}

const DeleteStageModal: React.FC<DeleteStageModalProps> = ({
  stages,
  handleCloseDeleteModal,
  handleDeleteStageSubmit,
  setSelectedDeleteStage,
}) => {
  const [t] = useTranslation(["common"]);
  const { handleSubmit } = useForm();

  const submit = () => {
    handleDeleteStageSubmit();
    handleCloseDeleteModal();
  };

  return (
    <form
      noValidate
      className={styles.modalContent}
      onSubmit={handleSubmit(submit)}
    >
      <Typography variant="h3" component="h3">
        Edit Stage
      </Typography>
      <FormControl fullWidth>
        <Select
          placeholder="Select stage"
          onChange={(event) => {
            const value = (event as Option)?.value;
            const selectedStageId = value ? parseInt(value, 10) : null;
            const selectedStage =
              stages.find((stage) => stage.id === selectedStageId) ?? null;
            setSelectedDeleteStage(selectedStage);
          }}
          isSearchable
          options={stages.map((stage) => ({
            label: stage.title,
            value: String(stage.id),
          }))}
        />
      </FormControl>
      <Box className={styles.buttons}>
        <Button type="submit" color="primary" className={styles.add}>
          {t("delete")}
        </Button>
        <Button
          variant="contained"
          color="danger"
          onClick={handleCloseDeleteModal}
        >
          {t("cancel")}
        </Button>
      </Box>
    </form>
  );
};

export default DeleteStageModal;
