import { useForm } from "react-hook-form";
import { Box, FormControl, Typography } from "@mui/material";
import { Button, TextField, Select } from "@anytimesoftware0/anytime-ui";
import styles from "./AddCompanyModal.module.scss";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";
import { Option } from "../../../types/Option";

interface AddCompanyModalProps {
  handleCloseCreateModal: () => void;
  handleAddCompanySubmit: () => void;
  setCompany: Dispatch<
    SetStateAction<{
      name: string;
      language: Option;
      email: string;
      password: string;
    }>
  >;
  company: {
    name: string;
    language: Option;
    email: string;
    password: string;
  };
  onCancel: () => void;
}

interface IFormInput {
  name: string;
  langauge: { label: string; value: string };
  email: string;
  password: string;
}

const languages = [
  { label: "EN", value: "EN" },
  { label: "CZ", value: "CZ" },
];

const AddCompanyModal: React.FC<AddCompanyModalProps> = ({
  handleCloseCreateModal,
  handleAddCompanySubmit,
  onCancel,
  setCompany,
  company,
}) => {
  const [t] = useTranslation(["common"]);
  const { name, language, email, password } = company;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();

  const submit = () => {
    handleAddCompanySubmit();
    handleCloseCreateModal();
  };

  return (
    <form
      noValidate
      className={styles.modalContent}
      onSubmit={handleSubmit(submit)}
    >
      <Typography variant="h3" component="h3">
        Add New Company
      </Typography>
      <TextField
        required
        fullWidth
        onChange={(e) => setCompany({ ...company, name: e.target.value })}
        value={name}
        error={!!errors.name}
        helperText={errors.name?.message}
        register={register}
        validations={{
          required: { value: true, message: "Name is required" },
        }}
        label={t("name")}
        name="name"
        focused
      />
      <TextField
        required
        fullWidth
        onChange={(e) => setCompany({ ...company, email: e.target.value })}
        error={!!errors.email}
        helperText={errors.email?.message}
        register={register}
        validations={{
          required: { value: true, message: "Email is required" },
          pattern: {
            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: "Invalid email",
          },
        }}
        value={email}
        label={t("email")}
        name="email"
      />
      <FormControl fullWidth>
        <Select
          placeholder="Select Language"
          onChange={(v) => setCompany({ ...company, language: v as Option })}
          isSearchable
          value={language}
          options={languages}
        />
      </FormControl>
      <TextField
        required
        fullWidth
        onChange={(e) => setCompany({ ...company, password: e.target.value })}
        value={password}
        error={!!errors.password}
        helperText={errors.password?.message}
        register={register}
        validations={{
          required: { value: true, message: "Password is required" },
          minLength: {
            value: 6,
            message: "Password must be at least 6 characters",
          },
        }}
        type="password"
        label={t("password")}
        name="password"
      />
      <Box className={styles.buttons}>
        <Button type="submit" color="primary" className={styles.add}>
          {t("addCompany")}
        </Button>
        <Button variant="contained" color="danger" onClick={onCancel}>
          {t("cancel")}
        </Button>
      </Box>
    </form>
  );
};

export default AddCompanyModal;
